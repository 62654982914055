import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { NotificationActions } from 'store/reducers/notification';

let displayed = [];

const SnackbarNotify = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // Selectors
  const { list: notifications } = useSelector((state) => state.notification);

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  useEffect(() => {
    notifications.forEach((notification) => {
      if (displayed.includes(notification.key)) return;
      enqueueSnackbar(notification.message, {
        ...notification.options,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        action: closeSnackbar,
        onExited() {
          dispatch(NotificationActions.removeNotification(notification.key));
        },
      });
      storeDisplayed(notification.key);
    });
  }, [displayed, notifications, closeSnackbar, enqueueSnackbar]);

  return null;
};

export default SnackbarNotify;
