import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { EnhancedFormik } from 'context/formikContext';
import { Box, Button, SvgIcon, IconButton } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { FormTextField, FormActions } from 'shared/components/Form';
import { TrashAltIcon } from 'assets/icons';

const RequiredExperienceStep = (props) => {
	const { details, handleSubmit, actionButton, handleBack } = props;

	const validationSchema = Yup.object().shape({
		candidateRequiredExperience: Yup.array().of(
			Yup.object().shape({
				requiredExperience: Yup.string().required('Required'),
			}),
		),
	});

	const requiredExperienceEntity = { requiredExperience: '' };

	return (
		<>
			<PageTitle title="Candidate Required Experience" actionButton={actionButton} />

			<Box>
				<EnhancedFormik validationSchema={validationSchema}>
					<Formik
						initialValues={{
							candidateRequiredExperience: details.candidateRequiredExperience || [requiredExperienceEntity],
						}}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						validateOnMount
						enableReinitialize
					>
						{({ values, dirty, isValid, submitForm, isSubmitting }) => (
							<Form>
								<FieldArray
									name="candidateRequiredExperience"
									render={(arrayHelpers) => (
										<>
											{values.candidateRequiredExperience.map(({ id, requiredExperience }, index) => (
												<Box mb={4} gap={6} display="flex" alignItems="center" key={id}>
													<Box flexGrow={1}>
														<FormTextField
															fullWidth
															variant="outlined"
															name={`candidateRequiredExperience.${index}.requiredExperience`}
															type="text"
															inputlabel="Experience"
															value={requiredExperience}
															required
														/>
													</Box>
													<Box justifySelf="flex-end">
														<IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
															<SvgIcon component={TrashAltIcon} inheritViewBox size={18} />
														</IconButton>
													</Box>
												</Box>
											))}

											<Box>
												<Button
													color="secondary"
													variant="contained"
													size="large"
													disabled={isSubmitting}
													onClick={() =>
														arrayHelpers.push({
															...requiredExperienceEntity,
															id: `new-requiredExperience-${arrayHelpers.form.values.candidateRequiredExperience.length}`,
														})
													}
												>
													Add more
												</Button>
											</Box>
										</>
									)}
								/>

								<FormActions display="flex" justifyContent="flex-end" className="bottomActions" gap={2}>
									<Button color="secondary" variant="contained" size="large" disabled={isSubmitting} onClick={handleBack}>
										Back
									</Button>
									<div className="spacer" />
									<Button
										color="primary"
										variant="contained"
										size="large"
										type="submit"
										disabled={!isValid || isSubmitting}
										onClick={(e) => {
											e.preventDefault();
											handleSubmit(values, dirty);
										}}
									>
										Next
									</Button>
								</FormActions>
							</Form>
						)}
					</Formik>
				</EnhancedFormik>
			</Box>
		</>
	);
};
export default RequiredExperienceStep;
