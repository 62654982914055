import axios from 'axios';
import { GET_METRIC_LOGIN } from './api';

export async function sendMetricOnLogin() {
	try {
		const { data } = await axios.post(GET_METRIC_LOGIN);

		return data;
	} catch (e) {
		console.log({ e });
	}
}
