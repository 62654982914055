import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		greyText: {
			color: colors.textColorGrey,
		},
		linkText: {
			color: colors.linkText,
			textDecoration: 'none',
		},
		openedRow: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		openedRowLeft: {
			width: '65%',
			display: 'flex',
			flexDirection: 'column',
		},
		openedRowRight: {
			width: '30%',
			display: 'flex',
			gap: 4,
			flexDirection: 'column',
		},
	};
});
