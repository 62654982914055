import { createContext, useContext, useEffect, useState } from 'react';
import ManagementService from 'services/management';
import { useAuth } from 'context/authContext';

let ManagementContext = createContext({
	getOwnUser: () => ({}),
	setUserCurrentManagement: () => ({}),
	hasSelected: () => ({}),
	user: null,
	isLoading: true,
});

const useManagement = () => useContext(ManagementContext);

function ManagementProvider({ children }) {
	let [user, setUser] = useState();
	let [isLoading, setIsLoading] = useState(true);
	const { isAuthenticated, user: authUser } = useAuth();

	useEffect(() => {
		if (!isAuthenticated()) return;

		// console.log({ isAuthenticated, authUser });

		getOwnUser();
	}, [authUser]);

	const getOwnUser = async () => {
		setIsLoading(true);
		setUser(await ManagementService.getOwnUser());
		setIsLoading(false);
	};

	const hasSelected = () => {
		const isSelected = !!user?.currentManagement && user?.currentManagement !== 'NOT_SELECTED';
		return isSelected;
	};

	const setUserCurrentManagement = async (management) => {
		setIsLoading(true);
		await ManagementService.setUserCurrentManagement(management);
		await getOwnUser();
	};

	const value = { getOwnUser, setUserCurrentManagement, hasSelected, user, isLoading };

	return <ManagementContext.Provider value={value}>{children}</ManagementContext.Provider>;
}

export { useManagement, ManagementProvider };
