export const docMimeType = {
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};
export const pdfMimeType = {
	'application/pdf': ['.pdf'],
};
export const extToMimes = {
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',
};

export const photoMimeTypes = {
	'image/gif': ['.gif'],
	'image/jpeg': ['.jpeg', '.jpg'],
	'image/png': ['.png'],
};
