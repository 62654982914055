import { UserActions } from './reducer';
import { USER } from 'services/api';
import axios from 'axios';
import { NotificationActions } from '../notification';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(UserActions.setInitial());
	};
}

export function updateUser(userData) {
	return async function (dispatch, getState) {
		dispatch(UserActions.updateUserRequest());

		try {
			const { data } = await axios.put(USER, { ...userData });

			dispatch(UserActions.updateUserSuccess({ data }));
		} catch (e) {
			dispatch(UserActions.updateUserFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while updating has appeared', options: { variant: 'error' } }));
		}
	};
}
