import { SvgIcon } from '@mui/material';
import { CaretDownIcon } from 'assets/icons';
import OutlinedWhiteIconButton from '../OutlinedWhiteIconButton';

const UnfoldButton = (props) => {
	const { isOpened, ...otherProps } = props;
	return (
		<OutlinedWhiteIconButton aria-label="view" {...otherProps}>
			<SvgIcon component={CaretDownIcon} inheritViewBox size={18} transform={`rotate(${isOpened ? 180 : 0})`} />
		</OutlinedWhiteIconButton>
	);
};

export default UnfoldButton;
