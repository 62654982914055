import { combineReducers } from '@reduxjs/toolkit';

export function combineSliceReducers(slices) {
	return combineReducers(
		Object.entries(slices).reduce(
			(combinedReducer, [name, { reducer }]) => ({
				...combinedReducer,
				[name]: reducer,
			}),
			{},
		),
	);
}

export function combineSliceActions(slices) {
	return Object.entries(slices).reduce(
		(combinedActions, [name, { actions }]) => ({
			...combinedActions,
			[name]: actions,
		}),
		{},
	);
}
