import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, SvgIcon, ListItemText } from '@mui/material';
import * as routePaths from 'constants/routes';
import { INTERFACE_BUSINESS, INTERFACE_INDIVIDUAL } from 'constants/interfaces';
import { BusinessIcon, MyResumeIcon, CoverLetterIcon, EmployeesIcon, VacancyIcon, MatchingIcon, MatchingBusinessIcon, UserCircleIcon } from 'assets/icons';
import { useStyles } from './styles';
import { useFeatureFlag } from 'context/featureFlagContext';
import { useInterface } from 'context/interfaceContext';

const SidebarMenu = (props) => {
	const { drawerIsOpen } = props;
	const { interfaceType } = useInterface();
	const { classes } = useStyles();
	const location = useLocation();
	const { matchingEnabled, vacanciesEnabled, userManagementEnabled } = useFeatureFlag();
	const menuItems = {
		[INTERFACE_BUSINESS]: [
			{
				id: 'companies-list',
				label: 'Companies',
				order: 0,
				icon: BusinessIcon,
				path: routePaths.ROUTE_BUSINESS_COMPANY_LIST,
			},
			{
				id: 'employees-list',
				label: 'Employees',
				order: 1,
				icon: EmployeesIcon,
				path: routePaths.ROUTE_BUSINESS_EMPLOYEES,
			},
		],
		[INTERFACE_INDIVIDUAL]: [
			{
				id: 'resume-list',
				label: 'My Resume',
				order: 0,
				icon: MyResumeIcon,
				path: routePaths.ROUTE_PERSONAL_MY_RESUME,
			},
			{
				id: 'cover-letters-list',
				label: 'My Cover Letter',
				order: 1,
				icon: CoverLetterIcon,
				path: routePaths.ROUTE_PERSONAL_COVER_LETTERS,
			},
		],
	};

	if (matchingEnabled) {
		menuItems[INTERFACE_BUSINESS].push({
			id: 'matching-cv-list',
			label: 'Matching CVs',
			order: 2,
			icon: MatchingBusinessIcon,
			path: routePaths.ROUTE_BUSINESS_MATCHING,
		});
		menuItems[INTERFACE_INDIVIDUAL].push({
			id: 'matching-list',
			label: 'Matching',
			order: 2,
			icon: MatchingIcon,
			path: routePaths.ROUTE_PERSONAL_MATCHING,
		});

		menuItems[INTERFACE_INDIVIDUAL].push({
			id: 'match-my-resume-list',
			label: 'Match MyResumeList',
			order: 3,
			icon: MatchingBusinessIcon,
			path: routePaths.ROUTE_PERSONAL_MATCHING_MY_RESUME_LIST,
		});

		menuItems[INTERFACE_BUSINESS].push({
			id: 'match-employee-resumes',
			label: 'Match CVs2',
			icon: MatchingBusinessIcon,
			order: 4,
			path: routePaths.ROUTE_BUSINESS_MATCHING_EMPLOYEE_RESUME,
		});
	}

	if (vacanciesEnabled) {
		menuItems[INTERFACE_BUSINESS].push({
			id: 'vacancies-list',
			label: 'Vacancies',
			icon: VacancyIcon,
			order: 3,
			path: routePaths.ROUTE_BUSINESS_VACANCY_LIST,
		});
	}

	// Menu items for all interfaces
	let additionalMenuItems = [];

	if (userManagementEnabled) {
		additionalMenuItems = [
			...additionalMenuItems,
			{
				id: 'user-management',
				label: 'My account',
				icon: UserCircleIcon,
				order: 100,
				path: routePaths.ROUTE_MY_ACCOUNT,
			},
		];
	}

	return (
		<List component="nav" aria-label="nav" className={classes.root}>
			{menuItems[interfaceType]?.length > 0 &&
				[...menuItems[interfaceType], ...additionalMenuItems]
					.sort((a, b) => a.order - b.order)
					.map((item) => (
						<ListItemButton mb={2} key={item.id} selected={location.pathname.includes(item.path)} component={RouterLink} to={item.path}>
							<ListItemIcon>
								<SvgIcon component={item.icon} inheritViewBox htmlColor="white" sx={{ fill: 'white' }} />
							</ListItemIcon>
							<ListItemText primary={item.label} sx={{ opacity: drawerIsOpen ? 1 : 0 }} />
						</ListItemButton>
					))}
		</List>
	);
};

export default SidebarMenu;
