import { SvgIcon } from '@mui/material';
import { DownloadIcon } from 'assets/icons';
import ContainedIconButton from '../ContainedIconButton';

const DownloadButton = (props) => (
	<ContainedIconButton aria-label="view" {...props}>
		<SvgIcon component={DownloadIcon} inheritViewBox size={18} />
	</ContainedIconButton>
);

export default DownloadButton;
