import React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { ExitIcon } from 'assets/icons';
import { useStyles } from '../Menu/styles';

const LogoutButton = (props) => {
	const { drawerIsOpen, handleLogout } = props;
	const { classes } = useStyles();

	return (
		<List component="nav" aria-label="nav" className={classes.root}>
			<ListItemButton mb={2} onClick={handleLogout}>
				<ListItemIcon>
					<SvgIcon component={ExitIcon} inheritViewBox htmlColor="white" sx={{ fill: 'white' }} />
				</ListItemIcon>
				<ListItemText primary="Logout" sx={{ opacity: drawerIsOpen ? 1 : 0 }} />
			</ListItemButton>
		</List>
	);
};

export default LogoutButton;
