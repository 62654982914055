import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableHead, TableRow, TableBody, Typography, Box, Stack } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { StyledTableCell, StyledTableRow } from 'shared/components/Table';
import Empty from 'shared/components/Empty';
import FullscreenWrapper from 'shared/components/FullscreenWrapper';
import { matchResumesForVacancyId, setInitial } from 'store/reducers/matching';
import UnfoldButton from 'shared/components/Buttons/UnfoldButon/UnfoldButton';
import { useParams } from 'react-router-dom';
import { getVacancy, getVacancyList } from 'store/reducers/vacancy';
import { useState } from 'react';
import { loadCompanies } from 'store/reducers/company';
import PercentageButton from 'shared/components/Buttons/Percentage/Percentage';
import { useStyles } from '../styles';
import TruncatedText from 'shared/components/Table/TruncatedText';
import { EyeButton } from 'shared/components/Buttons';
import { generateResumePdf, generateResumePdfDefault } from 'store/reducers/pdf';
import DocPreview from 'shared/dialogs/DocPreview';
import PDF from 'shared/components/PDF';
import { getOwnPdfTemplateList } from 'store/reducers/template';

const MatchingResumeList = () => {
	const { vacancyId } = useParams();

	const dispatch = useDispatch();
	const isOneVacancy = Boolean(vacancyId);

	const { classes } = useStyles();

	const [shouldOpenPreview, setShouldOpenPreview] = useState(false);
	const [resumePreviewIsOpen, setResumePreviewIsOpen] = useState(false);
	const [checkCompanies, setCheckCompanies] = useState(false);
	const [ounfoldedResumes, setOunfoldedResumes] = useState([]);

	const { details, loading: vacancyLoading } = useSelector((state) => state.vacancy);

	const { matchingResumeList, loading: matchingLoading } = useSelector((state) => state.matching);
	const { templates } = useSelector((state) => state.template);

	const {
		list: { content: companiesList },
		loading: companiesLoading,
		currentCompany,
	} = useSelector((state) => state.company);

	const { file: resumePDF, loading: pdfLoading } = useSelector((state) => state.pdf);

	const toggleOpenResume = (resumeId) => {
		if (ounfoldedResumes.includes(resumeId)) {
			setOunfoldedResumes(ounfoldedResumes.filter((resume) => resume !== resumeId));
		} else {
			setOunfoldedResumes([...ounfoldedResumes, resumeId]);
		}
	};

	useEffect(() => {
		setInitial();
		dispatch(getOwnPdfTemplateList());
	}, []);

	useEffect(() => {
		if (companiesList && companiesList.length > 0) return;

		if (checkCompanies) return;

		setCheckCompanies(true);

		dispatch(loadCompanies());
	}, [companiesList]);

	useEffect(() => {
		if (!currentCompany?.id) return;

		if (isOneVacancy) {
			dispatch(getVacancy(currentCompany.id, vacancyId));
		} else {
			dispatch(getVacancyList(currentCompany.id));
		}
	}, [currentCompany]);

	useEffect(() => {
		if (isOneVacancy) {
			dispatch(matchResumesForVacancyId(vacancyId, 10, false));
		} else {
			// test component, will be replaced later
			//console.log('try to get nowhere')
		}
	}, [vacancyId]);

	useEffect(() => {
		if (!shouldOpenPreview) return;

		resumePDF && setResumePreviewIsOpen(true);
	}, [shouldOpenPreview, resumePDF]);

	const handleViewResume = (resume) => {
		if (templates.length === 0) {
			dispatch(generateResumePdfDefault(resume.id));
		} else {
			const { id: templateId } = templates[0];
			dispatch(generateResumePdf(resume.id, templateId));
		}

		setShouldOpenPreview(true);
	};

	const closeResumePreview = () => {
		setResumePreviewIsOpen(false);
		setShouldOpenPreview(false);
	};

	const joinLocationNames = (locations) => {
		return locations && locations.map((location) => location.country.name).join(', ');
	};

	return (
		<>
			<PageTitle title={`Matching resumes for ${!vacancyLoading && details && `${details.seniority} ${details.specialisation}`}`} />

			<FullscreenWrapper loading={matchingLoading || vacancyLoading || companiesLoading || pdfLoading}>
				{matchingResumeList.length === 0 && !matchingLoading ? (
					<Empty />
				) : (
					<Table aria-label="my resume table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Role</StyledTableCell>
								<StyledTableCell>Seniority</StyledTableCell>
								<StyledTableCell>Name</StyledTableCell>
								<StyledTableCell>Location</StyledTableCell>
								<StyledTableCell>Workload</StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell align="right">Action</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{matchingResumeList?.length &&
								matchingResumeList.map(({ resume, companyContact }) => (
									<React.Fragment key={resume.id}>
										<StyledTableRow key={resume.id}>
											<StyledTableCell>
												<Typography variant="body1" color="primary">
													{resume.companyName}
												</Typography>
												<Typography variant="body2" color="primary">
													{resume.specialisation}
												</Typography>
											</StyledTableCell>
											<StyledTableCell>
												<Typography variant="subtitle1" color="primary">
													{resume.seniority}
												</Typography>
											</StyledTableCell>
											<StyledTableCell>
												<Typography variant="subtitle1" color="primary">
													{resume.name}
												</Typography>
											</StyledTableCell>
											<StyledTableCell>
												<Typography variant="subtitle1" color="primary">
													{joinLocationNames(resume.locations)}
												</Typography>
											</StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell align="center">
												<PercentageButton>86%</PercentageButton>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Stack spacing={1} direction="row" justifyContent="flex-end">
													<EyeButton onClick={() => handleViewResume(resume)} />
													<UnfoldButton onClick={() => toggleOpenResume(resume.id)} isOpened={ounfoldedResumes.includes(resume.id)} />
												</Stack>
											</StyledTableCell>
										</StyledTableRow>
										{ounfoldedResumes.includes(resume.id) && (
											<StyledTableRow>
												<StyledTableCell colSpan={7}>
													<Box className={classes.openedRow}>
														<Box className={classes.openedRowLeft}>
															<Typography variant="subtitle2" className={classes.greyText}>
																Summary
															</Typography>
															<Typography variant="subtitle1" color="secondary">
																<TruncatedText text={resume.summary.reduce((acc, currentSumm) => acc + currentSumm.summary, '')} maxLength={150} />
															</Typography>
														</Box>
														<Box className={classes.openedRowRight}>
															<Typography variant="subtitle2" className={classes.greyText}>
																Contacts
															</Typography>
															{companyContact?.companyName && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.companyName}
																</Typography>
															)}
															{companyContact?.email && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.email}
																</Typography>
															)}
															{companyContact?.phone && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.phone}
																</Typography>
															)}
															{companyContact?.skype && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.skype}
																</Typography>
															)}
															{companyContact?.telegram && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.telegram}
																</Typography>
															)}
														</Box>
													</Box>
												</StyledTableCell>
											</StyledTableRow>
										)}
									</React.Fragment>
								))}
						</TableBody>
					</Table>
				)}
			</FullscreenWrapper>

			<DocPreview open={resumePreviewIsOpen} handleClose={closeResumePreview} preview={<PDF file={new File([resumePDF], 'resume.pdf')} />} title="Preview resume" />
		</>
	);
};

export default MatchingResumeList;
