import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		pagination: {},
		prevnextBtn: {
			color: '#6F6C90',
			fontFamily: theme.typography.fontFamilyInter,
			fontSize: '1.4rem',
			fontWeight: 500,
			lineHeight: '2.4rem',
			letterSpacing: '-0.2px',

			borderRadius: 50,
			border: '1px solid #E6E6E6',
		},
		currentPageBtn: {
			'&.Mui-disabled': {
				backgroundColor: colors.pageNumberCurrentColor,
				color: '#FFF',
			},
		},
		pageBtn: {
			color: colors.pageNumberColor,
			fontFamily: theme.typography.fontFamilyInter,
			fontSize: '1.4rem',
			fontStyle: 'normal',
			fontWeight: 500,
			lineHeight: '2.4rem',
			letterSpacing: '-0.2px',
			width: 28,
			minWidth: 28,
			height: 28,
			borderRadius: '50%',
		},
	};
});
