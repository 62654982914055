import { featureFlagsList } from 'constants/featureFlags';

export const isMatchingEnabled = (featureFlags) => isFeatureEnabled(featureFlags, featureFlagsList.matching);
export const isVacanciesEnabled = (featureFlags) => isFeatureEnabled(featureFlags, featureFlagsList.vacancies);
export const isUserManagementEnabled = (featureFlags) => isFeatureEnabled(featureFlags, featureFlagsList.userManagement);
export const isTeamManagementEnabled = (featureFlags) => isFeatureEnabled(featureFlags, featureFlagsList.teamManagement);

export const isFeatureEnabled = (featureFlags, feature) => {
	return featureFlags.includes(feature);
};
