import React from 'react';
import Routes from 'routes';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider, CssBaseline } from '@mui/material';
import SnackbarNotify from 'shared/snackbar/Notify';
// defaultTheme
import theme from 'theme';
import { FeatureFlagProvider } from 'context/featureFlagContext';
import { ManagementProvider } from 'context/managementContext';

function App() {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<ManagementProvider>
					<FeatureFlagProvider>
						<CssBaseline />
						<SnackbarNotify />
						<Routes />
					</FeatureFlagProvider>
				</ManagementProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
