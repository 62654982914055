import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useField } from 'formik';
import { Box, Button } from '@mui/material';
import { FormTextField } from 'shared/components/Form';
import FormImproveFieldVariants from '../FormImproveFieldVariants';

const FormImproveField = forwardRef((props, ref) => {
	const { improveAction, hideButtons = false, children, ...rest } = props;
	const [{ value: fieldValue }, meta, helpers] = useField(props);
	const improveRef = useRef(null);

	const [fieldVariants, setFieldVariants] = useState([]);
	const [currentVariantIndex, setCurrentVariantIndex] = useState(0);
	const [, setPrevVariantIndex] = useState(null);
	const [improved, setImproved] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// Hack to wait for new value to be applied
		// Pending https://github.com/jaredpalmer/formik/issues/529
		setTimeout(() => {
			helpers.setValue(fieldValue);
		}, 0);
	}, []);

	useEffect(() => {
		if (!improved) return;

		if (fieldVariants === improved) return;

		setFieldVariants(improved);
		const formattedFieldValue = Array.isArray(improved[currentVariantIndex]) ? improved[currentVariantIndex].join('\r\n') : improved[currentVariantIndex];

		helpers.setValue(formattedFieldValue);
	}, [improved]);

	useImperativeHandle(ref, () => ({
		pressImprove: () => improveRef.current.click(),
	}));

	const handleImprove = async () => {
		setPrevVariantIndex(null);
		setLoading(true);
		const improveResult = await improveAction();
		setImproved(improveResult);
		setLoading(false);
	};

	const handleSelectVariant = useCallback(
		(value, index) => {
			if (index === currentVariantIndex && value === fieldValue) return;
			const formattedValue = Array.isArray(value) ? value.join('\r\n') : value;
			helpers.setValue(formattedValue);
			setPrevVariantIndex(currentVariantIndex);
			setCurrentVariantIndex(index);
		},
		[currentVariantIndex],
	);

	const revertToOriginal = () => {
		setCurrentVariantIndex(-1);
		helpers.setValue(meta.initialValue);
	};

	return (
		<>
			<FormTextField {...rest} disabled={loading} />
			<Box display="flex" justifyContent="space-between" gap={1}>
				<FormImproveFieldVariants fieldVariants={fieldVariants} variantIndex={currentVariantIndex} onSelect={handleSelectVariant} handleRevert={revertToOriginal} />

				{hideButtons || (
					<Box display="flex" justifyContent="flex-end" gap={1}>
						<Button color="secondary" variant="contained" sx={{ height: 30 }} onClick={handleImprove} disabled={loading} ref={improveRef}>
							{loading ? 'Improving...' : 'Improve'}
						</Button>
					</Box>
				)}
			</Box>
		</>
	);
});

export default FormImproveField;
