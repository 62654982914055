import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import colors from 'constants/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.tableHeadBg,
		color: colors.tableHeadColor,
		padding: '8px 16px',
		fontSize: '1.2rem',
		lineHeight: '2rem',
		letterSpacing: '-0.2px',
	},
	[`&.${tableCellClasses.body}`]: {
		padding: '12px 16px',
		fontSize: '1.4rem',
	},
	[`&.${tableCellClasses.footer}`]: {
		padding: '30px 16px',
		fontSize: '1.4rem',
		borderBottom: 0,
	},
	'& .MuiCheckbox-root': {
		'& .MuiSvgIcon-root': {
			fill: 'currentColor',
		},
	},
}));

export default StyledTableCell;
