import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	updated: false,
	details: null,
};

const { actions, reducer } = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.updated = false;
			state.details = null;
		},

		updateUserRequest: (state, { payload }) => {
			state.updated = false;
			state.loading = true;
			state.error = false;
		},
		updateUserSuccess: (state, { payload }) => {
			state.loading = false;
			state.updated = true;
			state.details = payload.data;
		},
		updateUserFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as UserActions };

export default reducer;
