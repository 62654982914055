import { defaultPaginationSize } from 'constants/other';
import { VacancyActions } from './reducer';
import { getLink } from 'helpers/route';
import {
	CREATE_VACANCY_FOR_OWN_COMPANY,
	DELETE_VACANCY,
	GET_VACANCIES,
	GET_VACANCY_BY_ID,
	UPDATE_VACANCY_FOR_OWN_COMPANY,
	UPLOAD_VACANCY_FOR_OWN_COMPANY,
} from 'services/api';
import axios from 'axios';
import { NotificationActions } from '../notification';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.setInitial());
	};
}

export function createVacancy(companyId, vacancyData) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.createVacancyRequest());

		try {
			const url = getLink(CREATE_VACANCY_FOR_OWN_COMPANY, { companyId });

			const { data } = await axios.post(url, { ...vacancyData });

			dispatch(VacancyActions.createVacancySuccess({ data }));
		} catch (e) {
			dispatch(VacancyActions.createVacancyFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a vacancy appeared', options: { variant: 'error' } }));
		}
	};
}

export function uploadVacancy(companyId, vacancyText) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.uploadVacancyRequest());
		try {
			const url = getLink(UPLOAD_VACANCY_FOR_OWN_COMPANY, { companyId });

			const { data } = await axios.post(url, vacancyText, {
				timeout: 0, // No timeout
			});

			dispatch(VacancyActions.uploadVacancySuccess({ data }));
		} catch (e) {
			dispatch(VacancyActions.uploadVacancyFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a vacancy appeared', options: { variant: 'error' } }));
		}
	};
}

export function getVacancy(companyId, vacancyId) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.getVacancyRequest());

		try {
			const url = getLink(GET_VACANCY_BY_ID, { companyId, vacancyId });

			const { data } = await axios.get(url);

			dispatch(VacancyActions.getVacancySuccess({ data }));
		} catch ({ response }) {
			dispatch(VacancyActions.getVacancyFailure({ not_found: response?.status === 404 }));
			dispatch(
				NotificationActions.pushNotification({
					message: response?.data?.messages ? response.data.messages[0] : 'An error occurred while retrieving the vacancy info.',
					options: { variant: 'error' },
				}),
			);
		}
	};
}

export function getVacancyList(companyId, page) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.getVacancyListRequest());

		try {
			const params = {
				page,
				size: defaultPaginationSize,
				sort: 'updatedDate,desc',
			};

			const url = getLink(GET_VACANCIES, { companyId });

			const { data } = await axios.get(url, { params });

			dispatch(VacancyActions.getVacancyListSuccess({ data }));
		} catch (e) {
			dispatch(VacancyActions.getVacancyListFailure());
		}
	};
}

export function updateVacancy(vacancyData) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.updateVacancyRequest());

		try {
			const { data } = await axios.put(UPDATE_VACANCY_FOR_OWN_COMPANY, { ...vacancyData });

			dispatch(VacancyActions.updateVacancySuccess({ data }));
		} catch (e) {
			dispatch(VacancyActions.updateVacancyFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a vacancy appeared', options: { variant: 'error' } }));
		}
	};
}

export function deleteVacancy(vacancyId) {
	return async function (dispatch, getState) {
		dispatch(VacancyActions.deleteVacancyRequest());

		try {
			const url = getLink(DELETE_VACANCY, { vacancyId });

			await axios.delete(url);

			dispatch(VacancyActions.deleteVacancySuccess());
		} catch (e) {
			dispatch(VacancyActions.deleteVacancyFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a vacancy appeared', options: { variant: 'error' } }));
		}
	};
}
