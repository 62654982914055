import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from './styles';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { fileInputMaxFilesizeMB } from 'constants/other';
import Image from 'mui-image';
import { string2sha256 } from 'helpers/format';
import Avatar from 'shared/components/Avatar';
import { NotificationActions } from 'store/reducers/notification';

const FormFileInput = (props) => {
	const { name, inputlabel, required = false, isImageLoading = () => {}, setFieldValue, value, disablePreview = false, accept = false } = props;
	const { validationSchema } = useFormikContext();
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();

	const [isUploaded, setIsUploaded] = useState(false);

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[name];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	const validateFileSize = (event) => {
		const file = event.target.files[0];

		if (file.size > fileInputMaxFilesizeMB * 1024 * 1024) {
			dispatch(
				NotificationActions.pushNotification({ message: `File size exceeds ${fileInputMaxFilesizeMB}MB. Please choose a smaller file.`, options: { variant: 'error' } }),
			);
			event.target.value = ''; // clear the file input
			return false;
		} else {
			return true;
		}
	};

	return (
		<Box mb={1} className={cx(classes.root, props.className)}>
			<Box mb={1}>
				<Typography variant="body1" color="primary" className={classes.label}>
					<>
						{inputlabel}
						{isFieldRequired() && <span>*</span>}
					</>
				</Typography>
				<input
					className={classes.input}
					style={{ display: 'none' }}
					id="raised-button-file"
					type="file"
					onChange={(e) => {
						if (e.currentTarget.files && validateFileSize(e)) {
							setIsUploaded(true);
							setFieldValue(name, e.currentTarget.files[0]);
						}
					}}
					{...(accept ? { accept } : {})}
				/>
				<label htmlFor="raised-button-file">
					<Button className={classes.button} variant="outlined" component="span" color="primary">
						<Typography className={classes.browseLink} fontSize={14}>
							<span>Browse file</span> from your computer
						</Typography>
					</Button>
				</label>
			</Box>
			{disablePreview || (
				<Box className="preview">
					{isUploaded ? (
						<Image mb={1} src={URL.createObjectURL(value)} width={120} height={120} fit="cover" />
					) : isImageLoading() ? (
						<Skeleton variant="rectangular" width={120} height={120} />
					) : value ? (
						<Image mb={1} src={value} width={120} height={120} fit="cover" />
					) : (
						<Avatar size={120} md5={string2sha256('')} default="" />
					)}
				</Box>
			)}
		</Box>
	);
};

export default FormFileInput;
