import { format } from 'date-fns';
import { extToMimes } from 'constants/mimeTypes';
import { sha256 } from 'js-sha256';

export const dateFormat = (date) => {
	return format(date, 'P');
};

export function formatBytes(bytes, decimals) {
	if (bytes === 0) return '0 Bytes';
	var k = 1024,
		dm = decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function reorderList(list, startIndex, endIndex) {
	const result = [...list.filter((element) => element)];
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return [...result.map((item, idx) => ({ ...item, indexOrdinal: idx })).sort((a, b) => a.indexOrdinal - b.indexOrdinal)];
}

export const getRandomInt = (min = 1, max = 1000) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getFileExtFromURI = (uri) => {
	return uri.split(/[#?]/)[0].split('.').pop().trim();
};

export const getMimeTypeFromExtension = (ext) => {
	if (extToMimes.hasOwnProperty(ext)) {
		return extToMimes[ext];
	}
	return false;
};

export const joinLocationNames = (locations = []) => {
	return locations.map((location) => location.country.name).join(', ');
};

export const string2sha256 = (string) => {
	return sha256(string.trim().toLocaleLowerCase());
};

export const generateResumeFilename = (firstName, lastName, seniority, specialisation, updatedDate) => {
	const filenameArray = [];

	if (firstName) {
		filenameArray.push(firstName);
	}

	if (lastName) {
		filenameArray.push(lastName[0]);
	}

	if (seniority) {
		filenameArray.push(seniority);
	}

	if (specialisation) {
		filenameArray.push(specialisation.replaceAll(/[^A-Za-z0-9]/gi, ''));
	}

	filenameArray.push(dateFormat(updatedDate ? new Date(updatedDate) : new Date()).replaceAll(/[^A-Za-z0-9]/gi, ''));

	return filenameArray.join('_').replace(/_{2,}/g, '_');
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

function base64UrlEncode(input) {
	return btoa(String.fromCharCode.apply(null, new Uint8Array(input)))
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
}

export async function generatePKCE() {
	const array = new Uint8Array(32);
	window.crypto.getRandomValues(array);
	const codeVerifier = base64UrlEncode(array);
	const encoder = new TextEncoder();
	const data = encoder.encode(codeVerifier);
	const digest = await window.crypto.subtle.digest('SHA-256', data);
	const codeChallenge = base64UrlEncode(digest);

	return { codeVerifier, codeChallenge };
}
