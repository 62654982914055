import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogButton } from 'assets/icons';
import { useStyles } from './styles';

const DocPreview = (props) => {
	const { open, handleClose, preview, title = 'Preview' } = props;

	const { classes } = useStyles();

	return (
		<Dialog maxWidth="md" open={open} onClose={handleClose} fullWidth className={classes.root}>
			<DialogTitle>
				<Box display="flex" justifyContent="center" sx={{ padding: '12px' }}>
					<Typography variant="h4" sx={{ fontWeight: 700 }}>
						{title}
					</Typography>
				</Box>
				<IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
					<SvgIcon component={CloseDialogButton} inheritViewBox size={36} />
				</IconButton>
			</DialogTitle>
			<DialogContent>{preview}</DialogContent>
		</Dialog>
	);
};

export default DocPreview;
