import { Typography } from "@mui/material";
import { useState } from "react";

import { useStyles } from './styles';

const TruncatedText = ({ text, maxLength }) => {
  const [isExpanded, setExpanded] = useState(false);  

  const truncatedText = isExpanded || (text.length < maxLength + 20) ? text : text.slice(0, maxLength) + "...";

	const { classes } = useStyles();

  return (
    <>
      <Typography variant="body2" color="secondary">
        {truncatedText}
      </Typography>
      {text.length >= maxLength + 20 && (
        <Typography
          variant="body2"
          className={classes.truncatedBtn}
          onClick={() => setExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </Typography>
      )}
    </>
  );
};

export default TruncatedText;
