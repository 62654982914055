import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	file: null,
	uri: null,
	filename: null,
	templateId: null,
	whiteLabel: null,
};

const { actions, reducer } = createSlice({
	name: 'pdf',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.file = null;
			state.uri = null;
			state.filename = null;
			state.templateId = null;
			state.whiteLabel = null;
		},

		convertRequest: (state, { payload }) => {
			state.file = null;
			state.uri = null;
			state.filename = null;
			state.loading = true;
			state.error = false;
		},
		convertSuccess: (state, { payload }) => {
			state.loading = false;
			state.file = payload.file;
			state.uri = payload.uri;
			state.filename = payload.filename;
		},
		convertFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		generateRequest: (state, { payload }) => {
			state.file = null;
			state.loading = true;
			state.error = false;
			state.filename = null;
			state.templateId = null;
			state.whiteLabel = null;
		},
		generateSuccess: (state, { payload }) => {
			state.loading = false;
			state.file = payload.file;
			state.uri = payload.uri;
			state.filename = payload.filename;
			state.templateId = payload.templateId;
			state.whiteLabel = payload.whiteLabel;
		},
		generateFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getDownloadRequest: (state, { payload }) => {
			state.file = null;
			state.uri = null;
			state.filename = null;
			state.loading = true;
			state.error = false;
		},
		getDownloadSuccess: (state, { payload }) => {
			state.loading = false;
			state.file = payload.file;
			state.uri = payload.uri;
			state.filename = payload.filename;
		},
		getDownloadFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getDownloadTemplateRequest: (state, { payload }) => {
			state.file = null;
			state.loading = true;
			state.error = false;
		},
		getDownloadTemplateSuccess: (state, { payload }) => {
			state.loading = false;
			state.file = payload.file;
		},
		getDownloadTemplateFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as PDFActions };

export default reducer;
