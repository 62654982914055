import { styled } from '@mui/material/styles';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import colors from 'constants/colors';

const OutlinedWhiteIconButton = styled(IconButton)({
	display: 'inline-flex',
	padding: 10,
	justifyContent: 'center',
	alignItems: 'center',
	gap: 10,
	borderRadius: 6,
	border: `1px solid ${colors.iconWhiteButtonBorder}`,
	background: '#FFF',
	'& svg': {
		color: 'transparent',
	},
	[`&.${iconButtonClasses.disabled}`]: {
		opacity: 0.5,
	},
});

export default OutlinedWhiteIconButton;
