import React, { lazy } from 'react';
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom';
import * as routePaths from 'constants/routes';
import { INTERFACE_BUSINESS, INTERFACE_INDIVIDUAL } from 'constants/interfaces';
import LayoutRoute from 'hoc/LayoutRoute';

import AuthCallback from 'scenes/Auth/scenes/AuthCallback';
import SilentRenew from 'scenes/Auth/scenes/SilentRenew';
import AccountType from 'scenes/Auth/scenes/AccountType';
import VacancyForm from 'scenes/Vacancies/scenes/Form/VacancyForm';
import MatchingVacancyList from 'scenes/Matching/scenes/MatchingVacancyList';
import ParseVacancy from 'scenes/Vacancies/scenes/ParseVacancy';
import MatchingResumeList from 'scenes/Matching/scenes/MatchingResumeList';
import { useFeatureFlag } from 'context/featureFlagContext';
import MatchMyResumeList from 'scenes/Matching/scenes/MatchMyResumeList/MatchMyResumeList';
import MatchEmployeeResumeList from 'scenes/Matching/scenes/MatchEmployeeResumeList';
import PrivacyPolicy from 'scenes/Policies/scenes/PrivacyPolicy';

const CompaniesList = lazy(() => import('scenes/Companies/scenes/List'));
const VacancyList = lazy(() => import('scenes/Vacancies/scenes/List'));
const CompanyForm = lazy(() => import('scenes/Companies/scenes/Form'));
const MyResumeList = lazy(() => import('scenes/Resume/scenes/MyResumeList'));
const UploadResume = lazy(() => import('scenes/Resume/scenes/Upload'));
const ResumeForm = lazy(() => import('scenes/Resume/scenes/Form'));
const CoverLettersList = lazy(() => import('scenes/CoverLetters/scenes/List'));
const CoverLetterForm = lazy(() => import('scenes/CoverLetters/scenes/Form'));
const EmployeesList = lazy(() => import('scenes/Employees/scenes/List'));
const EmployeeForm = lazy(() => import('scenes/Employees/scenes/Form'));
const EmployeeDetails = lazy(() => import('scenes/Employees/scenes/Details'));
const UploadEmployees = lazy(() => import('scenes/Employees/scenes/Upload'));
const MyAccount = lazy(() => import('scenes/User/scenes/Form'));

const Router = () => {
	const indexRoute = routePaths.ROUTE_BUSINESS_COMPANY_LIST;
	const { matchingEnabled, vacanciesEnabled, userManagementEnabled } = useFeatureFlag();

	return (
		<BrowserRouter>
			<Routes>
				<Route path={routePaths.ROUTE_HOME} element={<Navigate replace to={indexRoute} />} />
				<Route path={routePaths.ROUTE_404} element={<Navigate replace to={indexRoute} />} />
				<Route path={routePaths.ROUTE_PRIVACY_POLICY} element={<PrivacyPolicy />} />

				<Route path={routePaths.ROUTE_AUTH_CALLBACK} element={<AuthCallback />} />
				<Route path={routePaths.ROUTE_AUTH_SILENTRENEW} element={<SilentRenew />} />
				<Route path={routePaths.ROUTE_AUTH_ACCOUNT_TYPE} element={<AccountType />} />

				{/*BUSINESS ROUTES*/}

				<Route path={routePaths.ROUTE_BUSINESS_COMPANY_LIST} element={<LayoutRoute component={CompaniesList} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route path={routePaths.ROUTE_BUSINESS_COMPANY_ADD} element={<LayoutRoute component={CompanyForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route path={routePaths.ROUTE_BUSINESS_COMPANY_EDIT} element={<LayoutRoute component={CompanyForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />

				{vacanciesEnabled && (
					<>
						<Route path={routePaths.ROUTE_BUSINESS_VACANCY_LIST} element={<LayoutRoute component={VacancyList} exact protection interfaceType={INTERFACE_BUSINESS} />} />
						<Route
							path={routePaths.ROUTE_BUSINESS_VACANCY_PARSE}
							element={<LayoutRoute component={ParseVacancy} exact protection interfaceType={INTERFACE_BUSINESS} />}
						/>
						<Route path={routePaths.ROUTE_BUSINESS_VACANCY_ADD} element={<LayoutRoute component={VacancyForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />
						<Route path={routePaths.ROUTE_BUSINESS_VACANCY_EDIT} element={<LayoutRoute component={VacancyForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />
					</>
				)}

				<Route path={routePaths.ROUTE_BUSINESS_EMPLOYEES} element={<LayoutRoute component={EmployeesList} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route path={routePaths.ROUTE_BUSINESS_EMPLOYEES_ADD} element={<LayoutRoute component={UploadEmployees} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route path={routePaths.ROUTE_BUSINESS_EMPLOYEES_CREATE} element={<LayoutRoute component={EmployeeForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route path={routePaths.ROUTE_BUSINESS_EMPLOYEES_EDIT} element={<LayoutRoute component={EmployeeForm} exact protection interfaceType={INTERFACE_BUSINESS} />} />
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_DETAILS}
					element={<LayoutRoute component={EmployeeDetails} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_RESUME_UPLOAD}
					element={<LayoutRoute component={UploadResume} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_RESUME_ADD}
					element={<LayoutRoute component={ResumeForm} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_RESUME_EDIT}
					element={<LayoutRoute component={ResumeForm} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_ADD}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_ADD_FOR_RESUME}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>
				<Route
					path={routePaths.ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_EDIT}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_BUSINESS} />}
				/>

				{matchingEnabled && (
					<>
						<Route
							path={routePaths.ROUTE_BUSINESS_MATCHING}
							element={<LayoutRoute component={MatchingResumeList} exact protection interfaceType={INTERFACE_BUSINESS} />}
						/>
						<Route
							path={routePaths.ROUTE_BUSINESS_MATCHING_FOR_VACANCY}
							element={<LayoutRoute component={MatchingResumeList} exact protection interfaceType={INTERFACE_BUSINESS} />}
						/>
						<Route
							path={routePaths.ROUTE_BUSINESS_MATCHING_FOR_EMPLOYEE_RESUME}
							element={<LayoutRoute component={MatchingVacancyList} exact protection interfaceType={INTERFACE_BUSINESS} />}
						/>
						<Route
							path={routePaths.ROUTE_BUSINESS_MATCHING_EMPLOYEE_RESUME}
							element={<LayoutRoute component={MatchEmployeeResumeList} exact protection interfaceType={INTERFACE_BUSINESS} />}
						/>
					</>
				)}

				{/*PERSONAL ROUTES*/}

				<Route path={routePaths.ROUTE_PERSONAL_MY_RESUME} element={<LayoutRoute component={MyResumeList} exact protection interfaceType={INTERFACE_INDIVIDUAL} />} />
				<Route
					path={routePaths.ROUTE_PERSONAL_MY_RESUME_UPLOAD}
					element={<LayoutRoute component={UploadResume} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
				/>
				<Route path={routePaths.ROUTE_PERSONAL_MY_RESUME_CREATE} element={<LayoutRoute component={ResumeForm} exact protection interfaceType={INTERFACE_INDIVIDUAL} />} />
				<Route path={routePaths.ROUTE_PERSONAL_MY_RESUME_EDIT} element={<LayoutRoute component={ResumeForm} exact protection interfaceType={INTERFACE_INDIVIDUAL} />} />

				<Route
					path={routePaths.ROUTE_PERSONAL_COVER_LETTERS}
					element={<LayoutRoute component={CoverLettersList} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
				/>
				<Route
					path={routePaths.ROUTE_PERSONAL_COVER_LETTER_CREATE}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
				/>
				<Route
					path={routePaths.ROUTE_PERSONAL_COVER_LETTER_CREATE_FOR_RESUME}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
				/>
				<Route
					path={routePaths.ROUTE_PERSONAL_COVER_LETTER_EDIT}
					element={<LayoutRoute component={CoverLetterForm} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
				/>

				{matchingEnabled && (
					<>
						<Route
							path={routePaths.ROUTE_PERSONAL_MATCHING}
							element={<LayoutRoute component={MatchingVacancyList} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
						/>
						<Route
							path={routePaths.ROUTE_PERSONAL_MATCHING_FOR_RESUME}
							element={<LayoutRoute component={MatchingVacancyList} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
						/>
						<Route
							path={routePaths.ROUTE_PERSONAL_MATCHING_MY_RESUME_LIST}
							element={<LayoutRoute component={MatchMyResumeList} exact protection interfaceType={INTERFACE_INDIVIDUAL} />}
						/>
					</>
				)}

				{userManagementEnabled && <Route path={routePaths.ROUTE_MY_ACCOUNT} element={<LayoutRoute component={MyAccount} exact protection />} />}

				{/* Fallback */}
				<Route path="*" element={<Navigate replace to={routePaths.ROUTE_404} />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
