import colors from 'constants/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		skillsList: {
			borderRadius: 8,
			background: '#F6F6F6',
			padding: '30px 30px 0',
			height: 450,
			overflowY: 'auto',

			'& .MuiListItem-root': {
				borderRadius: 8,
				background: '#FFF',
				marginBottom: 10,
				paddingRight: 96,
			},

			'& .MuiListItemText-primary': {
				color: colors.ListTextColorPurple,
				fontSize: '1.2rem',
				fontWeight: 700,
				lineHeight: '1.6rem',
				textTransform: 'uppercase',
			},

			'& .MuiIconButton-root': {
				'&.visibility': {
					opacity: 0.3,
					'&.visible': {
						opacity: 1,
					},
				},
			},
		},
	};
});
