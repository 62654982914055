import { Popper } from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';

const PopperWrapper = ({ children, content }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { classes } = useStyles();

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<div onClick={handleClick} className={classes.children}>
				{children}
			</div>
			<Popper className={classes.wrapper} open={open} anchorEl={anchorEl} placement="bottom">
				<div className={classes.content}>{content}</div>
			</Popper>
		</div>
	);
};

export default PopperWrapper;
