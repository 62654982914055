import { SvgIcon } from '@mui/material';
import { TrashIcon } from 'assets/icons';
import OutlinedIconButton from '../OutlinedIconButton';

const DeleteButton = (props) => (
	<OutlinedIconButton aria-label="view" {...props}>
		<SvgIcon component={TrashIcon} inheritViewBox size={18} />
	</OutlinedIconButton>
);

export default DeleteButton;
