import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { AuthProvider } from 'context/authContext';
import configureStore from 'store/configureStore';
import httpService from 'services/http';
import SnackbarProvider from 'shared/snackbar/Provider';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore();

httpService.setDefaultAxios();

root.render(
	// React.StrictMode is disabled
	<>
		<AuthProvider>
			<Provider store={store}>
				<SnackbarProvider maxSnack={3}>
					<App />
				</SnackbarProvider>
			</Provider>
		</AuthProvider>
	</>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
