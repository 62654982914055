import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		input: {
			height: 36,
			minWidth: 300,

			'& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart': {
				padding: "8px 14px 8px 0",
			},
		},
	};
});
