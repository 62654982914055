import { Box, Table, TableBody, TableHead, TableRow, Typography, Stack, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow } from 'shared/components/Table';
import TruncatedText from 'shared/components/Table/TruncatedText';
import { useStyles } from '../styles';
import UnfoldButton from 'shared/components/Buttons/UnfoldButon/UnfoldButton';
import PercentageButton from 'shared/components/Buttons/Percentage/Percentage';
import { joinLocationNames } from 'helpers/format';
import { EyeButton } from 'shared/components/Buttons';

const MatchedVacancyRow = ({ emplRes, matchFunc, setVacancyJson, handleViewVacancy }) => {
	const { classes } = useStyles();

	const [matchedResults, setMatchedResults] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [unfoldedMatchedVacancies, SetUnfoldedMatchedVacancies] = useState([{}]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const results = await matchFunc(emplRes.id, 15, false);
				setMatchedResults(results);
				setIsLoading(false);
			} catch (error) {
				console.log(error, 'error');
			}
		};

		fetchData();
	}, []);

	const toggleUnfoldEmployeeMatches = (emplResId, vacancyId) => {
		if (unfoldedMatchedVacancies.some((unfolded) => unfolded.emplResId === emplResId && unfolded.vacancyId === vacancyId)) {
			SetUnfoldedMatchedVacancies(unfoldedMatchedVacancies.filter((unfolded) => unfolded.emplResId !== emplResId || unfolded.vacancyId !== vacancyId));
		} else {
			SetUnfoldedMatchedVacancies([...unfoldedMatchedVacancies, { emplResId, vacancyId }]);
		}
	};

	if (isLoading)
		return (
			<StyledTableRow>
				<StyledTableCell colSpan={7}>
					<Typography>Loading</Typography>
				</StyledTableCell>
			</StyledTableRow>
		);

	return matchedResults?.map(({ vacancy, companyContact }) => {
		return (
			<React.Fragment key={emplRes.id + vacancy.id}>
				<StyledTableRow>
					<StyledTableCell>
						<Typography variant="body1" color="primary">
							{emplRes.name}
						</Typography>
						<Typography variant="body2" color="primary">
							{emplRes.specialisation}
						</Typography>
					</StyledTableCell>
					<StyledTableCell>
						<Typography variant="subtitle1" color="primary">
							{emplRes.seniority}
						</Typography>
					</StyledTableCell>
					<StyledTableCell></StyledTableCell>
					<StyledTableCell>
						<Typography variant="subtitle1" color="primary">
							{emplRes.location?.country?.name}
						</Typography>
					</StyledTableCell>
					<StyledTableCell></StyledTableCell>
					<StyledTableCell align="center">
						<PercentageButton>86%</PercentageButton>
					</StyledTableCell>
					<StyledTableCell align="right">
						<Stack spacing={1} direction="row" justifyContent="flex-end">
							<UnfoldButton
								onClick={() => toggleUnfoldEmployeeMatches(emplRes.id, vacancy.id)}
								isOpened={unfoldedMatchedVacancies?.some((unfolded) => unfolded.emplResId === emplRes.id && unfolded.vacancyId === vacancy.id)}
								sx={{ border: 0 }}
							/>
						</Stack>
					</StyledTableCell>
				</StyledTableRow>
				{unfoldedMatchedVacancies?.some((unfolded) => unfolded.emplResId === emplRes.id && unfolded.vacancyId === vacancy.id) && (
					<StyledTableRow>
						<TableCell colSpan={7} padding="none">
							<Table aria-label="employee vacancy table">
								<TableHead>
									<TableRow>
										<StyledTableCell>Vacancy</StyledTableCell>
										<StyledTableCell>Seniority</StyledTableCell>
										<StyledTableCell>Main Stack</StyledTableCell>
										<StyledTableCell>Languages</StyledTableCell>
										<StyledTableCell>Location</StyledTableCell>
										<StyledTableCell>Workload</StyledTableCell>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell align="right">Action</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<StyledTableRow>
										<StyledTableCell>
											<Typography variant="body1" color="primary">
												{vacancy.companyName}
											</Typography>
											<Typography variant="body2" color="primary">
												{vacancy.specialisation}
											</Typography>
										</StyledTableCell>
										<StyledTableCell>
											<Typography variant="subtitle1" color="primary">
												{vacancy.seniority}
											</Typography>
										</StyledTableCell>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell>{vacancy.languageRequirements}</StyledTableCell>
										<StyledTableCell>
											<Typography variant="subtitle1" color="primary">
												{joinLocationNames(vacancy.locations)}
											</Typography>
										</StyledTableCell>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell align="center">
											<PercentageButton>86%</PercentageButton>
										</StyledTableCell>
										<StyledTableCell align="right">
											<Stack spacing={1} direction="row" justifyContent="flex-end">
												<EyeButton onClick={() => handleViewVacancy(vacancy)} />
											</Stack>
										</StyledTableCell>
									</StyledTableRow>
									<StyledTableRow>
										<StyledTableCell colSpan={7}>
											<Box className={classes.openedRow}>
												<Box className={classes.openedRowLeft}>
													<Typography variant="subtitle2" className={classes.greyText}>
														Description
													</Typography>
													<Typography variant="subtitle1" color="secondary">
														<TruncatedText text={vacancy.projectDescription.reduce((acc, currentDescr) => acc + currentDescr.description, '')} maxLength={150} />
													</Typography>
												</Box>
												<Box className={classes.openedRowRight}>
													<Typography variant="subtitle2" className={classes.greyText}>
														Contacts
													</Typography>
													{companyContact?.companyName && (
														<Typography variant="subtitle1" color="secondary">
															{companyContact.companyName}
														</Typography>
													)}
													{companyContact?.email && (
														<Typography variant="subtitle1" color="secondary">
															{companyContact.email}
														</Typography>
													)}
													{companyContact?.phone && (
														<Typography variant="subtitle1" color="secondary">
															{companyContact.phone}
														</Typography>
													)}
													{companyContact?.skype && (
														<Typography variant="subtitle1" color="secondary">
															{companyContact.skype}
														</Typography>
													)}
													{companyContact?.telegram && (
														<Typography variant="subtitle1" color="secondary">
															{companyContact.telegram}
														</Typography>
													)}
												</Box>
											</Box>
										</StyledTableCell>
									</StyledTableRow>
								</TableBody>
							</Table>
						</TableCell>
					</StyledTableRow>
				)}
			</React.Fragment>
		);
	});
};

export default MatchedVacancyRow;
