import colors from 'constants/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			position: 'absolute',
			right: 60,
			top: 32,
			float: 'right',
			outline: 'none',

			minWidth: 175,
			height: 56,

			borderRadius: '6px',
			border: '1px solid #F6F4FE',

			'.MuiOutlinedInput-notchedOutline': { border: 0 },
			'.MuiSelect-select.MuiInputBase-input.MuiSelect-outlined': {
				width: '100%',
				minWidth: 103,
				display: 'flex',
				padding: '8px',
				paddingRight: '50px',
			},
			'&:hover': {
				outline: 'none',
			},
			'&:focus': {
				outline: 'none',
			},
		},

		icon: {
			width: '24px',
			height: '24px',
			margin: '0px 8px',
			top: 'calc(50% - 12px)!important',
		},
		iconOpen: { transform: 'rotate(180deg)' },

		logoContainer: {
			width: 40,
			height: 40,
			borderRadius: '50%',
			overflow: 'hidden',
			marginRight: 10,
		},
		menuItem: {
			display: 'flex',
			padding: 8,
			minWidth: 140,
		},
		textWrapper: {
			display: 'flex',
			flexDirection: 'column',
		},
		label: {
			fontWeight: 500,
			lineHeight: 'normal',
		},
		type: {
			color: colors.textColorGrey,
		},
	};
});
