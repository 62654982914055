import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loader = () => {
	return (
		<Box>
			<CircularProgress />
		</Box>
	);
};

export default Loader;
