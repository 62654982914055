import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
  return {
    truncatedBtn: {
      color: colors.readMoreText,
      cursor: 'pointer',
    },
  };
});
