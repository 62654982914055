import axios from 'axios';
import { GENERATE_RESUME_PDF, GENERATE_RESUME_DOWNLOAD_URL, GENERATE_RESUME_PDF_DEFAULT } from 'services/api';
import { PDFActions } from './reducer';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(PDFActions.setInitial());
	};
}

export function generateResumePdf(resumeId, templateId, whiteLabel = false) {
	return async function (dispatch, getState) {
		dispatch(PDFActions.generateRequest());
		try {
			const { data } = await axios.post(GENERATE_RESUME_PDF, {}, { params: { resumeId, templateId, whiteLabel }, responseType: 'blob' });

			dispatch(PDFActions.generateSuccess({ file: data, uri: window.URL.createObjectURL(data), filename: 'my_resume.pdf', templateId, whiteLabel }));
		} catch (e) {
			console.log({ e });
			dispatch(PDFActions.generateFailure());
		}
	};
}

export function generateResumePdfDefault(resumeId, whiteLabel = false) {
	return async function (dispatch, getState) {
		dispatch(PDFActions.convertRequest());
		try {
			const { data } = await axios.post(GENERATE_RESUME_PDF_DEFAULT, {}, { params: { resumeId, whiteLabel }, responseType: 'blob' });

			dispatch(PDFActions.convertSuccess({ file: data, uri: window.URL.createObjectURL(data), filename: 'my_resume.pdf' }));
		} catch (e) {
			console.log({ e });
			dispatch(PDFActions.convertFailure());
		}
	};
}

export function getDownload(fileURI) {
	return async function (dispatch, getState) {
		dispatch(PDFActions.getDownloadRequest());

		try {
			const { data: fileURL } = await axios.get(GENERATE_RESUME_DOWNLOAD_URL, { params: { fileS3Uri: fileURI } });

			const uninterceptedAxiosInstance = axios.create();
			const { data } = await uninterceptedAxiosInstance.get(fileURL, {
				responseType: 'blob',
			});

			dispatch(PDFActions.getDownloadSuccess({ file: data, uri: fileURL, filename: fileURI.replace('/', '-') }));
		} catch (e) {
			console.log({ e });
			dispatch(PDFActions.getDownloadFailure());
		}
	};
}
