import React, { useState, useRef } from 'react';

import { Button, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, SvgIcon } from '@mui/material';
import { ArrowDownWhiteIcon } from 'assets/icons';

const SplitButton = (props) => {
	const { options, ...rest } = props;

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex] = useState(0);

	const handleMenuItemClick = (event, index) => {
		event.preventDefault();
		options[index].action();
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
				<Button {...rest} onClick={(event) => handleMenuItemClick(event, selectedIndex)} disabled={options[selectedIndex].disabled}>
					{options[selectedIndex].title}
				</Button>
				<Button
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
					disabled={options.map((item) => item.disabled).every((item) => item === true)}
				>
					<SvgIcon component={ArrowDownWhiteIcon} inheritViewBox size={20} sx={{ fill: 'red' }} />
				</Button>
			</ButtonGroup>
			<Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem key={option.title} disabled={option.disabled} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
											{option.title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

export default SplitButton;
