import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& .MuiBreadcrumbs-li a': {
				color: colors.linkText,
				textDecoration: 'none',
			},
		},
	};
});
