import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useStyles } from '../styles';

const FormGroupField = (props) => {
	const { children, inputlabel, required = false, ...rest } = props;
	const { errors } = useFormikContext();
	const { classes, cx } = useStyles();

	const childrenFields = children.map((item) => item.props.name);
	const validateErrors = childrenFields.reduce((acc, cur) => (errors[cur] ? [...acc, errors[cur]] : acc), []);

	return (
		<Box mb={1} className={cx(classes.selectAndInputField, rest.className, { invalid: validateErrors.length > 0 })}>
			{inputlabel && (
				<Typography variant="body1" color="primary" className={classes.label}>
					<>
						{inputlabel}
						{required && <span className="required">*</span>}
					</>
				</Typography>
			)}
			<Box display="flex" className="combinedField">
				{children}
			</Box>
			{validateErrors.length > 0 && (
				<Box className="childfieldsError">
					{validateErrors.map((item) => (
						<Typography variant="subtitle2" color="error" key={item}>
							{item}
						</Typography>
					))}
				</Box>
			)}
		</Box>
	);
};

export default FormGroupField;
