import React from 'react';
import { code } from 'country-emoji';
import Skeleton from '@mui/material/Skeleton';

const CountryFlag = (props) => {
	const { country } = props;

	const countryCode = code(country);

	return countryCode ? (
		<img
			loading="lazy"
			width="22"
			srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
			src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
			alt={country}
		/>
	) : (
		<Skeleton variant="rectangular" width={22} height={14} sx={{ marginRight: 2 }} />
	);
};

export default CountryFlag;
