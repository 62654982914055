import React from 'react';
import { useAuth } from 'context/authContext';
import Route from 'shared/components/Route';

const PrivateRoute = (props) => {
	const { component: Component, ...rest } = props;

	const { isAuthenticated, login } = useAuth();

	if (!isAuthenticated()) {
		login();
		return <></>;
	}

	return <Route {...rest} component={Component} />;
};

export default PrivateRoute;
