import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Button, SvgIcon, List, ListItem, ListItemText, IconButton, Grid } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { TrashIcon, MooveIcon, EyeIcon } from 'assets/icons';
import { reorderList } from 'helpers/format';
import { useStyles } from './styles';
import FormAutoCompletedField from 'shared/components/Form/FormAutoCompletedField';
import { SplitButton } from 'shared/components/Buttons';

const SkillsStep = (props) => {
	const { skills = [], handleNext, actionButton, handleBack, skillList, skillKey, isEditing } = props;

	const { classes, cx } = useStyles();

	const [sortedSkills, setSortedSkills] = useState([...skills].sort((a, b) => a.indexOrdinal - b.indexOrdinal));
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		if (skills.length === 0) return;
		setSortedSkills([...skills].sort((a, b) => a.indexOrdinal - b.indexOrdinal));
	}, [skills]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorderList(sortedSkills, result.source.index, result.destination.index);
		setSortedSkills(items);
		setDirty(true);
	};

	const handleDelete = (index) => {
		const result = [...sortedSkills];
		result.splice(index, 1);
		setSortedSkills([...result.map((item, idx) => ({ ...item, indexOrdinal: idx })).sort((a, b) => a.indexOrdinal - b.indexOrdinal)]);
		setDirty(true);
	};

	const handleNextButton = (goNext = true) => {
		handleNext(sortedSkills, dirty, goNext);
	};

	const handleToggle = (index) => {
		const result = [...sortedSkills];
		result[index] = { ...result[index], visible: !result[index].visible };
		setSortedSkills(result);
		setDirty(true);
	};

	const validationSchema = Yup.object().shape({
		skill: Yup.string().required(),
	});

	const addNewSkill = ({ skill }) => {
		const newSkills = [...[{ [skillKey]: skill, indexOrdinal: 0, id: `new-skill-${sortedSkills.length}`, visible: true }], ...sortedSkills];
		const sorted = newSkills.map((item, idx) => ({ ...item, indexOrdinal: idx })).sort((a, b) => a.indexOrdinal - b.indexOrdinal);
		setSortedSkills(sorted);
		setDirty(true);
	};

	const getOptionDisabled = useMemo(() => {
		const skillNames = new Set(sortedSkills.map((skill) => skill[skillKey]));
		return (option) => skillNames.has(option.skill);
	}, [sortedSkills]);

	return (
		<>
			<Box>
				<PageTitle title="Add your skills" subtitle="Lets add your top skills" actionButton={actionButton} />
			</Box>
			<Grid container spacing={11}>
				<Grid item xs={6}>
					<Formik
						initialValues={{
							skillKey: '',
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { resetForm }) => {
							addNewSkill(values);
							resetForm();
						}}
						enableReinitialize
					>
						{({ values, dirty, isValid, submitForm, isSubmitting }) => (
							<Form>
								<FormAutoCompletedField
									fullWidth
									variant="outlined"
									name="skills"
									type="text"
									placeholder="Add Skills"
									inputlabel="Add new skill"
									value={values.skill}
									required
									getOptionDisabled={getOptionDisabled}
									getOptionLabel={(option) => option.skill}
									onComplete={(elem) => {
										if (!elem) return;
										addNewSkill(elem);
									}}
									options={skillList}
									formValue={values}
									makeDirty={() => (dirty = true)}
									valueFromEdit={values.skill}
								/>
							</Form>
						)}
					</Formik>
				</Grid>
				<Grid item xs={6}>
					{sortedSkills && (
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<List ref={provided.innerRef} className={classes.skillsList} {...provided.droppableProps}>
										{sortedSkills.map((item, idx) => (
											<Draggable key={item.id} draggableId={item.id} index={idx}>
												{(provided, snapshot) => (
													<ListItem
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														secondaryAction={
															<>
																<IconButton aria-label="visibility" onClick={() => handleToggle(idx)} className={cx('visibility', { visible: item.visible })}>
																	<SvgIcon component={EyeIcon} inheritViewBox size={18} />
																</IconButton>
																<IconButton aria-label="delete" onClick={() => handleDelete(idx)}>
																	<SvgIcon component={TrashIcon} inheritViewBox size={18} />
																</IconButton>
																<IconButton aria-label="move">
																	<SvgIcon component={MooveIcon} inheritViewBox size={18} />
																</IconButton>
															</>
														}
													>
														<ListItemText primary={item[skillKey]} />
													</ListItem>
												)}
											</Draggable>
										))}
									</List>
								)}
							</Droppable>
						</DragDropContext>
					)}
				</Grid>
			</Grid>
			<Box className="bottomActions">
				<Button color="secondary" variant="contained" size="large" onClick={handleBack}>
					Back
				</Button>
				<div className="spacer" />
				<SplitButton
					color="primary"
					variant="contained"
					size="large"
					options={[
						{ title: 'Next', action: () => handleNextButton() },
						{ title: 'Save', action: () => handleNextButton(false), disabled: !isEditing || !dirty },
					]}
				/>
			</Box>
		</>
	);
};

export default SkillsStep;
