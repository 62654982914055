import React from 'react';
import { Box, InputAdornment, SvgIcon, TextField } from '@mui/material';
import { SearchIcon } from 'assets/icons';
import { useStyles } from './styles';

const FormSearchInput = () => {
	const { classes } = useStyles();

	return (
		<Box>
			<TextField
				className={classes.input}
				placeholder="Search"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SvgIcon component={SearchIcon} inheritViewBox size={24} />
						</InputAdornment>
					),
				}}
			/>
		</Box>
	);
};

export default FormSearchInput;
