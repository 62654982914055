import { ROUTE_AUTH_ACCOUNT_TYPE } from 'constants/routes';
import { useManagement } from 'context/managementContext';
import React, { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PrivateRoute from 'routes/components/PrivateRoute';
import PublicRoute from 'routes/components/PublicRoute';

const Loader = () => <div>Loading...</div>;

const LayoutRoute = ({ protection, component: Component, interfaceType = null, ...rest }) => {
	const navigate = useNavigate();
	const { user, isLoading: isManagementLoading, hasSelected } = useManagement();

	useEffect(() => {
		if (!hasSelected() && !isManagementLoading) navigate(ROUTE_AUTH_ACCOUNT_TYPE);
	}, [user]);

	const Structure = (
		<Suspense fallback={<Loader />}>
			<Component />
		</Suspense>
	);

	const getInterfaceType = () => {
		if (interfaceType) return interfaceType;

		return user ? user.currentManagement.toLocaleLowerCase() : '';
	};

	return protection ? <PrivateRoute {...rest} interfaceType={getInterfaceType()} component={Structure} /> : <PublicRoute {...rest} component={Structure} />;
};

export default LayoutRoute;
