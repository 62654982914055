import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';

const FormActions = (props) => {
	const { children, ...rest } = props;
	const { classes } = useStyles();
	return (
		<Box className={classes.root} {...rest}>
			{children}
		</Box>
	);
};

export default FormActions;
