import colors from 'constants/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {},
		label: {
			fontSize: '1.4rem',
			fontWeight: 500,
			lineHeight: '2.4rem',
			letterSpacing: '-0.2px',
			marginBottom: '6px',

			'& span': {
				color: colors.labelRequiredAsteriskColor,
				fontSize: '1.4rem',
				fontWeight: 500,
				lineHeight: '2.4rem',
				letterSpacing: '-0.2px',
				paddingLeft: '0.5em',
			},
		},
		inputContainer: {
			display: 'inline-flex',
			alignItems: 'center',
			minWidth: 320,
			height: 42,
			borderRadius: '6px',
			border: '1px solid #AFBACA',
		},

		select: {
			border: 'none',
			'.MuiSelect-select': {
				display: 'flex',
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
			},
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
			},
			'.MuiSelect-select.MuiInputBase-input.MuiSelect-outlined': {
				display: 'flex',
				padding: '0px 0px 0px 14px',
				width: '24px',
			},
		},
		icon: {
			width: '26px',
			height: '24px',
			margin: '0 4px',
		},
		iconOpen: {
			transform: 'rotate(180deg)',
		},

		textField: {
			'.MuiInputBase-input.MuiOutlinedInput-input': {
				paddingLeft: 8,
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
			},
			'& .MuiOutlinedInput-root': {
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
			},
		},

		separator: {
			display: 'block',
			background: '#D7DFE9',
			width: '1px',
			height: '16px',
		},
	};
});
