import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { EnhancedFormik } from 'context/formikContext';
import { Box, Button, SvgIcon, IconButton } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { FormTextField, FormActions } from 'shared/components/Form';
import { TrashAltIcon } from 'assets/icons';

const CandidateResponsibilitiesStep = (props) => {
	const { details, handleSubmit, actionButton, handleBack } = props;

	const validationSchema = Yup.object().shape({
		candidateResponsibilities: Yup.array().of(
			Yup.object().shape({
				responsibility: Yup.string().required('Required'),
			}),
		),
	});

	const responsibilityEntity = { responsibility: '' };

	return (
		<>
			<PageTitle title="Candidate responsibilities" actionButton={actionButton} />

			<Box>
				<EnhancedFormik validationSchema={validationSchema}>
					<Formik
						initialValues={{
							candidateResponsibilities: details.candidateResponsibilities || [responsibilityEntity],
						}}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						validateOnMount
						enableReinitialize
					>
						{({ values, dirty, isValid, submitForm, isSubmitting }) => (
							<Form>
								<FieldArray
									name="candidateResponsibilities"
									render={(arrayHelpers) => (
										<>
											{values.candidateResponsibilities.map(({ id, responsibility }, index) => (
												<Box mb={4} gap={6} display="flex" alignItems="center" key={id}>
													<Box flexGrow={1}>
														<FormTextField
															fullWidth
															variant="outlined"
															name={`candidateResponsibilities.${index}.responsibility`}
															type="text"
															inputlabel="Responsibility"
															value={responsibility}
															required
														/>
													</Box>
													<Box justifySelf="flex-end">
														<IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
															<SvgIcon component={TrashAltIcon} inheritViewBox size={18} />
														</IconButton>
													</Box>
												</Box>
											))}

											<Box>
												<Button
													color="secondary"
													variant="contained"
													size="large"
													disabled={isSubmitting}
													onClick={() =>
														arrayHelpers.push({ ...responsibilityEntity, id: `new-responsibility-${arrayHelpers.form.values.candidateResponsibilities.length}` })
													}
												>
													Add more
												</Button>
											</Box>
										</>
									)}
								/>

								<FormActions display="flex" justifyContent="flex-end" className="bottomActions" gap={2}>
									<Button color="secondary" variant="contained" size="large" disabled={isSubmitting} onClick={handleBack}>
										Back
									</Button>
									<div className="spacer" />
									<Button
										color="primary"
										variant="contained"
										size="large"
										type="submit"
										disabled={!isValid || isSubmitting}
										onClick={(e) => {
											e.preventDefault();
											handleSubmit(values, dirty);
										}}
									>
										Finish
									</Button>
								</FormActions>
							</Form>
						)}
					</Formik>
				</EnhancedFormik>
			</Box>
		</>
	);
};
export default CandidateResponsibilitiesStep;
