import React from 'react';
import { Box, Typography, Button, SvgIcon } from '@mui/material';
import { PlusIcon } from 'assets/icons';
import { useStyles } from './styles';
import PopperWrapper from '../PopperWrapper';
import FormSearchInput from '../Form/FormSearchInput';

const PageTitle = (props) => {
	const { title, subtitle, searchEnabled = false, actionTitle, actionHandler, actionButton, actionIsDisabled = false, popperText } = props;
	const { classes } = useStyles();

	return (
		<Box className={classes.wrapper} display="flex" justifyContent="space-between" alignItems="center">
			<Box>
				<Typography color="primary" variant="h2" className={classes.title}>
					{title}
				</Typography>
				{subtitle && (
					<Typography color="secondary" variant="subtitle1" className={classes.subtitle}>
						{subtitle}
					</Typography>
				)}
			</Box>
			{searchEnabled && (
				<Box className={classes.search}>
					<FormSearchInput />{' '}
				</Box>
			)}
			{actionButton && actionButton}
			{actionTitle &&
				actionHandler &&
				(popperText ? (
					<PopperWrapper content={popperText}>
						<Button
							variant="contained"
							color="primary"
							onClick={actionHandler}
							className={classes.action}
							startIcon={<SvgIcon component={PlusIcon} inheritViewBox size={12} />}
							disabled={actionIsDisabled}
						>
							{actionTitle}
						</Button>
					</PopperWrapper>
				) : (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={actionHandler}
							className={classes.action}
							startIcon={<SvgIcon component={PlusIcon} inheritViewBox size={12} />}
							disabled={actionIsDisabled}
						>
							{actionTitle}
						</Button>
					</>
				))}
		</Box>
	);
};

export default PageTitle;
