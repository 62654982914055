import React, { createContext, useContext } from 'react';
import { INTERFACE_BUSINESS, INTERFACE_INDIVIDUAL } from 'constants/interfaces';

const InterfaceContext = createContext({ interfaceType: INTERFACE_BUSINESS });

const useInterface = () => useContext(InterfaceContext);

function InterfaceProvider({ interfaceType, children }) {
	return <InterfaceContext.Provider value={{ interfaceType, isIndividual: interfaceType === INTERFACE_INDIVIDUAL }}>{children}</InterfaceContext.Provider>;
}

export { useInterface, InterfaceProvider };
