import React from 'react';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';
import { useStyles } from '../styles';

const PaginationActions = (props) => {
	const { count, page, rowsPerPage, onPageChange } = props;

	const { classes, cx } = useStyles();

	const totalPages = Math.ceil(count / rowsPerPage);

	const pages = [...Array(totalPages).keys()];

	const handlePreviousButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handlePageNumberButtonClick = (event, page) => {
		onPageChange(event, page);
	};

	return (
		<Box display="flex" alignItems="center" gap={1}>
			<Button
				onClick={handlePreviousButtonClick}
				disabled={page === 0}
				aria-label="previous page"
				startIcon={<ArrowLeftIcon />}
				size="small"
				variant="outlined"
				className={classes.prevnextBtn}
			>
				Previous
			</Button>
			{pages.map((item) => (
				<React.Fragment key={item}>
					{item === page ? (
						<Button variant="contained" disabled className={cx(classes.pageBtn, classes.currentPageBtn)}>
							{item + 1}
						</Button>
					) : (
						<Button variant="text" onClick={(event) => handlePageNumberButtonClick(event, item)} className={classes.pageBtn}>
							{item + 1}
						</Button>
					)}
				</React.Fragment>
			))}
			<Button
				onClick={handleNextButtonClick}
				disabled={page >= totalPages - 1}
				aria-label="next page"
				endIcon={<ArrowRightIcon />}
				size="small"
				variant="outlined"
				className={classes.prevnextBtn}
			>
				Next
			</Button>
		</Box>
	);
};

const Pagination = (props) => {
	const { classes } = useStyles();

	return (
		<TableFooter>
			<TableRow>
				<TablePagination {...props} ActionsComponent={PaginationActions} rowsPerPageOptions={[]} labelDisplayedRows={() => ''} className={classes.pagination} />
			</TableRow>
		</TableFooter>
	);
};

export default Pagination;
