import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	templates: null,
};

const { actions, reducer } = createSlice({
	name: 'template',
	initialState,
	reducers: {
		getOwnTemplateListRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.templates = null;
		},
		getOwnTemplateListSuccess: (state, { payload }) => {
			state.loading = false;
			state.templates = payload.templates;
		},
		getOwnTemplateListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
		getOwnTemplateListByCompanyIdRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.templates = null;
		},
		getOwnTemplateListByCompanyIdSuccess: (state, { payload }) => {
			state.loading = false;
			state.templates = payload.templates;
		},
		getOwnTemplateListByCompanyIdFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as TemplateActions };

export default reducer;
