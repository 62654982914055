import { SvgIcon } from '@mui/material';
import { UndoIcon } from 'assets/icons';
import ContainedIconButton from '../ContainedIconButton';

const UndoButton = (props) => (
	<ContainedIconButton aria-label="view" {...props}>
		<SvgIcon component={UndoIcon} inheritViewBox size={18} />
	</ContainedIconButton>
);

export default UndoButton;
