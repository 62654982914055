import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'mui-image';
import Loader from 'shared/components/Loader';

import IndividualIcon from 'assets/icons/Individual.svg';
import CompanyIcon from 'assets/icons/Company.svg';
import Logo from 'assets/img/Antosh&Co.svg';

import { useManagement } from 'context/managementContext';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { ROUTE_HOME } from 'constants/routes';

const AccountType = () => {
	const { classes, cx } = useStyles();
	const { user, isLoading: isManagementLoading, hasSelected, setUserCurrentManagement } = useManagement();
	const navigate = useNavigate();

	useEffect(() => {
		if (hasSelected() && !isManagementLoading) navigate(ROUTE_HOME);
	}, [user]);

	return isManagementLoading ? (
		<Loader />
	) : (
		<Box className={classes.root}>
			<Box className={classes.logoContainer}>
				<Image src={Logo} alt="Logo" width={184} fit="contain" />
			</Box>

			<Box className={classes.container}>
				<Box className={cx(classes.item, classes.inDevelopmentContainer)}>
					<Box className={classes.inDevelopmentItem}>
						<Box className={classes.itemIcon}>
							<Image src={IndividualIcon} alt="Individual icon" width={36} height={36} fit="contain" />
						</Box>
						<Typography color="white" variant="h2" className={classes.itemTitle}>
							Individual
						</Typography>
						<Typography variant="subtitle1" className={classes.itemSubtitle}>
							I am an individual looking for a job
						</Typography>
					</Box>
				</Box>

				<Box className={classes.item} onClick={() => setUserCurrentManagement('BUSINESS')}>
					<Box className={classes.itemIcon}>
						<Image src={CompanyIcon} alt="Company Icon" width={36} height={36} fit="contain" />
					</Box>
					<Typography color="white" variant="h2" className={classes.itemTitle}>
						Company
					</Typography>
					<Typography variant="subtitle1" className={classes.itemSubtitle}>
						I am a company looking for employees
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default AccountType;
