import React from 'react';
import { Box, Typography, TextField, TextareaAutosize } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

export default function FormTextField(props) {
	const { name, inputlabel, required = false, autoresize = false, ...rest } = props;
	const [field, { error }] = useField(name);
	const { validationSchema } = useFormikContext();
	const { classes, cx } = useStyles();

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[name];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	return (
		<Box mb={1} className={classes.root}>
			{inputlabel && (
				<Typography variant="body1" color="primary" className={cx(classes.label, 'label')}>
					<>
						{inputlabel}
						{isFieldRequired() && <span className="required">*</span>}
					</>
				</Typography>
			)}
			<TextField
				className={classes.input}
				{...field}
				{...{
					...rest,
					...(rest.multiline &&
						autoresize && {
							InputProps: { ...rest.InputProps, inputComponent: TextareaAutosize },
						}),
				}}
				error={!!error}
				helperText={error}
			/>
		</Box>
	);
}
