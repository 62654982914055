import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useStyles } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// doc: https://github.com/wojtekmaj/react-pdf#readme

const PDF = (props) => {
	const { file } = props;

	const { classes } = useStyles();

	return (
		<div className={classes.root}>
			<Document file={file}>
				<Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} />
			</Document>
		</div>
	);
};

export default PDF;
