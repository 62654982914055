import colors from 'constants/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100%',
			width: '90%',
			maxWidth: 896,
			padding: '90px 0',
			margin: '0 auto',
		},
		logoContainer: {
			margin: '0 auto',
			width: 'fit-content',
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: 'auto 0',
			paddingTop: 15,
			paddingBottom: '7%',
		},
		item: {
			borderRadius: 20,
			border: '2px solid rgba(255, 255, 255, 0.20)',
			textAlign: 'center',
			padding: '46px 36px',
			width: '46.875%',
			cursor: 'pointer',
			transitionProperty: 'background, border',
			transitionDuration: '300ms',
			transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.65, 1)',
			opacity: 1,
			animation: '300ms cubic-bezier(0.75, 0, 0.65, 1) 0s 1 normal none running materialize',
			':hover': {
				border: '2px solid #FFF',
				background: 'rgba(0, 0, 0, 0.20)',
			},
		},
		inDevelopmentContainer: {
			position: 'relative',
			':hover': {
				border: '2px solid rgba(255, 255, 255, 0.20)',
				background: 'transparent',
			},
			cursor: 'initial',
			'&::before': {
				content: '"Coming soon"',
				position: 'absolute',
				top: 0,
				left: 0,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				width:'100%',
				height:'100%',
				textAlign: 'center',
				color: 'white',
				textShadow: '0px 0px 3px #541FF3',
				fontSize: 42,
				transform: "rotate(-20deg)",
				zIndex: 1,
			},
		},
		inDevelopmentItem: {
			filter: 'blur(3px)',
		},
		itemIcon: {
			display: 'block',
			width: 'fit-content',
			height: 'fit-content',
			margin: '0 auto 25px',
		},
		itemTitle: {
			fontSize: 27,
			lineHeight: '3.6rem',
		},
		itemSubtitle: {
			color: colors.tabsTextColor,
			lineHeight: '3.0rem',
		},
	};
});
