import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {},
		label: {
			fontSize: '1.4rem',
			fontWeight: 500,
			lineHeight: '2.4rem',
			letterSpacing: '-0.2px',
			marginBottom: '6px',

			'& span': {
				fontSize: '1.4rem',
				fontWeight: 500,
				lineHeight: '2.4rem',
				letterSpacing: '-0.2px',
			},
		},
		button: {
			minWidth: 320,
			borderRadius: '6px',
			border: '1px solid #EEE9FE',
			color: 'secondary',
			'&.MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
				height: 42,
				justifyContent: 'flex-start',
			},
		},
		browseLink: {
			'& span': {
				color: colors.linkText,
			},
		},
	};
});
