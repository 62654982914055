import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		wrapper: {
			maxWidth: 175
		},
		content: {
			background: 'grey',
			padding: '6px 12px',
			borderRadius: 4,
			color: 'white',
			margin: '10px 0',
			textAlign: "center"
		},
		children: {
			cursor: "pointer"
		}
	};

});
