import React from 'react';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import { makeStyles } from 'tss-react/mui';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      alignItems: 'center',
    },
  };
});

const CustomAlert = React.forwardRef(function Alert(props, ref) {
  const { id, message, variant, action } = props;
  const { classes } = useStyles();

  return (
    <MuiAlert elevation={6} ref={ref} severity={variant} className={classes.root} onClose={() => action(id)}>
      <Typography>{message}</Typography>
    </MuiAlert>
  );
});

const snackbarProvider = (props) => {
  return (
    <SnackbarProviderBase
      {...props}
      Components={{
        success: CustomAlert,
        error: CustomAlert,
        warning: CustomAlert,
        info: CustomAlert,
      }}
    />
  );
};

export default React.memo(snackbarProvider);
