import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	list: [],
};

const { actions, reducer } = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		pushNotification: (state, { payload }) => {
			state.list.push({ ...payload, key: new Date().getTime() + Math.random() });
		},
		removeNotification: (state, { payload }) => {
			state.list = state.list.filter((item) => item.key !== payload);
		},
	},
});

export { actions as NotificationActions };

export default reducer;
