import { SvgIcon } from '@mui/material';
import { FilterListIcon } from 'assets/icons';
import ContainedIconButton from '../ContainedIconButton';

const FilterButton = (props) => (
	<ContainedIconButton aria-label="filter" {...props}>
		<SvgIcon component={FilterListIcon} inheritViewBox size={24} sx={{ fill: '#541FF3' }} />
	</ContainedIconButton>
);

export default FilterButton;
