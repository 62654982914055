import { CustomListsActions } from './reducer';
import {
	GET_LANGUAGES_LIST,
	GET_LANGUAGE_PROFICIENCY_LIST,
	GET_COUNTRY_LIST,
	GET_INDUSTRY_LIST,
	GET_SENIORITY_LIST_BY_INDUSTRY_NAME,
	GET_SKILL_LIST,
	GET_SPECIALISATION_LIST_BY_INDUSTRY_NAME,
} from 'services/api';
import axios from 'axios';
import { getLink } from 'helpers/route';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.industry.setInitial());
	};
}

export function getLanguagesList() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.languages.getLanguagesListRequest());
		try {
			const { data } = await axios.get(GET_LANGUAGES_LIST);

			dispatch(CustomListsActions.languages.getLanguagesListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.languages.getLanguagesListFailure());
		}
	};
}

export function getLanguageProficienciesList() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.languageProficiencies.getLanguageProficienciesListRequest());
		try {
			const { data } = await axios.get(GET_LANGUAGE_PROFICIENCY_LIST);

			dispatch(CustomListsActions.languageProficiencies.getLanguageProficienciesListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.languageProficiencies.getLanguageProficienciesListFailure());
		}
	};
}

export function getSkillList() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.skill.getSkillListRequest());
		try {
			const { data } = await axios.get(GET_SKILL_LIST);

			dispatch(CustomListsActions.skill.getSkillListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.skill.getSkillListFailure());
		}
	};
}

export function getIndustryList() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.industry.getIndustryListRequest());
		try {
			const { data } = await axios.get(GET_INDUSTRY_LIST);

			dispatch(CustomListsActions.industry.getIndustryListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.industry.getIndustryListFailure());
		}
	};
}

export function getSeniorityListByIndustryName(industryName = 'IT') {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.seniority.getSeniorityListRequest());
		try {
			const url = getLink(GET_SENIORITY_LIST_BY_INDUSTRY_NAME, { industryName });

			const { data } = await axios.get(url);

			dispatch(CustomListsActions.seniority.getSeniorityListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.seniority.getSeniorityListFailure());
		}
	};
}

export function getSpecialisationListByIndustryName(industryName = 'IT') {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.specialisation.getSpecialisationListRequest());
		try {
			const url = getLink(GET_SPECIALISATION_LIST_BY_INDUSTRY_NAME, { industryName });

			const { data } = await axios.get(url);

			dispatch(CustomListsActions.specialisation.getSpecialisationListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.specialisation.getSpecialisationListFailure());
		}
	};
}

export function getCountryList() {
	return async function (dispatch, getState) {
		dispatch(CustomListsActions.country.getCountryListRequest());
		try {
			const { data } = await axios.get(GET_COUNTRY_LIST);

			dispatch(CustomListsActions.country.getCountryListSuccess({ data }));
		} catch (e) {
			dispatch(CustomListsActions.country.getCountryListFailure());
		}
	};
}
