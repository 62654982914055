import { createSlice } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';

const initialState = {
	loading: false,
	error: false,
	list: { content: [] },
	uploaded: null,
	deleted: false,
	updated: false,
	details: null,
	view: null,
	created: false,
	not_found: false,
};
const openForJobInitialState = {
	// updated: [],
	error: [],
	loading: [],
};

const resume = {
	origin: createSlice({
		name: 'resume/origin',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.loading = false;
				state.error = false;
				state.list = { content: [] };
				state.uploaded = null;
				state.deleted = false;
				state.updated = false;
				state.details = null;
				state.view = null;
				state.created = false;
				state.not_found = false;
			},

			improveTextRequest: (state) => {
				state.loading = true;
				state.error = false;
			},
			improveTextSuccess: (state, { payload }) => {
				state.loading = false;
				// state.details = null; // @todo: update details field in the state
			},
			improveTextFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			getResumeListByEmployeeRequest: (state) => {
				state.list = initialState.list;
				state.loading = true;
				state.error = false;
			},
			getResumeListByEmployeeSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getResumeListByEmployeeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			createMyResumeRequest: (state, { payload }) => {
				state.created = false;
				state.details = null;
				state.loading = true;
				state.error = false;
			},
			createMyResumeSuccess: (state, { payload }) => {
				state.loading = false;
				state.details = payload.data;
				state.created = true;
				state.improved = [];
			},
			createMyResumeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			createResumeForEmployeeRequest: (state, { payload }) => {
				state.created = false;
				state.details = null;
				state.loading = true;
				state.error = false;
			},
			createResumeForEmployeeSuccess: (state, { payload }) => {
				state.loading = false;
				state.details = payload.data;
				state.created = true;
				state.improved = [];
			},
			createResumeForEmployeeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			getResumeRequest: (state, { payload }) => {
				state.details = null;
				state.loading = true;
				state.error = false;
			},
			getResumeSuccess: (state, { payload }) => {
				state.loading = false;
				state.details = payload.data;
			},
			getResumeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			getEmployeeResumeRequest: (state, { payload }) => {
				state.details = null;
				state.loading = true;
				state.error = false;
			},
			getEmployeeResumeSuccess: (state, { payload }) => {
				state.loading = false;
				state.details = payload.data;
			},
			getEmployeeResumeFailure: (state, { payload: { not_found } }) => {
				state.loading = false;
				state.error = true;
				state.not_found = not_found;
			},

			updateMyResumeRequest: (state, { payload }) => {
				state.updated = false;
				state.loading = true;
				state.error = false;
			},
			updateMyResumeSuccess: (state, { payload }) => {
				state.loading = false;
				state.updated = true;
				state.details = payload.data;
				state.improved = [];
			},
			updateMyResumeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			updateResumeForEmployeeRequest: (state, { payload }) => {
				state.updated = false;
				state.loading = true;
				state.error = false;
			},
			updateResumeForEmployeeSuccess: (state, { payload }) => {
				state.loading = false;
				state.updated = true;
				state.details = payload.data;
				state.improved = [];
			},
			updateResumeForEmployeeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			deleteMyResumeRequest: (state, { payload }) => {
				state.loading = true;
				state.error = false;
				state.deleted = false;
			},
			deleteMyResumeSuccess: (state) => {
				state.loading = false;
				state.deleted = true;
			},
			deleteMyResumeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			deleteEmployeeResumeRequest: (state, { payload }) => {
				state.loading = true;
				state.error = false;
				state.deleted = false;
			},
			deleteEmployeeResumeSuccess: (state) => {
				state.loading = false;
				state.deleted = true;
			},
			deleteEmployeeResumeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			getResumeListRequest: (state) => {
				state.list = initialState.list;
				state.loading = true;
				state.error = false;
			},
			getResumeListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getResumeListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			uploadResumePersonalRequest: (state, { payload }) => {
				state.uploaded = false;
				state.loading = true;
				state.error = false;
			},
			uploadResumePersonalSuccess: (state, { payload }) => {
				state.loading = false;
				state.uploaded = payload.data;
			},
			uploadResumePersonalFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			uploadResumeForEmployeeRequest: (state, { payload }) => {
				state.uploaded = false;
				state.loading = true;
				state.error = false;
			},
			uploadResumeForEmployeeSuccess: (state, { payload }) => {
				state.loading = false;
				state.uploaded = payload.data;
			},
			uploadResumeForEmployeeFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			resumeLongPollingRequest: (state, { payload }) => {
				state.error = false;
			},
			resumeLongPollingSuccess: (state) => {
				state.loading = false;
			},
			resumeLongPollingFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			recallParseForOwnRequest: (state, { payload }) => {
				state.loading = true;
				state.error = false;
			},
			recallParseForOwnSuccess: (state) => {
				state.loading = false;
			},
			recallParseForOwnFailure: (state) => {
				state.loading = false;
				state.error = true;
			},

			updateOpenForJob: (state, { payload }) => {
				state.list.content.forEach((item) => {
					if (item.id === payload.resumeId) {
						item.openForJob = !item.openForJob;
					}
				});
			},
		},
	}),
	openForJob: createSlice({
		name: 'resume/openForJob',
		initialState: openForJobInitialState,
		reducers: {
			setInitial: (state) => {
				// state.updated = [];
				state.loading = [];
				state.error = [];
			},

			updateOpenForJobRequest: (state, { payload }) => {
				state.error = state.error.filter((item) => item !== payload.resumeId);
				state.loading = [...state.loading, payload.resumeId];
			},
			updateOpenForJobSuccess: (state, { payload }) => {
				// state.updated = [...state.updated, { id: payload.data.id, newOpenForJob: payload.data.openForJob }];
				state.loading = state.loading.filter((item) => item !== payload.data.id);
			},
			updateOpenForJobFailure: (state, { payload }) => {
				state.error = [...state.error, payload.resumeId];
				state.loading = state.loading.filter((item) => item !== payload.resumeId);
			},
		},
	}),
};

const actions = combineSliceActions(resume);

export { actions as ResumeActions };

export default combineSliceReducers(resume);
