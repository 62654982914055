import { Box, MenuItem, Select, SvgIcon, TextField, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ArrowDownIcon } from 'assets/icons';

const FormCombinedSelectInput = (props) => {
	const { inputlabel, required = false, options = [], setOptions, selectValueInit, ...otherProps } = props;
	const [open, setOpen] = useState(false);
	const [selectValue, setSelectValue] = useState(selectValueInit || '');
	const [fieldText, { errorText }] = useField(selectValue ? selectValue : 'unknown');
	const { setFieldValue, validationSchema } = useFormikContext();
	const { classes, cx } = useStyles();

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const optionSelected = options.find((item) => item.name === selectValue);

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[fieldText];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	const changeSelectValue = (newField) => {
		const oldValue = fieldText.value;
		setOptions((prev) =>
			prev.map((item) => {
				if (item.name === fieldText.name) {
					return { ...item, used: false };
				} else if (item.name === newField) {
					return { ...item, used: true };
				} else {
					return item;
				}
			}),
		);
		setFieldValue(fieldText.name, '');
		setFieldValue(newField, oldValue);
		setSelectValue(newField);
	};

	useEffect(() => {
		setOptions((prev) =>
			prev.map((item) => {
				if (item.name === selectValueInit) {
					return { ...item, used: true };
				} else {
					return item;
				}
			}),
		);
	}, []);

	return (
		<Box mb={1} className={classes.root}>
			<Typography variant="body1" color="primary" className={classes.label}>
				<>
					{inputlabel}
					{isFieldRequired() && <span>*</span>}
				</>
			</Typography>
			<Box display="flex" className={classes.inputContainer}>
				<Select
					className={classes.select}
					onOpen={handleOpen}
					onClose={handleClose}
					IconComponent={(otherProps) => <ArrowDownIcon {...otherProps} className={open ? cx(classes.icon, classes.iconOpen) : classes.icon} />}
					displayEmpty
					value={selectValue}
					onChange={(e) => changeSelectValue(e.target.value)}
				>
					{options
						.filter((option) => !option.used || option.name === selectValue)
						.map((option) => (
							<MenuItem key={option.name} value={option.name}>
								<SvgIcon component={option.icon} inheritViewBox size={12} sx={{ fill: '#00aff0' }} />
							</MenuItem>
						))}
				</Select>
				<Box className={classes.separator} />
				<TextField
					disabled={!selectValue}
					className={classes.textField}
					{...fieldText}
					{...otherProps}
					error={!!errorText}
					helperText={errorText}
					placeholder={optionSelected?.placeholder ?? otherProps?.placeholder ?? ''}
				/>
			</Box>
		</Box>
	);
};

export default FormCombinedSelectInput;
