import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import colors from 'constants/colors';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		border: 0,
		borderBottom: `1px solid ${colors.tableBorder}`,
	},
}));

export default StyledTableRow;
