import React, { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';

const FormImproveFieldVariants = memo(({ fieldVariants, onSelect, variantIndex, handleRevert }) => {
	const handleVariant = (value, index) => {
		onSelect(value, index);
	};

	return (
		<Box display="flex" gap={1} flexDirection="row">
			{fieldVariants.length > 0 && (
				<Button size="small" color={variantIndex === -1 ? 'primary' : 'secondary'} variant="contained" key="revert" onClick={handleRevert}>
					<Typography variant="inherit" fontSize="12px">
						Original
					</Typography>
				</Button>
			)}
			{fieldVariants.map(
				(option, index) =>
					option && (
						<Button size="small" color={variantIndex === index ? 'primary' : 'secondary'} variant="contained" key={index} onClick={() => handleVariant(option, index)}>
							<Typography variant="inherit" fontSize="12px">
								Variant {index + 1}
							</Typography>
						</Button>
					),
			)}
		</Box>
	);
});

export default FormImproveFieldVariants;
