import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_BUSINESS_COMPANY_ADD } from 'constants/routes';
import { Box, MenuItem, Select, Skeleton, Typography, SvgIcon } from '@mui/material';
import { useSelector } from 'react-redux';
import Avatar from 'shared/components/Avatar';
import { string2sha256 } from 'helpers/format';
import { ArrowDownIcon, PlusCircleIcon } from 'assets/icons';
import { useDispatch } from 'react-redux';
import { loadCompanies, setCurrentCompany } from 'store/reducers/company';
import { useStyles } from './styles';

const SelectCompany = (props) => {
	const { cx, classes } = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState('');
	const [scrollbarWidth, setScrollbarWidth] = useState(0);

	const handleOpen = () => {
		setScrollbarWidth(window.innerWidth - document.documentElement.clientWidth);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const { currentCompany, list, loading } = useSelector((state) => state.company);

	useEffect(() => {
		currentCompany?.name && setSelectedCompany(currentCompany.name);
	}, [currentCompany]);

	useEffect(() => {
		if (list.length === 0) {
			dispatch(loadCompanies());
		}
	}, []);

	const handleChange = (event) => {
		if (event.target.value === 'addnewcompany') return;
		dispatch(setCurrentCompany(list.content.find((company) => company.name === event.target.value)));
	};

	const handleAddNew = () => {
		navigate(ROUTE_BUSINESS_COMPANY_ADD);
	};

	const Company = ({ company }) => (
		<>
			<Box className={classes.logoContainer}>
				<Avatar md5={string2sha256(company.email ?? selectedCompany)} photo={company.logoUrl} alt={selectedCompany} photoIsLoading={company.logoLoading} size={40} />
			</Box>
			<Box className={classes.textWrapper}>
				<Typography variant="subtitle1" color="primary" className={classes.label}>
					{company.name ?? ''}
				</Typography>
				<Typography variant="subtitle3" className={classes.type}>
					Company
				</Typography>
			</Box>
		</>
	);

	if (!list?.content?.length) {
		return <></>;
	}

	if (!currentCompany || loading) {
		return <Skeleton width={175} height={56} variant="rounded" className={classes.root} />;
	}

	return (
		<Select
			value={selectedCompany}
			onChange={handleChange}
			onOpen={handleOpen}
			onClose={handleClose}
			className={classes.root}
			IconComponent={(props) => <ArrowDownIcon {...props} className={cx(classes.icon, props.className, { [classes.iconOpen]: open })} />}
			sx={{ marginRight: open ? `${scrollbarWidth}px` : 0 }}
		>
			{list?.content.map((company) => (
				<MenuItem key={company.id} value={company.name} className={classes.menuItem}>
					<Company company={company} />
				</MenuItem>
			))}
			<MenuItem key="new" value="addnewcompany" className={classes.menuItem} onClick={handleAddNew}>
				<Box className={classes.logoContainer} display="flex" justifyContent="center" alignItems="center">
					<SvgIcon component={PlusCircleIcon} inheritViewBox size={40} />
				</Box>
				<Box className={classes.textWrapper}>
					<Typography variant="subtitle1" color="primary" className={classes.label}>
						Add Company
					</Typography>
				</Box>
			</MenuItem>
		</Select>
	);
};

export default SelectCompany;
