import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
    root: {
      padding: '36px 0 54px'
    },
		content: {
			background: 'white',
			padding: '30px 46px',
			borderRadius: 16,
		},
	};
});
