import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { ROUTE_HOME } from 'constants/routes';

export default function AuthCallback() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.loginCallback().then(() => {
      navigate(ROUTE_HOME);
    });
  });

  return <div>Processing signin...</div>;
}
