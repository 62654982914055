import { useEffect } from 'react';
import { useAuth } from 'context/authContext';

export default function SilentRenew() {
  const auth = useAuth();

  useEffect(() => {
    auth.signinSilentCallback();
  });

  return <div>Processing signin...</div>;
}
