import axios from 'axios';
import { GENERATE_TEMPLATE_URL, GET_OWN_PDF_TEMPLATE_LIST, GET_OWN_PDF_TEMPLATE_LIST_BY_COMPANY_ID } from 'services/api';
import { TemplateActions } from './reducer';

export function getOwnPdfTemplateList() {
	return async function (dispatch, getState) {
		dispatch(TemplateActions.getOwnTemplateListRequest());

		try {
			const { data } = await axios.get(GET_OWN_PDF_TEMPLATE_LIST);
			dispatch(TemplateActions.getOwnTemplateListSuccess({ templates: data }));
		} catch (e) {
			console.log({ e });
			dispatch(TemplateActions.getOwnTemplateListFailure());
		}
	};
}

export function getOwnPdfTemplateListByCompanyId(companyId) {
	return async function (dispatch, getState) {
		dispatch(TemplateActions.getOwnTemplateListByCompanyIdRequest());

		try {
			const { data } = await axios.get(GET_OWN_PDF_TEMPLATE_LIST_BY_COMPANY_ID, { params: { companyId } });
			dispatch(TemplateActions.getOwnTemplateListByCompanyIdSuccess({ templates: data }));
		} catch (e) {
			console.log({ e });
			dispatch(TemplateActions.getOwnTemplateListByCompanyIdFailure());
		}
	};
}

export function generateTemplateDownloadUrl(fileURI) {
	return async function (dispatch, getState) {
		dispatch(TemplateActions.getDownloadTemplateRequest());

		try {
			const { data: fileURL } = await axios.get(GENERATE_TEMPLATE_URL, { params: { filename: fileURI } });

			const uninterceptedAxiosInstance = axios.create();
			const { data } = await uninterceptedAxiosInstance.get(fileURL, {
				responseType: 'blob',
			});

			dispatch(TemplateActions.getDownloadTemplateSuccess({ file: data }));
		} catch (e) {
			console.log({ e });
			dispatch(TemplateActions.getDownloadTemplateFailure());
		}
	};
}
