import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableHead, TableRow, TableBody } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { StyledTableCell } from 'shared/components/Table';
import Empty from 'shared/components/Empty';
import FullscreenWrapper from 'shared/components/FullscreenWrapper';
import DocPreview from 'shared/dialogs/DocPreview';
import { getManyEmployeesResumesFunc, matchVacanciesForResumeIdFunction } from 'services/matching';
import { loadCompanies } from 'store/reducers/company';
import { getEmployees, setInitial as setInitialEmployee } from 'store/reducers/employee';
import MatchedVacancyRow from './MatchedVacancyRow';

const MatchEmployeeResumeList = () => {
	const dispatch = useDispatch();

	const {
		list: { content: companiesList },
		currentCompany,
	} = useSelector((state) => state.company);
	const {
		list: { content: employeeList },
		loading: employeeLoading,
	} = useSelector((state) => state.employee);

	const [matchingLoading, setMatchingLoading] = useState(true);
	const [checkCompanies, setCheckCompanies] = useState(false);

	const [employeesResumes, setEmployeesResumes] = useState([]);
	const [vacancyJson, setVacancyJson] = useState('');
	const [shouldOpenPreview, setShouldOpenPreview] = useState(false);
	const [vacancyPreviewIsOpen, setVacancyPreviewIsOpen] = useState(false);

	useEffect(() => {
		if (companiesList && companiesList.length > 0) return;

		if (checkCompanies) return;

		setCheckCompanies(true);

		dispatch(loadCompanies());
	}, [companiesList]);

	useEffect(() => {
		if (!currentCompany) return;

		dispatch(setInitialEmployee());
		dispatch(getEmployees(currentCompany.id, 0, 60));
	}, [currentCompany]);

	useEffect(() => {
		if (!shouldOpenPreview) return;

		setVacancyPreviewIsOpen(true);
	}, [shouldOpenPreview]);

	useEffect(() => {
		if (employeeList?.length && !employeeLoading) {
			const fetchEmployeeResumes = async () => {
				setEmployeesResumes(
					await getManyEmployeesResumesFunc(
						employeeList.map((emplItem) => emplItem.id),
						0,
						20,
					),
				);
				setMatchingLoading(false);
			};

			fetchEmployeeResumes();
		}
	}, [employeeList, employeeLoading]);

	useEffect(() => {
		if (employeesResumes.length > 0) {
		}
	}, [employeesResumes]);

	const closeVacancyPreview = () => {
		setVacancyPreviewIsOpen(false);
		setShouldOpenPreview(false);
	};

	const handleViewVacancy = (vacancy) => {
		setShouldOpenPreview(true);
		setVacancyJson(JSON.stringify(vacancy, (key, value) => value || '', 4).replace(/"([^"]+)":/g, '$1:'));
	};

	return (
		<>
			<PageTitle title={'Matching All Employees'} />

			<FullscreenWrapper loading={matchingLoading}>
				{employeeList.length === 0 ? (
					<Empty />
				) : (
					<Table aria-label="my resume table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Name & Specialisation</StyledTableCell>
								<StyledTableCell>Seniority</StyledTableCell>
								<StyledTableCell>Main Stack</StyledTableCell>
								<StyledTableCell>Location</StyledTableCell>
								<StyledTableCell>Workload</StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell align="right">Action</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{employeesResumes &&
								employeesResumes.map((emplRes) => {
									return (
										<MatchedVacancyRow
											emplRes={emplRes}
											matchFunc={matchVacanciesForResumeIdFunction}
											setVacancyJson={setVacancyJson}
											handleViewVacancy={handleViewVacancy}
											key={emplRes.id}
										/>
									);
								})}
						</TableBody>
					</Table>
				)}
			</FullscreenWrapper>

			<DocPreview open={vacancyPreviewIsOpen} handleClose={closeVacancyPreview} preview={<pre>{vacancyJson}</pre>} title="Preview vacancy" />
		</>
	);
};

export default MatchEmployeeResumeList;
