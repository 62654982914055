import React, { useState } from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InterfaceProvider } from 'context/interfaceContext';
import { INTERFACE_BUSINESS } from 'constants/interfaces';
import Sidebar from './Sidebar';
import SelectCompany from './Sidebar/SelectCompany';

import { useStyles } from './styles';

const Layout = ({ children, interfaceType }) => {
	const theme = useTheme();
	const { classes } = useStyles();

	const wideScreen = useMediaQuery(theme.breakpoints.up('lg'));

	const [sidebarIsOpen, setSidebarIsOpen] = useState(wideScreen);

	const toggleSidebar = () => setSidebarIsOpen(!sidebarIsOpen);

	return (
		<InterfaceProvider interfaceType={interfaceType}>
			<div className={classes.root}>
				<Sidebar drawerIsOpen={sidebarIsOpen} toggleDrawer={toggleSidebar} />
				<Box component="main" className={classes.main}>
					{interfaceType === INTERFACE_BUSINESS && <SelectCompany name="selectCompany" />}
					<Container maxWidth="xl">{children}</Container>
				</Box>
			</div>
		</InterfaceProvider>
	);
};

export default Layout;
