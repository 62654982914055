import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { EnhancedFormik } from 'context/formikContext';
import { employmentType } from 'constants/other';
import { getIndustryList, getSeniorityListByIndustryName, getSpecialisationListByIndustryName } from 'store/reducers/customLists';
import { Box, Button } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { FormTextField, FormActions, FormDateField, FormSwitchField, FormSelect } from 'shared/components/Form';
import FormAutoCompletedField from 'shared/components/Form/FormAutoCompletedField/FormAutoCompletedField';
import { useStyles } from '../styles';

const EmployeeStep = (props) => {
	const { details, handleNext } = props;

	const dispatch = useDispatch();

	const { list: industryList } = useSelector((state) => state.customLists.industry);
	const { list: seniorityList } = useSelector((state) => state.customLists.seniority);
	const { list: specialisationList } = useSelector((state) => state.customLists.specialisation);

	useEffect(() => {
		dispatch(getIndustryList());
		dispatch(getSeniorityListByIndustryName());
		dispatch(getSpecialisationListByIndustryName());
	}, []);

	const { classes } = useStyles();

	const validationSchema = Yup.object().shape({
		industry: Yup.string().required('Required'),
		specialisation: Yup.string().required('Required'),
		seniority: Yup.string().required('Required'),
		position: Yup.string().nullable(),
		yearsOfExperienceNormalized: Yup.number().required('Required'),
		compensation: Yup.number().nullable(),
		timeZone: Yup.number().nullable(),
		startWorkDate: Yup.date().nullable(),
		remote: Yup.boolean(),
		employmentType: Yup.mixed().oneOf(employmentType).required('Required'),
	});

	const employmentTypeOptions = [' ', ...employmentType].map((item) => ({ label: item.replace('_', ' '), value: item }));

	return (
		<>
			<Box>
				<PageTitle title="Improve your vacancy" subtitle="Lets start with your Employee requirments" />
			</Box>
			<EnhancedFormik validationSchema={validationSchema}>
				<Formik
					initialValues={{
						industry: (details && details.industry) || 'IT',
						specialisation: (details && details.specialisation) || '',
						seniority: (details && details.seniority) || '',
						position: (details && details.position) || '',
						yearsOfExperienceNormalized: (details && details.yearsOfExperienceNormalized) || '',
						compensation: (details && details.compensation) || '',
						timeZone: (details && details.timeZone) || '',
						startWorkDate: (details && details.startWorkDate && new Date(details.startWorkDate)) || null,
						remote: (details && details.remote) || false,
						employmentType: (details && details.employmentType) || employmentType[0],
					}}
					validationSchema={validationSchema}
					onSubmit={handleNext}
					validateOnMount
					enableReinitialize
				>
					{({ values, dirty, isValid, submitForm, isSubmitting }) => (
						<Form>
							<Box mb={4} gap={6} className={classes.gridLine} alignItems="center">
								<FormAutoCompletedField
									fullWidth
									variant="outlined"
									name="industry"
									type="text"
									placeholder="IT"
									inputlabel="Industry"
									onComplete={(elem) => {
										values.industry = elem.name;
									}}
									options={industryList}
									formValue={values}
									makeDirty={() => (dirty = true)}
									valueFromEdit={values.industry}
								/>
								<FormAutoCompletedField
									fullWidth
									variant="outlined"
									name="seniority"
									type="text"
									placeholder="Middle"
									inputlabel="Senior"
									onComplete={(elem) => {
										values.seniority = elem.name;
									}}
									options={seniorityList}
									formValue={values}
									makeDirty={() => (dirty = true)}
									valueFromEdit={values.seniority}
								/>
								<FormAutoCompletedField
									fullWidth
									variant="outlined"
									name="specialisation"
									type="text"
									placeholder="Specialisation"
									inputlabel="Specialisation"
									onComplete={(elem) => {
										values.specialisation = elem.name;
									}}
									options={specialisationList}
									formValue={values}
									makeDirty={() => (dirty = true)}
									valueFromEdit={values.specialisation}
								/>
								<FormTextField fullWidth variant="outlined" name="position" type="text" placeholder="Position" inputlabel="Position" value={values.position} />
								<FormTextField
									fullWidth
									variant="outlined"
									name="yearsOfExperienceNormalized"
									type="number"
									placeholder="2.5"
									inputlabel="Years Of Experience"
									value={values.yearsOfExperienceNormalized}
									inputProps={{ min: 0, max: 70, step: 0.5 }}
								/>
								<FormTextField
									fullWidth
									variant="outlined"
									name="compensation"
									type="number"
									placeholder="15000"
									inputlabel="Compensation (in USD)"
									value={values.compensation}
									inputProps={{ min: 0, max: 2000000, step: 50 }}
								/>
								<FormTextField
									fullWidth
									variant="outlined"
									name="timeZone"
									type="number"
									placeholder="-5"
									inputlabel="TimeZone (+/- from UTC)"
									value={values.timeZone}
									inputProps={{ min: -12, max: 12, step: 0.5 }}
								/>
								<FormDateField fullWidth variant="outlined" name="startWorkDate" inputlabel="Date when you need employee to start working" value={values.startWorkDate} />

								<FormSelect
									name="employmentType"
									value={values.employmentType}
									inputlabel="Employment Type"
									options={employmentTypeOptions}
									variant="outlined"
									fullWidth
								/>

								<Box mt={3}>
									<FormSwitchField name="remote" value={values.remote} inputlabel="Remote" />
								</Box>
							</Box>
							<FormActions display="flex" justifyContent="flex-end" className="bottomActions">
								<Button
									color="primary"
									variant="contained"
									size="large"
									type="submit"
									onClick={(e) => {
										e.preventDefault();
										handleNext(values, dirty);
									}}
									disabled={!isValid || isSubmitting}
								>
									Next
								</Button>
							</FormActions>
						</Form>
					)}
				</Formik>
			</EnhancedFormik>
		</>
	);
};

export default EmployeeStep;
