import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		gridLine: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
		},

		skillsList: {
			borderRadius: 8,
			background: '#F6F6F6',
			padding: '30px 30px 0',
			height: 450,
			overflowY: 'auto',

			'& .MuiListItem-root': {
				borderRadius: 8,
				background: '#FFF',
				marginBottom: 10,
				paddingRight: 96,
			},

			'& .MuiListItemText-primary': {
				color: colors.ListTextColorPurple,
				fontSize: '1.2rem',
				fontWeight: 700,
				lineHeight: '1.6rem',
				textTransform: 'uppercase',
			},
		},
		addSectionsList: {
			'& .MuiSvgIcon-root': {
				color: colors.background,
				fill: 'currentColor',
			},
		},
	};
});
