import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	list: [],
	deleted: false,
	updated: false,
	created: false,
	details: null,
	improvedList: [],
	generated: null,
	not_found: false,
};

const { actions, reducer } = createSlice({
	name: 'coverLetter',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.list = [];
			state.deleted = false;
			state.updated = false;
			state.details = null;
			state.improvedList = initialState.improvedList;
			state.created = false;
			state.not_found = false;
		},

		improveCoverLetterRequest: (state, { payload }) => {
			state.improvedList = initialState.improvedList;
			state.loading = true;
			state.error = false;
		},
		improveCoverLetterSuccess: (state, { payload }) => {
			state.loading = false;
			state.improvedList = payload.data;
		},
		improveCoverLetterFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		createCoverLetterRequest: (state, { payload }) => {
			state.created = false;
			state.loading = true;
			state.error = false;
		},
		createCoverLetterSuccess: (state, { payload }) => {
			state.loading = false;
			state.created = true;
			state.details = payload.data;
		},
		createCoverLetterFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		generateCoverLetterRequest: (state, { payload }) => {
			state.generated = false;
			state.loading = true;
			state.error = false;
		},
		generateCoverLetterSuccess: (state, { payload }) => {
			state.generated = true;
			state.loading = false;
			state.details = payload.data;
		},
		generateCoverLetterFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		updateCoverLettersRequest: (state, { payload }) => {
			state.updated = false;
			state.loading = true;
			state.error = false;
		},
		updateCoverLettersSuccess: (state, { payload }) => {
			state.loading = false;
			state.updated = true;
			// state.details = payload.data;
		},
		updateCoverLettersFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		updateCoverLettersForEmployeeRequest: (state, { payload }) => {
			state.updated = false;
			state.loading = true;
			state.error = false;
		},
		updateCoverLettersForEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.updated = true;
			// state.details = payload.data;
		},
		updateCoverLettersForEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		deleteMyCoverLetterRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.deleted = false;
		},
		deleteMyCoverLetterSuccess: (state) => {
			state.loading = false;
			state.deleted = true;
		},
		deleteMyCoverLetterFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		deleteEmployeeCoverLetterRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.deleted = false;
		},
		deleteEmployeeCoverLetterSuccess: (state) => {
			state.loading = false;
			state.deleted = true;
		},
		deleteEmployeeCoverLetterFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getLetterRequest: (state, { payload }) => {
			state.details = null;
			state.loading = true;
			state.error = false;
			state.not_found = false;
		},
		getLetterSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
		},
		getLetterFailure: (state, { payload: { not_found } }) => {
			state.loading = false;
			state.error = true;
			state.not_found = not_found;
		},

		getLettersListRequest: (state) => {
			state.list = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getLettersListSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		getLettersListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getLettersForEmployeeRequest: (state) => {
			state.list = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getLettersForEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		getLettersForEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as CoverLetterActions };

export default reducer;
