import axios from 'axios';
import {
	GET_COVER_LETTERS,
	UPDATE_MY_RESUME,
	GET_COVER_LETTERS_BY_EMPLOYEE,
	UPDATE_RESUME_FOR_EMPLOYEE,
	CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME,
	CREATE_COVERLETTER_FOR_OWN_RESUME,
	IMPROVE_COVERLETTER_BASED_ON_RESUME,
	CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION,
	CREATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION,
	GENERATE_COVERLETTER_FOR_OWN_RESUME,
	GENERATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION,
	GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME,
	GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION,
	GET_OWN_COVER_LETTER_BY_ID,
	GET_EMPLOYEE_COVER_LETTER_BY_ID,
	DELETE_COVERLETTER_FOR_OWN_RESUME,
	DELETE_COVERLETTER_FOR_EMPLOYEE_RESUME,
} from 'services/api';
import { getLink } from 'helpers/route';
import { NotificationActions } from 'store/reducers/notification';
import { CoverLetterActions } from './reducer';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.setInitial());
	};
}

export function improveCoverLetterForEmployee(coverLetter, resumeDto) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.improveCoverLetterRequest());

		try {
			const { data } = await axios.post(IMPROVE_COVERLETTER_BASED_ON_RESUME, { coverLetter, resumeDto });

			dispatch(CoverLetterActions.improveCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.improveCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function generateCoverLetterForOwn(resumeId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.generateCoverLetterRequest());

		try {
			const { data } = await axios.post(GENERATE_COVERLETTER_FOR_OWN_RESUME, {}, { params: { resumeId } });

			dispatch(CoverLetterActions.generateCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.generateCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function generateCoverLetterForOwnWithDescription(resumeId, description) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.generateCoverLetterRequest());

		try {
			const { data } = await axios.post(GENERATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION, { description }, { params: { resumeId } });

			dispatch(CoverLetterActions.generateCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.generateCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function generateCoverLetterForEmployee(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.generateCoverLetterRequest());

		try {
			const { data } = await axios.post(GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME, {}, { params: { employeeId, resumeId } });

			dispatch(CoverLetterActions.generateCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.generateCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function generateCoverLetterForEmployeeWithDescription(employeeId, resumeId, description) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.generateCoverLetterRequest());

		try {
			const { data } = await axios.post(GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION, { description }, { params: { employeeId, resumeId } });

			dispatch(CoverLetterActions.generateCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.generateCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function createCoverLetterForOwn(resumeId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.createCoverLetterRequest());

		try {
			const { data } = await axios.post(CREATE_COVERLETTER_FOR_OWN_RESUME, {}, { params: { resumeId } });

			dispatch(CoverLetterActions.createCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.createCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

// todo change api url and use description
export function createCoverLetterForOwnWithDescription(resumeId, description) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.createCoverLetterRequest());
		try {
			const { data } = await axios.post(CREATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION, { description }, { params: { resumeId } });

			dispatch(CoverLetterActions.createCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.createCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function createCoverLetterForEmployee(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.createCoverLetterRequest());

		try {
			const { data } = await axios.post(CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME, {}, { params: { employeeId, resumeId } });

			dispatch(CoverLetterActions.createCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.createCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function createCoverLetterForEmployeeWithDescription(employeeId, resumeId, description) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.createCoverLetterRequest());

		try {
			const { data } = await axios.post(CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION, { description }, { params: { employeeId, resumeId } });

			dispatch(CoverLetterActions.createCoverLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.createCoverLetterFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function updateMyCoverLetters(coverLetters) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.updateCoverLettersRequest());

		try {
			const { data } = await axios.put(UPDATE_MY_RESUME, { ...coverLetters });

			dispatch(CoverLetterActions.updateCoverLettersSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.updateCoverLettersFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function updateCoverLetterForEmployee(employeeId, resumeData) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.updateCoverLettersForEmployeeRequest());

		try {
			const { data } = await axios.put(UPDATE_RESUME_FOR_EMPLOYEE, { ...resumeData }, { params: { employeeId } });

			dispatch(CoverLetterActions.updateCoverLettersForEmployeeSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.updateCoverLettersForEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function deleteMyCoverLetter(coverLetterId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.deleteMyCoverLetterRequest());

		try {
			const url = getLink(DELETE_COVERLETTER_FOR_OWN_RESUME, { coverLetterId });

			await axios.delete(url);

			dispatch(CoverLetterActions.deleteMyCoverLetterSuccess());
		} catch (e) {
			dispatch(CoverLetterActions.deleteMyCoverLetterFailure());
			dispatch(
				NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a Cover Letter appeared', options: { variant: 'error' } }),
			);
		}
	};
}

export function deleteEmployeeCoverLetter(coverLetterId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.deleteEmployeeCoverLetterRequest());

		try {
			const url = getLink(DELETE_COVERLETTER_FOR_EMPLOYEE_RESUME, { coverLetterId });

			await axios.delete(url);

			dispatch(CoverLetterActions.deleteEmployeeCoverLetterSuccess());
		} catch (e) {
			dispatch(CoverLetterActions.deleteEmployeeCoverLetterFailure());
			dispatch(
				NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a Cover Letter appeared', options: { variant: 'error' } }),
			);
		}
	};
}

export function getCoverLetters() {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.getLettersListRequest());

		try {
			const params = {
				sort: 'updatedDate,desc',
			};

			const { data } = await axios.get(GET_COVER_LETTERS, { params });

			dispatch(CoverLetterActions.getLettersListSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.getLettersListFailure());
		}
	};
}

export function getCoverLetterForOwnById(coverLetterId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.getLetterRequest());

		try {
			const url = getLink(GET_OWN_COVER_LETTER_BY_ID, { coverLetterId });

			const { data } = await axios.get(url);

			dispatch(CoverLetterActions.getLetterSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.getLetterFailure());
		}
	};
}

export function getCoverLetterForEmployeeById(employeeId, coverLetterId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.getLetterRequest());

		try {
			const url = getLink(GET_EMPLOYEE_COVER_LETTER_BY_ID, { coverLetterId, employeeId });

			const { data } = await axios.get(url);

			dispatch(CoverLetterActions.getLetterSuccess({ data }));
		} catch ({ response }) {
			dispatch(CoverLetterActions.getLetterFailure({ not_found: response?.status === 404 }));
			dispatch(
				NotificationActions.pushNotification({
					message: response?.data?.messages ? response.data.messages[0] : 'An error occurred while retrieving the cover letter.',
					options: { variant: 'error' },
				}),
			);
		}
	};
}

export function getCoverLettersForEmployee(employeeId) {
	return async function (dispatch, getState) {
		dispatch(CoverLetterActions.getLettersForEmployeeRequest());

		try {
			const params = {
				// 	page: 0,
				// 	size: 100,
				sort: 'updatedDate,desc',
			};

			const url = getLink(GET_COVER_LETTERS_BY_EMPLOYEE, { employeeId });

			const { data } = await axios.get(url, { params });

			dispatch(CoverLetterActions.getLettersForEmployeeSuccess({ data }));
		} catch (e) {
			dispatch(CoverLetterActions.getLettersForEmployeeFailure());
		}
	};
}
