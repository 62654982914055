import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& canvas': {
				maxWidth: '100%',
				height: 'auto!important',
			},
			'& .react-pdf__message--error': {
				color: theme.palette.error.main,
			},
		},
	};
});
