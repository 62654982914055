import { generatePath } from 'react-router-dom';

export const getLink = (route, params = {}) => {
  let updatedRoute = route;
  try {
    updatedRoute = generatePath(route, params);
  } catch {
    Object.keys(params).forEach((param) => {
      updatedRoute = updatedRoute.replace(`:${param}`, params[param]);
    });
  }

  return updatedRoute;
};
