import axios from 'axios';
import {
	GET_EMPLOYEES,
	ADD_EMPLOYEE,
	GET_OWN_EMPLOYEE_BY_ID,
	UPDATE_EMPLOYEE,
	UPLOAD_EMPLOYEE_RESUMES,
	LONG_POLLING_EMPLOYEE_RESUME_STAGE,
	RECALL_PARSE_FOR_EMPLOYEE,
	DELETE_EMPLOYEE,
	FILTER_EMPLOYEES,
	GET_EPLOYEE_PHOTO_URL,
	MY_RESUME_GET_LIST_BY_EMPLOYEE,
	UPDATE_RESUME_FOR_EMPLOYEE,
} from 'services/api';
import { getLink } from 'helpers/route';
import { defaultPaginationSize } from 'constants/other';
import { NotificationActions } from 'store/reducers/notification';
import { EmployeeActions } from './reducer';
import { POLLING_FAIL, POLLING_PENDING, POLLING_SUCCESS } from 'constants/stages';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.setInitial());
	};
}

export function uploadEmployeeResumes(companyId, files) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.uploadEmployeeResumesRequest());

		try {
			const form = new FormData();

			files.forEach(function (value) {
				form.append('files', value);
			});

			const { data } = await axios.post(UPLOAD_EMPLOYEE_RESUMES, form, {
				params: { companyId },
				timeout: 0,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			dispatch(EmployeeActions.uploadEmployeeResumesSuccess({ data }));

			// start longpolling by each resume
			data
				.reduce((acc, cur) => {
					return [...acc, ...[{ employeeId: cur.id, resumeIds: [...cur.resumeIds] }]];
				}, [])
				.forEach((item) => {
					item.resumeIds.forEach((resumeId) => {
						dispatch(getEmployeeResumePollingStage(item.employeeId, resumeId));
					});
				});
		} catch (e) {
			console.log({ e });
			dispatch(EmployeeActions.uploadEmployeeResumesFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function updateEmployee(employeeData) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.updateEmployeeRequest());

		try {
			const formData = new FormData();
			const { photo, ...employeeDto } = { ...employeeData };
			const jsonDto = new Blob([JSON.stringify({ ...employeeDto })], { type: 'application/json' });
			formData.append('dto', jsonDto);
			photo && formData.append('file', photo);

			const { data } = await axios.put(UPDATE_EMPLOYEE, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			dispatch(EmployeeActions.updateEmployeeSuccess({ data }));
		} catch (e) {
			console.log({ e });
			dispatch(EmployeeActions.updateEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function getEmployee(employeeId) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.getEmployeeRequest());

		try {
			const url = getLink(GET_OWN_EMPLOYEE_BY_ID, { employeeId });

			const { data } = await axios.get(url);

			dispatch(EmployeeActions.getEmployeeSuccess({ data }));
			if (data.photo?.uri) {
				dispatch(getEmployeePhoto(data.id, data.photo.uri));
			}
			dispatch(getEmployeeOwnResumes(data.id, data.resumeIds));
		} catch ({ response }) {
			dispatch(EmployeeActions.getEmployeeFailure({ not_found: response?.status === 404 }));
			dispatch(
				NotificationActions.pushNotification({
					message: response?.data?.messages ? response.data.messages[0] : 'An error occurred while retrieving the employee info.',
					options: { variant: 'error' },
				}),
			);
		}
	};
}

function getEmployeeOwnResumes(employeeId, resumeIds) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.getEmployeeOwnResumesRequest());

		try {
			const resumes = await Promise.all(
				resumeIds.map(async (resumeId) => {
					try {
						const params = {
							page: 0,
							size: defaultPaginationSize,
							sort: 'updatedDate,desc',
						};

						const url = getLink(MY_RESUME_GET_LIST_BY_EMPLOYEE, { employeeId });

						const { data } = await axios.get(url, { params });

						return data;
					} catch (e) {
						console.log(e, 'error while getting resume for employee');
					}
				}),
			);

			dispatch(EmployeeActions.getEmployeeOwnResumesSuccess({ data: resumes[0].content }));
		} catch (e) {
			console.log(e, 'error while getting resumes for employee');
			dispatch(EmployeeActions.getEmployeeOwnResumesFailure());
			// dispatch(NotificationActions.pushNotification({ message: e.response?.data?.title || 'An Error while getting resumes for employee', options: { variant: 'error' } }));
		}
	};
}

export function addEmployee(companyId, employeeData) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.addEmployeeRequest());

		try {
			const formData = new FormData();
			const { photo, ...employeeDto } = { ...employeeData };
			const jsonDto = new Blob([JSON.stringify({ ...employeeDto })], { type: 'application/json' });
			formData.append('dto', jsonDto);
			photo && formData.append('file', photo);

			const { data } = await axios.post(ADD_EMPLOYEE, formData, {
				params: { companyId },
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			dispatch(EmployeeActions.addEmployeeSuccess({ data }));
		} catch (e) {
			console.log({ e });
			dispatch(EmployeeActions.addEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function getEmployees(companyId, page, paginationSize = 0) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.getEmployeesRequest());

		try {
			const params = {
				page,
				size: paginationSize || defaultPaginationSize,
				sort: 'updatedDate,desc',
			};

			const url = getLink(GET_EMPLOYEES, { companyId });

			const { data } = await axios.get(url, { params });

			dispatch(EmployeeActions.getEmployeesSuccess({ data }));
		} catch (e) {
			dispatch(EmployeeActions.getEmployeesFailure());
		}
	};
}

export function getEmployeeResumePollingStage(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.employeeResumeLongPollingRequest());

		try {
			const url = getLink(LONG_POLLING_EMPLOYEE_RESUME_STAGE, { resumeId });
			const {
				data: { stage },
			} = await axios.get(url, { timeout: 0 });

			switch (stage) {
				case POLLING_PENDING:
					dispatch(getEmployeeResumePollingStage(employeeId, resumeId));
					break;
				case POLLING_SUCCESS:
					dispatch(EmployeeActions.employeeResumeLongPollingSuccess());
					break;
				case POLLING_FAIL:
				default:
					dispatch(recallParseForEmployee(employeeId, resumeId));
					break;
			}
		} catch (e) {
			const { response } = e;

			if (response.status === 408) {
				dispatch(getEmployeeResumePollingStage(employeeId, resumeId));
				return;
			}

			dispatch(EmployeeActions.employeeResumeLongPollingFailure());
		}
	};
}

export function recallParseForEmployee(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.recallParseForEmployeeRequest());

		try {
			await axios.post(RECALL_PARSE_FOR_EMPLOYEE, {}, { params: { employeeId, resumeId } });

			dispatch(EmployeeActions.recallParseForEmployeeSuccess());
		} catch (e) {
			dispatch(EmployeeActions.recallParseForEmployeeFailure());
		}
	};
}

export function deleteEmployee(employeeId) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.deleteEmployeeRequest());

		try {
			const url = getLink(DELETE_EMPLOYEE, { employeeId });

			await axios.delete(url);

			dispatch(EmployeeActions.deleteEmployeeSuccess());
		} catch (e) {
			dispatch(EmployeeActions.deleteEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting an employee appeared', options: { variant: 'error' } }));
		}
	};
}

export function filterEmployees(filter, page, paginationSize = 0) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.filterEmployeeRequest());

		try {
			const postData = {
				pageable: {
					direction: 'desc',
					properties: ['salesRate'],
					page,
					size: paginationSize || defaultPaginationSize,
				},

				...filter,
			};

			const { data } = await axios.post(getLink(FILTER_EMPLOYEES), { ...postData });

			dispatch(EmployeeActions.filterEmployeeSuccess({ data }));

			data.content.forEach((employee) => {
				if (employee.resumeIds) {
					dispatch(getEmployeesOwnResumes(employee.id, employee.resumeIds));
				}
			});
		} catch (e) {
			dispatch(EmployeeActions.filterEmployeeFailure());
		}
	};
}

export function getEmployeePhoto(employeeId, uri) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.getEmployeePhotoRequest());

		try {
			const params = {
				fileS3Uri: uri,
			};
			const { data } = await axios.get(GET_EPLOYEE_PHOTO_URL, { params });
			dispatch(EmployeeActions.getEmployeePhotoSuccess(data));
		} catch (e) {
			console.log(e, 'e');
			dispatch(EmployeeActions.getEmployeePhotoFailure());
		}
	};
}

function getEmployeesOwnResumes(employeeId, resumeIds) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.getEmployeesOwnResumesRequest({ employeeId }));

		try {
			const resumes = await Promise.all(
				resumeIds.map(async (resumeId) => {
					try {
						const params = {
							page: 0,
							size: defaultPaginationSize,
							sort: 'updatedDate,desc',
						};

						const url = getLink(MY_RESUME_GET_LIST_BY_EMPLOYEE, { employeeId });

						const { data } = await axios.get(url, { params });

						return data;
					} catch (e) {
						console.log(e, 'error while getting resume for employee');
					}
				}),
			);

			dispatch(EmployeeActions.getEmployeesOwnResumesSuccess({ employeeId, data: resumes[0].content }));
		} catch (e) {
			console.log(e, 'error while getting resumes for employee');
			dispatch(EmployeeActions.getEmployeesOwnResumesFailure({ employeeId }));
		}
	};
}

export function updateEmployeeResumeOpenForJob(employeeId, resumeId, newOpenForJob) {
	return async function (dispatch, getState) {
		dispatch(EmployeeActions.updateEmployeeResumeOpenForJobRequest({ employeeId, resumeId }));

		try {
			const { data } = await axios.put(UPDATE_RESUME_FOR_EMPLOYEE, { id: resumeId, openForJob: newOpenForJob }, { params: { employeeId } });
			dispatch(EmployeeActions.updateEmployeeResumeOpenForJobSuccess({ employeeId, resumeId, newOpenForJob: data.openForJob }));
		} catch (e) {
			dispatch(EmployeeActions.updateEmployeeResumeOpenForJobFailure({ employeeId, resumeId }));
			dispatch(
				NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while updating openForJob for resume', options: { variant: 'error' } }),
			);
		}
	};
}
