import { styled } from '@mui/material/styles';
import colors from 'constants/colors';
import { Button } from '@mui/material';

const PercentageButton = styled(Button)({
	padding: '2px 8px',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 6,
	border: null,
	minWidth: 0,
	fontFamily: "'Inter', sans-serif",
	fontWeight: 500,
  color: colors.percentageBtnColor,
	background: colors.percentageBtnBg,
});

export default PercentageButton;
