import React from 'react';
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

export default function FormSwitchField(props) {
	const { name, inputlabel, required = false } = props;
	const [field] = useField(name);
	const { validationSchema } = useFormikContext();
	const { classes } = useStyles();

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[name];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	return (
		<Box mb={1} className={classes.root}>
			<FormControlLabel
				value="start"
				control={<Switch checked={field.value === true} {...field} inputProps={{ 'aria-label': 'controlled' }} />}
				label={
					<Typography variant="body1" color="primary" className={classes.label}>
						{inputlabel}
						{isFieldRequired() && <span>*</span>}
					</Typography>
				}
				labelPlacement="start"
				className={classes.control}
			/>
		</Box>
	);
}
