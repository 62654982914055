import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { createVacancy, getVacancy, updateVacancy } from 'store/reducers/vacancy';
import { loadCompanies, setInitial } from 'store/reducers/company';
import { getSkillList } from 'store/reducers/customLists';
import { SkillsStep } from 'shared/components/Steps';
import { ROUTE_BUSINESS_VACANCY_LIST } from 'constants/routes';
import { Typography, Box } from '@mui/material';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import FullscreenWrapper from 'shared/components/FullscreenWrapper';
import { CandidateRequirementsStep, CandidateResponsibilitiesStep, EmployeeStep, ProjectStep, ProjectTechStackStep, RequiredExperienceStep } from './steps';

const initValues = {
	industry: '',
	specialisation: '',
	seniority: '',
	position: '',
	companyName: '',
	yearsOfExperienceNormalized: null,
	compensation: null,
	timeZone: null,
	vacancyExpirationDate: null,
	startWorkDate: null,
	locations: [],
	summary: [],
	projectDescription: [],
	candidateRequiredSkills: [],
	candidateRequirements: [],
	candidateRequiredExperience: [],
	candidateResponsibilities: [],
	projectTechStack: [],
};

const VacancyForm = (props) => {
	const { id: vacancyId } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [activeStep, setActiveStep] = useState(0);
	const [checkCompanies, setCheckCompanies] = useState(false);
	const [shouldGoNext, setShouldGoNext] = useState(false);
	const [createValues, setCreateValues] = useState(initValues);

	const isEditing = Boolean(vacancyId);

	const steps = ['Employee', 'Project Info', 'Skills', 'Project Tech Stack', 'Candidate Required Experience', 'Candidate Requirements', 'Candidate Responsibilities'];

	const {
		list: { content: companiesList },
		loading: companiesLoading,
		currentCompany,
	} = useSelector((state) => state.company);

	const { loading, details, uploaded, created, updated, not_found } = useSelector((state) => state.vacancy);
	const { list: skillList } = useSelector((state) => state.customLists.skill);

	useEffect(() => {
		dispatch(getSkillList());
	}, []);

	useEffect(() => {
		if (companiesList && companiesList.length > 0) return;

		if (checkCompanies) return;

		setCheckCompanies(true);

		dispatch(loadCompanies());
	}, [companiesList]);

	useEffect(() => {
		if (!created) return;

		navigate(ROUTE_BUSINESS_VACANCY_LIST);
	}, [created]);

	useEffect(() => {
		if (!isEditing || !currentCompany?.id) return;

		dispatch(getVacancy(currentCompany.id, vacancyId));
	}, [currentCompany]);

	useEffect(() => {
		if (!uploaded) return;

		navigate(ROUTE_BUSINESS_VACANCY_LIST);
	}, [uploaded]);

	useEffect(() => {
		if (!updated) return;
		if (!shouldGoNext) return;

		setActiveStep(activeStep + 1);
		setShouldGoNext(false);
	}, [updated]);

	useEffect(() => {
		if (!not_found) return;
		dispatch(setInitial());
		navigate(ROUTE_BUSINESS_VACANCY_LIST);
	}, [not_found]);

	const submitEmployeeStep = (values, dirty) => {
		if (isEditing) {
			if (dirty) {
				updateVacancyCall({ ...values, id: vacancyId });
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, ...values });
			setActiveStep(activeStep + 1);
		}
		setShouldGoNext(true);
	};

	const submitProjectStep = (values, dirty) => {
		if (isEditing) {
			if (dirty) {
				const locations = values.locations.map((country) => {
					return { country: { name: country.name } };
				});
				updateVacancyCall({
					id: vacancyId,
					companyName: values.companyName,
					vacancyExpirationDate: values.vacancyExpirationDate,
					locations,
					projectDescription: values.projectDescription,
				});
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, ...values });
			setActiveStep(activeStep + 1);
		}

		setShouldGoNext(true);
	};

	const submitSkillsStep = (skills, dirty) => {
		if (isEditing) {
			if (dirty) {
				const cleanupNewIds = skills.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }));
				updateVacancyCall({ candidateRequiredSkills: cleanupNewIds, id: vacancyId });
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, candidateRequiredSkills: skills });
			setActiveStep(activeStep + 1);
		}
		setShouldGoNext(true);
	};

	const submitProjectTechStackStep = ({ projectTechStack }, dirty) => {
		if (isEditing) {
			if (dirty) {
				updateVacancyCall({ id: vacancyId, projectTechStack: projectTechStack.map((item) => ({ ...item, id: item.id.includes('new-techStack') ? null : item.id })) });
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, projectTechStack });
			setActiveStep(activeStep + 1);
		}
		setShouldGoNext(true);
	};

	const submitRequiredExperienceStep = ({ candidateRequiredExperience }, dirty) => {
		if (isEditing) {
			if (dirty) {
				updateVacancyCall({
					id: vacancyId,
					candidateRequiredExperience: candidateRequiredExperience.map((item) => ({ ...item, id: item.id.includes('new-requiredExperience') ? null : item.id })),
				});
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, candidateRequiredExperience });
			setActiveStep(activeStep + 1);
		}
		setShouldGoNext(true);
	};

	const submitCandidateRequirementsStep = ({ candidateRequirements }, dirty) => {
		if (isEditing) {
			if (dirty) {
				updateVacancyCall({
					id: vacancyId,
					candidateRequirements: candidateRequirements.map((item) => ({ ...item, id: item.id.includes('new-requirement') ? null : item.id })),
				});
			} else {
				setActiveStep(activeStep + 1);
			}
		} else {
			setCreateValues({ ...createValues, candidateRequirements });
			setActiveStep(activeStep + 1);
		}
		setShouldGoNext(true);
	};

	const submitCandidateResponsibilitiesStep = ({ candidateResponsibilities }, dirty) => {
		if (isEditing) {
			if (dirty) {
				updateVacancyCall({
					id: vacancyId,
					candidateResponsibilities: candidateResponsibilities.map((item) => ({ ...item, id: item.id.includes('new-responsibility') ? null : item.id })),
				});
			}
			navigate(ROUTE_BUSINESS_VACANCY_LIST);
			return;
		} else {
			// Create a new vacancy
			const cleanIds = {
				...createValues,
				...(createValues.candidateRequiredExperience.length > 0 && {
					candidateRequiredExperience: [...createValues.candidateRequiredExperience.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }))],
				}),
				...(createValues.candidateRequiredSkills.length > 0 && {
					candidateRequiredSkills: [...createValues.candidateRequiredSkills.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }))],
				}),
				...(createValues.candidateRequirements.length > 0 && {
					candidateRequirements: [...createValues.candidateRequirements.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }))],
				}),
				...(candidateResponsibilities.length > 0 && {
					candidateResponsibilities: [...candidateResponsibilities.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }))],
				}),
				...(createValues.projectTechStack.length > 0 && {
					projectTechStack: [...createValues.projectTechStack.map((item) => ({ ...item, id: item.id.includes('new') ? null : item.id }))],
				}),
				yearsOfExperienceNormalized: isNaN(+createValues.yearsOfExperienceNormalized) ? 0 : +createValues.yearsOfExperienceNormalized,
				locations: createValues.locations.map((item) => ({ id: item.id.includes('new') ? null : item.id, country: { name: item.name } })),
			};

			dispatch(createVacancy(currentCompany.id, cleanIds));
		}
	};

	const updateVacancyCall = (vacancyData) => {
		dispatch(updateVacancy({ ...vacancyData }));
	};

	const handleStep = (step) => {
		setActiveStep(step);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const breadcrumbs = () =>
		steps.map((step, idx) => (
			<Typography
				key={step}
				color="primary"
				variant="subtitle2"
				onClick={isEditing ? () => handleStep(idx) : () => {}}
				{...(isEditing ? { component: Link, href: '#' } : {})}
				sx={{ textDecoration: activeStep === idx ? 'underline!important' : 'none' }}
			>
				{step}
			</Typography>
		));

	return (
		<>
			<Box mb={2}>
				<Typography color="secondary" variant="subtitle1">
					{isEditing ? `Editing vacancy: ${[details?.companyName, details?.seniority, details?.specialisation].filter((item) => item).join(' - ')}` : 'Creating vacancy'}
				</Typography>
			</Box>

			<Breadcrumbs breadcrumbs={breadcrumbs()} />

			<FullscreenWrapper loading={companiesLoading || loading}>
				{activeStep === steps.indexOf('Employee') && <EmployeeStep details={isEditing ? details : createValues} handleNext={submitEmployeeStep} />}
				{activeStep === steps.indexOf('Project Info') && (
					<ProjectStep details={isEditing ? details : createValues} handleNext={submitProjectStep} handleBack={handleBack} />
				)}
				{activeStep === steps.indexOf('Skills') && (
					<SkillsStep
						skills={isEditing && details ? details.candidateRequiredSkills : createValues?.candidateRequiredSkills}
						handleNext={submitSkillsStep}
						handleBack={handleBack}
						skillList={skillList}
						skillKey="requiredSkill"
					/>
				)}

				{activeStep === steps.indexOf('Project Tech Stack') && (
					<ProjectTechStackStep details={isEditing && details ? details : createValues} handleSubmit={submitProjectTechStackStep} handleBack={handleBack} />
				)}

				{activeStep === steps.indexOf('Candidate Required Experience') && (
					<RequiredExperienceStep details={isEditing && details ? details : createValues} handleSubmit={submitRequiredExperienceStep} handleBack={handleBack} />
				)}

				{activeStep === steps.indexOf('Candidate Requirements') && (
					<CandidateRequirementsStep details={isEditing && details ? details : createValues} handleSubmit={submitCandidateRequirementsStep} handleBack={handleBack} />
				)}

				{activeStep === steps.indexOf('Candidate Responsibilities') && (
					<CandidateResponsibilitiesStep
						details={isEditing && details ? details : createValues}
						handleSubmit={submitCandidateResponsibilitiesStep}
						handleBack={handleBack}
					/>
				)}
			</FullscreenWrapper>
		</>
	);
};

export default VacancyForm;
