import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { EnhancedFormik } from 'context/formikContext';
import { getCountryList } from 'store/reducers/customLists';
import { TrashAltIcon } from 'assets/icons';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import { FormTextField, FormActions, FormDateField } from 'shared/components/Form';
import CountryFlag from 'shared/components/CountryFlag';
import FormAutoCompletedField from 'shared/components/Form/FormAutoCompletedField';
import { useStyles } from '../styles';

const ProjectStep = (props) => {
  const { details, handleNext, handleBack } = props;

  const dispatch = useDispatch();

  const { list: countryList } = useSelector((state) => state.customLists.country);

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  const { classes } = useStyles();

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().nullable(),
    vacancyExpirationDate: Yup.date().nullable(),
    locations: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().nullable(),
          name: Yup.string().required(),
        }),
      )
      .min(1),
    projectDescription: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required('Required'),
      }),
    ),
  });

  const projectDescriptionEntity = { description: '' };

  return (
    <>
      <Box>
        <PageTitle title="Let's add project info" subtitle="Enter information about the following fields" />
      </Box>
      <EnhancedFormik validationSchema={validationSchema}>
        <Formik
          initialValues={{
            companyName: (details && details.companyName) || '',
            vacancyExpirationDate: (details && details.vacancyExpirationDate && new Date(details.startWorkDate)) || null,
            locations: (details && details.locations.map(({ country: { id, name } }) => ({ id, name }))) || [{ name: '' }],
            projectDescription: (details && details.projectDescription) || [projectDescriptionEntity],
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
          validateOnMount
          enableReinitialize
        >
          {({ values, dirty, isValid, submitForm, isSubmitting }) => (
            <Form>
              <Box mb={4} gap={6} className={classes.gridLine} alignItems="center">
                <FormTextField
                  fullWidth
                  variant="outlined"
                  name="companyName"
                  type="text"
                  placeholder="companyName"
                  inputlabel="Company Name"
                  value={values.companyName}
                />
                <FormDateField
                  fullWidth
                  variant="outlined"
                  name="Vacancy Expiration Date"
                  inputlabel="Date when your vacancy will dissaper from our website"
                  value={values.vacancyExpirationDate}
                />
                <FormAutoCompletedField
                  fullWidth
                  multiple
                  variant="outlined"
                  name="locations"
                  type="text"
                  placeholder="Locations"
                  inputlabel="Locations"
                  onComplete={(locations) => {
                    values.locations = locations;
                  }}
                  options={countryList}
                  formValue={values}
                  makeDirty={() => (dirty = true)}
                  valueFromEdit={values.locations}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                        <>
                          <CountryFlag country={option.name} />
                          {`${option.name}`}
                        </>
                      </Box>
                    );
                  }}
                />
              </Box>

              <FieldArray
                name="projectDescription"
                render={(arrayHelpers) => (
                  <>
                    {values.projectDescription.map(({ id, description }, index) => (
                      <Box mb={4} gap={6} display="flex" alignItems="center" key={id}>
                        <Box flexGrow={1}>
                          <FormTextField
                            fullWidth
                            variant="outlined"
                            name={`projectDescription.${index}.description`}
                            type="text"
                            inputlabel="Project description"
                            value={description}
                            required
                          />
                        </Box>
                        <Box justifySelf="flex-end">
                          <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                            <SvgIcon component={TrashAltIcon} inheritViewBox size={18} />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}

                    <Box>
                      <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        disabled={isSubmitting}
                        onClick={() => arrayHelpers.push({ ...projectDescriptionEntity, id: `new-description-${arrayHelpers.form.values.projectDescription.length}` })}
                      >
                        Add more
                      </Button>
                    </Box>
                  </>
                )}
              />

              <FormActions display="flex" justifyContent="flex-end" className="bottomActions">
                <Button color="secondary" variant="contained" size="large" onClick={handleBack}>
                  Back
                </Button>
                <div className="spacer" />
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNext(values, dirty);
                  }}
                  disabled={!isValid || isSubmitting}
                >
                  Next
                </Button>
              </FormActions>
            </Form>
          )}
        </Formik>
      </EnhancedFormik>
    </>
  );
};

export default ProjectStep;
