import React, { createContext, useContext } from 'react';
import * as Yup from 'yup';

const FormikContext = createContext({
  validationSchema: Yup.object(),
});

export const useFormikContext = () => useContext(FormikContext);

export const EnhancedFormik = ({ validationSchema, children }) => {
  const contextValue = {
    validationSchema,
  };

  return <FormikContext.Provider value={contextValue}>{children}</FormikContext.Provider>;
};
