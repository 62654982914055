import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Typography } from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useStyles } from '../styles';

const toolbar = {
	options: ['inline', 'colorPicker', 'textAlign', 'list'],
	inline: {
		inDropdown: false,
		options: ['bold', 'italic', 'underline', 'strikethrough'],
	},
};

const FormEditor = (props) => {
	const { disabled } = props;
	const [, { error, initialValue }, helpers] = useField(props);
	const { classes, cx } = useStyles();

	const prepareDraft = (values) => {
		const contentBlock = htmlToDraft(values);
		const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	};

	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		setEditorState(prepareDraft(initialValue));
	}, [initialValue]);

	const handleBlur = (e) => {
		helpers.setTouched(true);
	};

	const handleChange = (editorState) => {
		const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		helpers.setValue(forFormik);
		setEditorState(editorState);
	};

	return (
		<div className={cx(classes.Editor, { readOnly: disabled })}>
			<Editor
				toolbar={toolbar}
				editorState={editorState}
				toolbarClassName="toolbarClassName"
				wrapperClassName="wrapperClassName"
				editorClassName="editorClassName"
				onEditorStateChange={handleChange}
				onBlur={handleBlur}
				readOnly={disabled}
			/>
			{error && <Typography color="error">{error}</Typography>}
		</div>
	);
};

export default FormEditor;
