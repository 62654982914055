import axios from 'axios';
import { isMatchingEnabled, isVacanciesEnabled, isUserManagementEnabled, isTeamManagementEnabled, isFeatureEnabled } from 'helpers/featureFlags';
import { createContext, useContext, useEffect, useState } from 'react';
import { GET_FEATURE_FLAG } from 'services/api';
import { useAuth } from 'context/authContext';
import { featureFlagsList } from 'constants/featureFlags';

export let FeatureFlagContext = createContext({
	featureFlags: [],
	useFeauteFlag: () => {},
	matchingEnabled: false,
	vacanciesEnabled: false,
	userManagementEnabled: false,
	teamManagementEnabled: false,
	billingInfoManagementEnabled: false,
});

function FeatureFlagProvider({ children }) {
	const { isAuthenticated, user } = useAuth();

	let [featureFlags, setFeatureFlags] = useState(JSON.parse(localStorage.getItem('featureFlags')) || []);

	useEffect(() => {
		if (!isAuthenticated()) return;

		getFeatureFlags();
	}, [user]);

	const getFeatureFlags = async () => {
		try {
			const { data } = await axios.get(GET_FEATURE_FLAG);
			const featureFlagsArr = data.filter((item) => item.active).map((item) => item.feature);
			setFeatureFlags(featureFlagsArr);
			localStorage.setItem('featureFlags', JSON.stringify(featureFlagsArr));
		} catch (e) {
			console.log('error while trying to get featureFlags', { e });
		}
	};

	const value = {
		featureFlags,
		useFeatureFlag,
		matchingEnabled: isMatchingEnabled(featureFlags),
		vacanciesEnabled: isVacanciesEnabled(featureFlags),
		userManagementEnabled: isUserManagementEnabled(featureFlags),
		teamManagementEnabled: isTeamManagementEnabled(featureFlags),
		billingInfoManagementEnabled: isFeatureEnabled(featureFlags, featureFlagsList.billingInfoManagement),
	};

	return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}

const useFeatureFlag = () => useContext(FeatureFlagContext);

export { useFeatureFlag, FeatureFlagProvider };
