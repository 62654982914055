import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: 'flex',
			height: '100%',
		},
		main: {
			width: '100%',
			minHeight: 'calc(100% - 40px)',
			height: 'fit-content',
			background: 'white',
			display: 'flex',
			flexDirection: 'column',

			'& > .MuiContainer-root': {
				paddingTop: theme.spacing(3.2),
				paddingRight: theme.spacing(3.3),
				paddingLeft: theme.spacing(4),
			},

			[theme.breakpoints.up('lg')]: {
				'& > .MuiContainer-root': {
					paddingTop: theme.spacing(11),
					paddingRight: theme.spacing(6),
					paddingLeft: theme.spacing(6),
				},
			},
		},
	};
});
