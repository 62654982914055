import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { uploadVacancy } from 'store/reducers/vacancy';
import { loadCompanies } from 'store/reducers/company';
import { ROUTE_BUSINESS_VACANCY_ADD, ROUTE_BUSINESS_VACANCY_LIST } from 'constants/routes';
import { Box, Button, Stack, Typography } from '@mui/material';
import PageTitle from 'shared/components/PageTitle';
import FullscreenWrapper from 'shared/components/FullscreenWrapper';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import { FormEditor } from 'shared/components/Form';

const ParseVacancy = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const activeStep = 0;

	const { loading, uploaded, error } = useSelector((state) => state.vacancy);

	const [checkCompanies, setCheckCompanies] = useState(false);

	const { loading: companiesLoading, currentCompany } = useSelector((state) => state.company);

	useEffect(() => {
		if (!uploaded) return;

		navigate(ROUTE_BUSINESS_VACANCY_LIST);
	}, [uploaded]);

	useEffect(() => {
		if (currentCompany?.id || checkCompanies) return;

		setCheckCompanies(true);

		dispatch(loadCompanies());
	}, [currentCompany]);

	const steps = ['Parse Vacancy', 'Project Info', 'Skills', 'Requirments'];

	const handleConfirm = (vacancyText) => {
		dispatch(uploadVacancy(currentCompany.id, vacancyText));
	};

	const handleNewFromStratch = () => {
		navigate(ROUTE_BUSINESS_VACANCY_ADD);
	};

	const validationSchema = Yup.object().shape({
		vacancyText: Yup.string().required('Required').min(25, 'Too short'),
	});

	const breadcrumbs = () =>
		steps.map((step, idx) => (
			<Typography
				key={step}
				color="primary"
				variant="subtitle2"
				{...(idx === 0 ? { component: Link, href: '#' } : {})}
				sx={{ textDecoration: activeStep === idx ? 'underline!important' : 'none' }}
			>
				{step}
			</Typography>
		));

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs()} />

			<Box>
				<PageTitle title="Paste your vacancy" subtitle="Paste your vacancy in this field and we will extract al needed information from it" />
			</Box>

			<FullscreenWrapper loading={companiesLoading}>
				{!currentCompany?.id ? (
					<Box>
						<Typography variant="h2" color="primary">
							Not found any company
						</Typography>
						<Typography variant="body1" color="secondary">
							Please create your company first.
						</Typography>
					</Box>
				) : (
					<Box>
						<Formik
							initialValues={{
								vacancyText: '<p></p>',
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => handleConfirm(values.vacancyText)}
							validateOnMount
							enableReinitialize
						>
							{({ values, dirty, isValid, submitForm, isSubmitting }) => (
								<Form>
									<FormEditor name="vacancyText" type="text" value={values.vacancyText} required />
									{error && (
										<Typography mt={2} color="error">
											{error || 'Something went wrong please try again'}
										</Typography>
									)}
									<Box className="bottomActions">
										<Stack direction="row" spacing={2} justifyContent="flex-end">
											<Button color="secondary" variant="contained" size="large" onClick={handleNewFromStratch}>
												Create from scratch
											</Button>
											<Button color="primary" variant="contained" size="large" onClick={() => handleConfirm(values.vacancyText)} disabled={!isValid || loading}>
												Confirm
											</Button>
										</Stack>
									</Box>
								</Form>
							)}
						</Formik>
					</Box>
				)}
			</FullscreenWrapper>
		</>
	);
};

export default ParseVacancy;
