import React from 'react';
import { Box, Typography } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

export default function FormDateField(props) {
	const { name, inputlabel, required = false } = props;
	const [field, { error }, { setValue }] = useField(name);
	const { validationSchema } = useFormikContext();
	const { classes } = useStyles();

	const isFieldRequired = () => {
		try {
			return validationSchema.describe().fields[name].some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	return (
		<Box mb={1} className={classes.root}>
			<Typography variant="body1" color="primary" className={classes.label}>
				<>
					{inputlabel}
					{isFieldRequired() && <span>*</span>}
				</>
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DemoContainer components={['DatePicker']}>
					<DatePicker
						className={classes.input}
						{...{
							...field,
							onChange: (date) => {
								setValue(date);
							},
						}}
						{...props}
						error={!!error}
						helperText={error}
					/>
				</DemoContainer>
			</LocalizationProvider>
		</Box>
	);
}
