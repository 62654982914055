import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Autocomplete } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

export default function FormAutoCompletedField(props) {
	const {
		name,
		placeholder,
		inputlabel,
		valueFromEdit,
		getOptionDisabled = () => false,
		getOptionLabel = (option) => option?.name || '',
		isOptionEqualToValue = (option, value) => option.id === value.id || value === '',
		onComplete = () => {},
		multiple = false,
		options = [],
		makeDirty = () => {},
		required = false,
		fullWidth = false,
		TextInputProps = {},
		...otherProps
	} = props;
	const [field, { error }, helpers] = useField(name);
	const { validationSchema } = useFormikContext();
	const { classes } = useStyles();

	const [autoCompleteValue, setAutoCompleteValue] = useState(multiple ? [] : null);

	useEffect(() => {
		if (!valueFromEdit) return;

		const selectedOption = multiple
			? typeof valueFromEdit === 'string'
				? options.filter((option) => valueFromEdit.includes(option.name))
				: valueFromEdit
			: options.find((option) => option.name === valueFromEdit);

		if (!selectedOption) return;
		if (multiple && !selectedOption.length) return;

		setAutoCompleteValue(selectedOption);
		onComplete(selectedOption);
	}, [valueFromEdit, options]);

	const handleAutocompleteChange = (event, newValue) => {
		if (!newValue) {
			onComplete(multiple ? [] : null);
		} else {
			onComplete(newValue);
		}
		makeDirty();
		setAutoCompleteValue(newValue);

		multiple ? helpers.setValue(newValue) : helpers.setValue(newValue?.name || '');
	};

	const isFieldRequired = () => {
		try {
			return validationSchema.describe().fields[name].tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	return (
		<Box mb={1} className={classes.root}>
			<Typography variant="body1" color="primary" className={classes.label}>
				<>
					{inputlabel}
					{isFieldRequired() && <span>*</span>}
				</>
			</Typography>

			<Autocomplete
				disablePortal
				autoHighlight
				multiple={multiple}
				options={options}
				getOptionDisabled={getOptionDisabled}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={isOptionEqualToValue}
				onChange={handleAutocompleteChange}
				value={autoCompleteValue}
				className={classes.autocomplete}
				renderInput={(params) => (
					<TextField
						name={name}
						{...{ ...params, InputProps: { ...params.InputProps, ...TextInputProps } }}
						{...field}
						placeholder={placeholder}
						error={!!error}
						helperText={error}
						onChange={() => {}}
					/>
				)}
				fullWidth={fullWidth}
				{...otherProps}
			/>
		</Box>
	);
}
