import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableHead, TableRow, TableBody, Typography, Box, Stack } from '@mui/material';
import { setInitial as setInitialResumes, loadMyResume } from 'store/reducers/resume';
import PageTitle from 'shared/components/PageTitle';
import { StyledTableCell, StyledTableRow } from 'shared/components/Table';
import Empty from 'shared/components/Empty';
import FullscreenWrapper from 'shared/components/FullscreenWrapper';
import UnfoldButton from 'shared/components/Buttons/UnfoldButon/UnfoldButton';
import PercentageButton from 'shared/components/Buttons/Percentage/Percentage';
import { useStyles } from '../styles';
import TruncatedText from 'shared/components/Table/TruncatedText';
import DocPreview from 'shared/dialogs/DocPreview';
import { EyeButton } from 'shared/components/Buttons';
import { matchVacanciesForManyResumes } from 'services/matching';
import { joinLocationNames } from 'helpers/format';

const MatchMyResumeList = () => {
	const dispatch = useDispatch();

	const { classes } = useStyles();

	const {
		list: { content: resumeList },
		loading: resumeLoading,
	} = useSelector((state) => state.resume.origin);

	const [matchingLoading, setMatchingLoading] = useState(true);
	const [matchingVacancyList, setMatchingVacancyList] = useState([]);

	const [vacancyJson, setVacancyJson] = useState('');
	const [shouldOpenPreview, setShouldOpenPreview] = useState(false);
	const [vacancyPreviewIsOpen, setVacancyPreviewIsOpen] = useState(false);
	const [unfoldedVacancies, setUnfoldedVacancies] = useState([]);

	useEffect(() => {
		dispatch(setInitialResumes());
		dispatch(loadMyResume(0, 100));
	}, []);

	useEffect(() => {
		if (!resumeList.length || resumeLoading) return;

		matchVacanciesForManyResumes(
			resumeList.map((item) => item.id),
			50,
		)
			.then((data) => {
				setMatchingVacancyList(data);
			})
			.finally(() => {
				setMatchingLoading(false);
			});
	}, [resumeList]);

	useEffect(() => {
		if (!shouldOpenPreview) return;

		setVacancyPreviewIsOpen(true);
	}, [shouldOpenPreview]);

	const handleViewVacancy = (vacancy) => {
		setShouldOpenPreview(true);
		setVacancyJson(JSON.stringify(vacancy, (key, value) => value || '', 4).replace(/"([^"]+)":/g, '$1:'));
	};

	const toggleUnfoldVacancy = (vacancyId) => {
		if (unfoldedVacancies.includes(vacancyId)) {
			setUnfoldedVacancies(unfoldedVacancies.filter((vacancy) => vacancy !== vacancyId));
		} else {
			setUnfoldedVacancies([...unfoldedVacancies, vacancyId]);
		}
	};

	const closeVacancyPreview = () => {
		setVacancyPreviewIsOpen(false);
		setShouldOpenPreview(false);
	};

	return (
		<>
			<PageTitle title={'Matching My Resume List'} />

			<FullscreenWrapper loading={resumeLoading || matchingLoading}>
				{matchingVacancyList.length === 0 ? (
					<Empty />
				) : (
					<Table aria-label="my resume table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Company & Specialisation</StyledTableCell>
								<StyledTableCell>Seniority</StyledTableCell>
								<StyledTableCell>Main Stack</StyledTableCell>
								<StyledTableCell>Location</StyledTableCell>
								<StyledTableCell>Workload</StyledTableCell>
								<StyledTableCell></StyledTableCell>
								<StyledTableCell align="right">Action</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{matchingVacancyList &&
								matchingVacancyList.map(({ vacancy, companyContact }) => (
									<React.Fragment key={vacancy.id}>
										<StyledTableRow>
											<StyledTableCell>
												<Typography variant="body1" color="primary">
													{vacancy.companyName}
												</Typography>
												<Typography variant="body2" color="primary">
													{vacancy.specialisation}
												</Typography>
											</StyledTableCell>
											<StyledTableCell>
												<Typography variant="subtitle1" color="primary">
													{vacancy.seniority}
												</Typography>
											</StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell>
												<Typography variant="subtitle1" color="primary">
													{joinLocationNames(vacancy.locations)}
												</Typography>
											</StyledTableCell>
											<StyledTableCell></StyledTableCell>
											<StyledTableCell align="center">
												<PercentageButton>86%</PercentageButton>
											</StyledTableCell>
											<StyledTableCell align="right">
												<Stack spacing={1} direction="row" justifyContent="flex-end">
													<EyeButton onClick={() => handleViewVacancy(vacancy)} />
													<UnfoldButton onClick={() => toggleUnfoldVacancy(vacancy.id)} isOpened={unfoldedVacancies.includes(vacancy.id)} sx={{ border: 0 }} />
												</Stack>
											</StyledTableCell>
										</StyledTableRow>
										{unfoldedVacancies.includes(vacancy.id) && (
											<StyledTableRow>
												<StyledTableCell colSpan={7}>
													<Box className={classes.openedRow}>
														<Box className={classes.openedRowLeft}>
															<Typography variant="subtitle2" className={classes.greyText}>
																Description
															</Typography>
															<Typography variant="subtitle1" color="secondary">
																<TruncatedText text={vacancy.projectDescription.reduce((acc, currentDescr) => acc + currentDescr.description, '')} maxLength={150} />
															</Typography>
														</Box>
														<Box className={classes.openedRowRight}>
															<Typography variant="subtitle2" className={classes.greyText}>
																Contacts
															</Typography>
															{companyContact?.companyName && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.companyName}
																</Typography>
															)}
															{companyContact?.email && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.email}
																</Typography>
															)}
															{companyContact?.phone && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.phone}
																</Typography>
															)}
															{companyContact?.skype && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.skype}
																</Typography>
															)}
															{companyContact?.telegram && (
																<Typography variant="subtitle1" color="secondary">
																	{companyContact.telegram}
																</Typography>
															)}
														</Box>
													</Box>
												</StyledTableCell>
											</StyledTableRow>
										)}
									</React.Fragment>
								))}
						</TableBody>
					</Table>
				)}
			</FullscreenWrapper>

			<DocPreview open={vacancyPreviewIsOpen} handleClose={closeVacancyPreview} preview={<pre>{vacancyJson}</pre>}  title="Preview vacancy"/>
		</>
	);
};

export default MatchMyResumeList;
