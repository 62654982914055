import React from 'react';
import { useAuth } from 'context/authContext';
import { Drawer, Box, IconButton, SvgIcon } from '@mui/material';
import { Image } from 'mui-image';
import SidebarMenu from './Menu';
import LogoutButton from './LogoutButton';

import Logo from 'assets/img/Antosh&Co.svg';
import SantaHat from 'assets/img/hat-xmas-svgrepo-com.svg';
import { ArrowDownWhiteIcon } from 'assets/icons';
import { useStyles } from './styles';

const Sidebar = ({ drawerIsOpen, toggleDrawer }) => {
	const { classes, cx } = useStyles();

	const { logout: handleLogout } = useAuth();

	const isChristmas = process.env.REACT_APP_MERRY_CHRISTMAS === 'true';

	return (
		<Drawer variant="permanent" open={drawerIsOpen} className={cx(classes.root, { opened: drawerIsOpen, closed: !drawerIsOpen })}>
			<Box display="flex" justifyContent="center">
				{/* <IconButton aria-label="toggle" onClick={toggleDrawer} sx={{ padding: 0 }}>
					<SvgIcon component={MenuIcon} inheritViewBox size={24} />
				</IconButton> */}
				<Box className={classes.logoWrapper} sx={{ opacity: drawerIsOpen ? 1 : 0 }}>
					<Image src={Logo} alt="Logo" width={120} fit="contain" className={classes.logo} />
					{isChristmas && (
						<Box className={classes.santahat}>
							<Image src={SantaHat} alt="MerryCristmas!" width={20} fit="contain" />
						</Box>
					)}
				</Box>
			</Box>

			<Box className={cx(classes.menuWrapper, { closed: !drawerIsOpen })}>
				<SidebarMenu drawerIsOpen={drawerIsOpen} />
			</Box>

			<Box className={cx(classes.bottomActions, { closed: !drawerIsOpen })}>
				{/* {drawerIsOpen && (
					<Box mb={4}>
						<ButtonGroup variant="text" aria-label="text button group" className={classes.interfaceTypes}>
							<Button className={cx({ current: interfaceType === INTERFACE_INDIVIDUAL })} component={RouterLink} to={ROUTE_PERSONAL_MY_RESUME}>
								<Typography>Individual</Typography>
							</Button>
							<Button className={cx({ current: interfaceType === INTERFACE_BUSINESS })} component={RouterLink} to={ROUTE_BUSINESS_COMPANY_LIST}>
								<Typography>Business</Typography>
							</Button>
						</ButtonGroup>
					</Box>
				)}
				<Box>
					{drawerIsOpen ? (
						<Button variant="text" onClick={handleLogout}>
							<Typography>Logout</Typography>
						</Button>
					) : (
						<IconButton aria-label="logout" onClick={handleLogout}>
							<SvgIcon component={ExitIcon} inheritViewBox />
							</IconButton>
							)} 
							</Box> */}
				<Box className={cx(classes.menuWrapper, { closed: !drawerIsOpen })}>
					<LogoutButton drawerIsOpen={drawerIsOpen} handleLogout={handleLogout} />
				</Box>
			</Box>

			<IconButton aria-label="toggle" onClick={toggleDrawer} className={cx(classes.toggleButton, { closed: !drawerIsOpen })}>
				<SvgIcon component={ArrowDownWhiteIcon} inheritViewBox size={20} sx={{ fill: 'red' }} />
			</IconButton>
		</Drawer>
	);
};

export default Sidebar;
