import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		wrapper: {
			marginBottom: 35,
		},

		title: {
			fontWeight: '700',
			wordWrap: 'break-word',

			'& span': {
				color: colors.secondary,
				fontSize: '2.4rem',
				fontWeight: 400,
				lineHeight: '3.5rem',
				paddingLeft: '1em',
			},
		},
		search:{
			margin: '0 25px 0 auto',
		},
		subtitle: {
			marginTop: '7px',
		},
		action: {},
	};
});
