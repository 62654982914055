import axios from 'axios';
import {
	COMPANIES_GET_LIST,
	COMPANY_ADD,
	COMPANY_GET_BY_ID,
	COMPANY_EDIT,
	COMPANY_DELETE,
	GENERATE_COMPANY_LOGO_URL,
	INVITE_USER_TO_COMPANY,
	DETACH_USER_FROM_COMPANY,
	GET_COMPANY_MEMBERS,
	GET_COMPANY_INVITE_LIST,
	COMPANY_ACCEPT_INVITE,
	COMPANY_REJECT_INVITE,
} from 'services/api';
import { getLink } from 'helpers/route';
import { NotificationActions } from 'store/reducers/notification';
import { CompanyActions } from './reducer';

export function setInitial() {
	return function (dispatch) {
		dispatch(CompanyActions.setInitial());
	};
}

export function setCurrentCompany(company) {
	return async function (dispatch, getState) {
		sessionStorage.setItem('currentCompany', JSON.stringify({ company }));
		dispatch(CompanyActions.setCurrentCompany({ company }));
	};
}

export function loadCompanies() {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompaniesRequest());

		try {
			const params = {
				page: 0,
				size: 100,
				sort: 'updatedDate,desc',
			};

			const { data } = await axios.get(COMPANIES_GET_LIST, { params });

			dispatch(CompanyActions.getCompaniesSuccess({ data }));

			data.content.forEach((company) => {
				if (company?.logo?.uri) {
					dispatch(getCompaniesLogo(company.id, company.logo.uri));
				}
			});

			const savedCurrentCompany = JSON.parse(sessionStorage.getItem('currentCompany'));
			if (data.content.length > 0) {
				if (!!savedCurrentCompany) {
					dispatch(CompanyActions.setCurrentCompany(savedCurrentCompany));
				} else {
					dispatch(setCurrentCompany(data.content[0]));
				}
			}
		} catch (e) {
			dispatch(CompanyActions.getCompaniesFailure());
		}
	};
}

function getCompaniesLogo(companyId, uri) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompaniesLogoRequest({ companyId }));

		try {
			const params = {
				fileS3Uri: uri,
			};
			const logoUrl = (await axios.get(GENERATE_COMPANY_LOGO_URL, { params })).data;
			dispatch(CompanyActions.getCompaniesLogoSuccess({ companyId, logoUrl }));
		} catch (e) {
			console.log(e, 'error while getting logo for company');
			dispatch(CompanyActions.getCompaniesLogoError({ companyId }));
		}
	};
}

export function getCompany(companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompanyRequest());

		try {
			const url = getLink(COMPANY_GET_BY_ID, { id: companyId });

			const { data } = await axios.get(url, {});

			dispatch(CompanyActions.getCompanySuccess({ data }));

			if (data?.logo?.uri) {
				dispatch(getCompanyLogo(companyId, data.logo.uri));
			}
			dispatch(getCompanyMembers(companyId));
		} catch ({ response }) {
			dispatch(CompanyActions.getCompanyFailure({ not_found: response?.status === 404 }));
			dispatch(
				NotificationActions.pushNotification({
					message: response?.data?.messages ? response.data.messages[0] : 'An error occurred while retrieving the company info.',
					options: { variant: 'error' },
				}),
			);
		}
	};
}

function getCompanyLogo(companyId, uri) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompanyLogoRequest());

		try {
			const params = {
				fileS3Uri: uri,
			};
			const logoUrl = (await axios.get(GENERATE_COMPANY_LOGO_URL, { params })).data;
			dispatch(CompanyActions.getCompanyLogoSuccess({ logoUrl }));
		} catch (e) {
			console.log(e, 'e');
			dispatch(CompanyActions.getCompanyLogoError());
		}
	};
}

export function addCompany(companyData) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.addCompanyRequest());

		try {
			const formData = new FormData();
			const { logo, ...companyDto } = { ...companyData };
			const jsonDto = new Blob([JSON.stringify({ ...companyDto })], { type: 'application/json' });
			formData.append('dto', jsonDto);
			formData.append('file', logo);

			const { data } = await axios.post(COMPANY_ADD, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			dispatch(CompanyActions.addCompanySuccess({ data }));
		} catch (e) {
			dispatch(CompanyActions.addCompanyFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function editCompany(companyId, companyData) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.editCompanyRequest());

		try {
			const { logo, ...companyDto } = { ...companyData };
			const formData = new FormData();
			const jsonDto = new Blob([JSON.stringify({ ...companyDto, id: companyId })], { type: 'application/json' });
			formData.append('dto', jsonDto);
			formData.append('file', logo);

			const { data } = await axios.put(COMPANY_EDIT, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			dispatch(CompanyActions.editCompanySuccess({ data }));
		} catch (e) {
			dispatch(CompanyActions.editCompanyFailure());
			dispatch(NotificationActions.pushNotification({ message: `${e.response.data.title}`, options: { variant: 'error' } }));
		}
	};
}

export function deleteCompany(companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.deleteCompanyRequest());

		try {
			const url = getLink(COMPANY_DELETE, { id: companyId });

			await axios.delete(url);

			dispatch(CompanyActions.deleteCompanySuccess());
		} catch (e) {
			dispatch(CompanyActions.deleteCompanyFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a company appeared', options: { variant: 'error' } }));
		}
	};
}

export function getCompanyMembers(companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompanyMemebersRequest());

		try {
			const url = getLink(GET_COMPANY_MEMBERS, { companyId });

			const { data } = await axios.get(url, {});

			dispatch(CompanyActions.getCompanyMemebersSuccess({ data }));
		} catch ({ response }) {
			dispatch(CompanyActions.getCompanyMemebersFailure());
		}
	};
}

export function inviteUserToCompany(inviteUserEmail, companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.postInviteUserToCompanyRequest());

		try {
			const { data } = await axios.post(INVITE_USER_TO_COMPANY, null, { params: { inviteUserEmail, companyId } });

			dispatch(CompanyActions.postInviteUserToCompanySuccess({ data }));
			dispatch(NotificationActions.pushNotification({ message: 'The invite has been sent', options: { variant: 'success' } }));
		} catch (e) {
			dispatch(CompanyActions.postInviteUserToCompanyFailure());
		}
	};
}

export function detachUserFromCompany(detachedUserEmail, companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.postDetachUserFromCompanyRequest());

		try {
			const { data } = await axios.post(DETACH_USER_FROM_COMPANY, null, { params: { detachedUserEmail, companyId } });

			dispatch(CompanyActions.postDetachUserFromCompanySuccess({ data }));
		} catch (e) {
			dispatch(CompanyActions.postDetachUserFromCompanyFailure());
		}
	};
}

export function getCompanyInvites(companyId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.getCompanyInviteListRequest());

		try {
			const { data } = await axios.get(GET_COMPANY_INVITE_LIST, {});

			dispatch(CompanyActions.getCompanyInviteListSuccess({ data }));
		} catch ({ response }) {
			dispatch(CompanyActions.getCompanyInviteListFailure());
		}
	};
}

export function acceptCompanyInvite(inviteId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.postAcceptCompanyInviteRequest());

		try {
			const url = getLink(COMPANY_ACCEPT_INVITE, { inviteId });

			const { data } = await axios.post(url);

			dispatch(CompanyActions.postAcceptCompanyInviteSuccess({ data }));
		} catch (e) {
			dispatch(CompanyActions.postAcceptCompanyInviteFailure());
		}
	};
}

export function rejectCompanyInvite(inviteId) {
	return async function (dispatch, getState) {
		dispatch(CompanyActions.postRejectCompanyInviteRequest());

		try {
			const url = getLink(COMPANY_REJECT_INVITE, { inviteId });

			const { data } = await axios.post(url);

			dispatch(CompanyActions.postRejectCompanyInviteSuccess({ data }));
		} catch (e) {
			dispatch(CompanyActions.postRejectCompanyInviteFailure());
		}
	};
}
