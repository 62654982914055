import React from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

const FormSelect = (props) => {
	const { name, inputlabel, required = false, options = [], ...restProps } = props;
	const [field, { error }] = useField(name);
	const { validationSchema } = useFormikContext();
	const { cx, classes } = useStyles();

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[name];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	return (
		<Box mb={1} className={classes.root}>
			<Typography variant="body1" color="primary" className={cx(classes.label, 'label')}>
				<>
					{inputlabel}
					{isFieldRequired() && <span className="required">*</span>}
				</>
			</Typography>
			<Select {...field} {...restProps} name={name} error={!!error}>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default FormSelect;
