import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& .MuiListItemButton-root': {
				borderRadius: 20,
				padding: '10px 0 10px 20px',
				whiteSpace: 'nowrap',
				minHeight: 55,
				marginBottom: theme.spacing(2),
				overflow: 'hidden',

				'&.Mui-selected': {
					backgroundColor: 'rgba(23, 15, 73, 0.20)',
				},

				'& .MuiTypography-root': {
					color: '#FFF',
				},

				'& .MuiListItemIcon-root': {
					minWidth: 34,
				},

				'& .MuiListItemText-root': {
					margin: 0,
					transition: 'all 300ms ease',
				},
			},
		},
	};
});
