import {
	FacebookMessengerIcon,
	MicrosoftTeamsIcon,
	SkypeIcon,
	SlackIcon,
	TelegramIcon,
	ViberIcon,
	WhatsAppIcon,
	AppleIcon,
	FacebookIcon,
	GoogleIcon,
	LinkedinIcon,
} from 'assets/icons';

export const defaultPaginationSize = 10;

export const currencies = ['USD', 'EUR', 'UAH'];

export const messengersList = [
	{
		name: 'telegram',
		placeholder: 'telegram username without @',
		icon: TelegramIcon,
		label: 'Telegram',
		deeplink: 'https://t.me/',
	},
	{
		name: 'skype',
		placeholder: 'skype link',
		icon: SkypeIcon,
		label: 'Skype',
		deeplink: '',
	},
	{
		name: 'whatsApp',
		placeholder: 'whatsApp phone number without +',
		icon: WhatsAppIcon,
		label: 'WhatsApp',
		deeplink: 'https://wa.me/',
	},
	{
		name: 'slack',
		placeholder: 'slack link',
		icon: SlackIcon,
		label: 'Slack',
		deeplink: '',
	},
	{
		name: 'microsoftTeams',
		placeholder: 'microsoftTeams email',
		icon: MicrosoftTeamsIcon,
		label: 'Teams',
		deeplink: 'https://teams.microsoft.com/l/call/0/0?users=',
	},
	{
		name: 'facebookMessenger',
		placeholder: '@facebookMessenger username',
		icon: FacebookMessengerIcon,
		label: 'Facebook Messenger',
		deeplink: 'http://m.me/',
	},
	{
		name: 'viber',
		placeholder: 'Viber phone number without +',
		icon: ViberIcon,
		label: 'Viber',
		deeplink: 'viber://chat?number=',
	},
];

export const fileInputMaxFilesizeMB = 10;

export const authProviders = [
	{ name: 'Google', icon: GoogleIcon },
	{ name: 'Facebook', icon: FacebookIcon },
	{ name: 'Apple', icon: AppleIcon },
	{ name: 'LinkedIn', icon: LinkedinIcon },
];

export const employmentType = ['FULL_TIME', 'PART_TIME'];

export const teamMembersStatuses = ['owner', 'admin', 'member'];
