import axios from 'axios';
import { USER, SET_USER_CURRENT_MANAGEMENT } from './api';
import { getLink } from 'helpers/route';

const ManagementService = {
	getOwnUser: async () => {
		try {
			const { data } = await axios.get(USER);

			return data;
		} catch (e) {
			console.log(e, 'error');
		}
	},
	setUserCurrentManagement: async (management) => {
		try {
			const url = getLink(SET_USER_CURRENT_MANAGEMENT, { management });

			const { data } = await axios.patch(url);

			return data;
		} catch (e) {
			console.log(e, 'error');
		}
	},
};

export default ManagementService;
