import React from 'react';
import { Box, Typography, SvgIcon, IconButton } from '@mui/material';
import { useField } from 'formik';
import { messengersList } from 'constants/other';
import { FormTextField, FormSelect, FormGroupField } from 'shared/components/Form';
import { TrashAltIcon } from 'assets/icons';
import { useStyles } from '../styles';

export default function FormMessengerField(props) {
	const { name, handleRemove } = props;
	const [field, { error }] = useField(name);
	const { classes, cx } = useStyles();

	const messengersSelectOptions = messengersList.map((item) => ({
		value: item.name,
		label: <SvgIcon component={item.icon} inheritViewBox size={12} sx={{ fill: '#00aff0' }} />,
	}));

	const { placeholder = '@address', label = 'Messenger' } = messengersList.find((item) => item.name === field.value['messenger']) || {};

	return (
		<FormGroupField inputlabel={label} className={cx({ error })}>
			<FormSelect name={`${field.name}.messenger`} value={field.value['messenger']} options={messengersSelectOptions} className="messengerSelector" />
			<FormTextField fullWidth variant="outlined" name={`${field.name}.address`} type="text" placeholder={placeholder} value={field.value['address']} required />
			<Box justifySelf="flex-end" className={classes.removeMessengerBtn}>
				<IconButton aria-label="delete" onClick={handleRemove}>
					<SvgIcon component={TrashAltIcon} inheritViewBox size={18} />
				</IconButton>
			</Box>
			<>
				{error && (
					<Box className="messengerError">
						<Typography variant="subtitle2" color="error">
							Set messenger(s)
						</Typography>
					</Box>
				)}
			</>
		</FormGroupField>
	);
}
