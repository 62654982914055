import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	list: { content: [] },
	created: false,
	updated: false,
	deleted: false,
	details: null,
	uploaded: false,
	not_found: false,
};

const { actions, reducer } = createSlice({
	name: 'vacancy',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.list = { content: [] };
			state.created = false;
			state.updated = false;
			state.deleted = false;
			state.details = null;
			state.uploaded = false;
			state.not_found = false;
		},

		createVacancyRequest: (state, { payload }) => {
			state.created = false;
			state.details = null;
			state.loading = true;
			state.error = false;
		},
		createVacancySuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
			state.created = true;
			state.improved = false;
		},
		createVacancyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		updateVacancyRequest: (state, { payload }) => {
			state.updated = false;
			state.loading = true;
			state.error = false;
		},
		updateVacancySuccess: (state, { payload }) => {
			state.loading = false;
			state.updated = true;
			state.details = payload.data;
			state.improved = false;
		},
		updateVacancyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		uploadVacancyRequest: (state) => {
			state.uploaded = false;
			state.loading = true;
			state.error = false;
		},
		uploadVacancySuccess: (state, { payload }) => {
			state.loading = false;
			state.uploaded = payload.data;
		},
		uploadVacancyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getVacancyListRequest: (state) => {
			state.list = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getVacancyListSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		getVacancyListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getVacancyRequest: (state, { payload }) => {
			state.details = null;
			state.loading = true;
			state.error = false;
		},
		getVacancySuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
		},
		getVacancyFailure: (state, { payload: { not_found } }) => {
			state.loading = false;
			state.error = true;
			state.not_found = not_found;
		},

		deleteVacancyRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.deleted = false;
		},
		deleteVacancySuccess: (state) => {
			state.loading = false;
			state.deleted = true;
		},
		deleteVacancyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as VacancyActions };

export default reducer;
