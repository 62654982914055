import axios from 'axios';

const httpService = {
  setDefaultAxios: () => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
    axios.defaults.headers = {
      'Content-Type': 'application/json',
    };
  },
  setupInterceptors: async (token_type = null, access_token = null) => {
    axios.interceptors.request.use((config) => {
      const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT_ID}`));

      if (config.headers) config.headers['Authorization'] = `${oidcStorage?.token_type || token_type} ${oidcStorage?.access_token || access_token}`;

      return config;
    });
  },
};

export default httpService;
