import { SvgIcon } from '@mui/material';
import { EyeIcon } from 'assets/icons';
import OutlinedIconButton from '../OutlinedIconButton';

const EyeButton = (props) => (
	<OutlinedIconButton aria-label="view" {...props}>
		<SvgIcon component={EyeIcon} inheritViewBox size={18} />
	</OutlinedIconButton>
);

export default EyeButton;
