import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	matchingResumeList: { content: [] },
	matchingVacancyList: [],
};

const { actions, reducer } = createSlice({
	name: 'matching',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.matchingResumeList = [];
			state.matchingVacancyList = [];
		},

		getMatchingVacancyListRequest: (state) => {
			state.matchingVacancyList = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getMatchingVacancyListSuccess: (state, { payload }) => {
			state.loading = false;
			state.matchingVacancyList = payload.data;
		},
		getMatchingVacancyListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getMatchingResumeListRequest: (state) => {
			state.matchingResumeList = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getMatchingResumeListSuccess: (state, { payload }) => {
			state.loading = false;
			state.matchingResumeList = payload.data;
		},
		getMatchingResumeListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as MatchingActions };

export default reducer;
