import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	list: [],
	saved: false,
	deleted: false,
	details: null,
	currentCompany: null,
	not_found: false,
	invited: false,
	datached: false,
	invites: [],
	accepted: false,
	rejected: false,
};

const { actions, reducer } = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.saved = false;
			state.details = null;
			state.deleted = false;
			state.not_found = false;
			state.invited = false;
			state.datached = false;
			state.invites = [];
			state.accepted = false;
			state.rejected = false;
		},

		getCompanyLogoRequest: (state) => {
			state.details.logoLoading = true;
		},
		getCompanyLogoSuccess: (state, { payload }) => {
			state.details.logoUrl = payload.logoUrl;
			state.details.logoLoading = false;
		},
		getCompanyLogoError: (state) => {
			state.details.logoLoading = false;
		},

		getCompaniesLogoRequest: (state, { payload }) => {
			state.list.content = state.list.content.map((company) => (company.id === payload.companyId ? { ...company, logoLoading: true } : company));
		},
		getCompaniesLogoSuccess: (state, { payload }) => {
			state.list.content = state.list.content.map((company) =>
				company.id === payload.companyId ? { ...company, logoLoading: false, logoUrl: payload.logoUrl } : company,
			);
		},
		getCompaniesLogoFailure: (state, { payload }) => {
			state.list.content = state.list.content.map((company) => (company.id === payload.companyId ? { ...company, logoLoading: false } : company));
		},

		setCurrentCompany: (state, { payload }) => {
			state.currentCompany = payload.company;
		},
		getCompaniesRequest: (state) => {
			state.loading = true;
			state.error = false;
		},
		getCompaniesSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		getCompaniesFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getCompanyRequest: (state) => {
			state.loading = true;
			state.error = false;
		},
		getCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
		},
		getCompanyFailure: (state, { payload: { not_found } }) => {
			state.loading = false;
			state.error = true;
			state.not_found = not_found;
		},

		addCompanyRequest: (state, { payload }) => {
			state.loading = true;
			state.saved = false;
			state.error = false;
		},
		addCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.saved = true;
		},
		addCompanyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		editCompanyRequest: (state, { payload }) => {
			state.loading = true;
			state.saved = false;
			state.error = false;
		},
		editCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.saved = true;
		},
		editCompanyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		deleteCompanyRequest: (state, { payload }) => {
			state.loading = true;
			state.deleted = false;
			state.error = false;
		},
		deleteCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.deleted = true;
		},
		deleteCompanyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getCompanyMemebersRequest: (state) => {
			state.loading = true;
			state.error = false;
		},
		getCompanyMemebersSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = { ...state.details, members: payload.data };
		},
		getCompanyMemebersFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		postInviteUserToCompanyRequest: (state, { payload }) => {
			state.loading = true;
			state.invited = false;
			state.error = false;
		},
		postInviteUserToCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.invited = true;
		},
		postInviteUserToCompanyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		postDetachUserFromCompanyRequest: (state, { payload }) => {
			state.loading = true;
			state.datached = false;
			state.error = false;
		},
		postDetachUserFromCompanySuccess: (state, { payload }) => {
			state.loading = false;
			state.datached = true;
		},
		postDetachUserFromCompanyFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getCompanyInviteListRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.invites = [];
		},
		getCompanyInviteListSuccess: (state, { payload }) => {
			state.loading = false;
			state.invites = payload.data;
		},
		getCompanyInviteListFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		postRejectCompanyInviteRequest: (state) => {
			state.loading = true;
			state.rejected = false;
			state.error = false;
		},
		postRejectCompanyInviteSuccess: (state) => {
			state.loading = false;
			state.rejected = true;
		},
		postRejectCompanyInviteFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		postAcceptCompanyInviteRequest: (state) => {
			state.loading = true;
			state.accepted = false;
			state.error = false;
		},
		postAcceptCompanyInviteSuccess: (state) => {
			state.loading = false;
			state.accepted = true;
		},
		postAcceptCompanyInviteFailure: (state) => {
			state.loading = false;
			state.error = true;
		},
	},
});

export { actions as CompanyActions };

export default reducer;
