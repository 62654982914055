import React, { createContext, useContext, useState } from 'react';
import AuthService from 'services/auth';
import httpService from 'services/http';

let AuthContext = createContext({
	isAuthenticated: () => ({}),
	loginCallback: () => ({}),
	logout: () => ({}),
	login: () => ({}),
	signinSilentCallback: () => ({}),
	createSigninRequest: () => ({}),
	user: null,
	getChangePasswordLink: () => ({}),
});

const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }) {
	let [user, setUser] = useState(
		JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT_ID}`) || 'null') || undefined,
	);

	const authService = new AuthService();

	const loginCallback = async () => {
		try {
			const authedUser = await authService.loginCallback();

			await httpService.setupInterceptors(authedUser.token_type, authedUser.access_token);

			setUser(authedUser);

			authService.postLoginActions();
		} catch (e) {
			console.log('signinRedirectCallback error', { e });
		}
	};

	const login = () => authService.login();
	const logout = () => authService.logout();
	const isAuthenticated = () => authService.isAuthenticated();
	const signinSilentCallback = () => authService.signinSilentCallback();
	const createSigninRequest = () => authService.createSigninRequest();
	const getChangePasswordLink = () => authService.getChangePasswordLink();

	const value = { isAuthenticated, loginCallback, logout, user, login, signinSilentCallback, createSigninRequest, getChangePasswordLink };

	if (user) {
		httpService.setupInterceptors(user.token_type, user.access_token);
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { useAuth, AuthProvider };
