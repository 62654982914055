import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme, open) => {
	const drawerWidth = 280;

	return {
		root: {
			width: drawerWidth,
			position: 'relative',
			transition: 'width 0.3s cubic-bezier(0.3, 0, 0.7, 1)',

			'&.closed': {
				width: 104,

				'& .MuiDrawer-paper': {
					width: 44,
					paddingRight: 20,
				},
			},

			'& .MuiDrawer-paper': {
				transition: 'width 0.3s cubic-bezier(0.3, 0, 0.7, 1), padding 0.3s cubic-bezier(0.3, 0, 0.7, 1)',
				overflow: 'visible',
				background: colors.background,
				border: 0,
				padding: '62px 40px 30px',
				width: 200,
				boxSizing: 'content-box',
				height: 'calc(100% - 92px)',
			},

			'& .MuiTypography-root': {
				color: 'white',
				fontSize: '1.6rem',
				fontWeight: '700',
				lineHeight: '3.5rem',
				wordWrap: 'break-word',
				textTransform: 'capitalize',
			},
		},
		logoWrapper: {
			marginLeft: '10px',
			transition: 'all 0.3s cubic-bezier(0.3, 0, 0.7, 1)',
			position: 'relative',
		},
		logo: {
			width: 120,
		},
		santahat: {
			position: 'absolute',
			top: -4,
			left: 38,
		},
		bottomActions: {
			width: '100%',
			flexGrow: 1,
			display: 'flex',
			alignItems: 'flex-end',
			overflow: 'hidden',

			'&.closed': {
				width: 104 - 60,
			},
		},
		interfaceTypes: {
			'& .MuiButtonBase-root': {
				'&.current': {
					'& .MuiTypography-root': {
						fontWeight: 700,
						textDecoration: 'underline',
					},
				},
			},
			'& .MuiTypography-root': {
				fontWeight: 400,
			},
		},
		menuWrapper: {
			marginTop: 94,
			transition: 'all 0.3s cubic-bezier(0.3, 0, 0.7, 1)',
			'&.closed': {
				marginLeft: '-20px',
			},
		},

		toggleButton: {
			position: 'absolute',
			right: '-18px',
			top: 'calc(50% - 18px)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '36px',
			height: '36px',
			background: colors.secondaryBtnColor,
			zIndex: 12,
			transform: 'rotate(90deg)',

			'&.closed': {
				rotate: '180deg',
			},

			'&:hover': {
				background: '#481CD1',
			},
		},
	};
});
