import { UserManager } from 'oidc-client-ts';
import { ROUTE_AUTH_CALLBACK, ROUTE_AUTH_SILENTRENEW } from 'constants/routes';
import { generatePKCE } from 'helpers/format';
import { sendMetricOnLogin } from './metrics';

export default class AuthService {
	constructor() {
		const settings = {
			authority: process.env.REACT_APP_AUTH_URL,
			client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
			client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
			redirect_uri: window && `${window.location.origin}${ROUTE_AUTH_CALLBACK}`,

			response_type: 'code',
			scope: 'openid profile email offline_access',

			loadUserInfo: false,

			automaticSilentRenew: true, // false,
			accessTokenExpiringNotificationTime: 60,
			checkSessionIntervalInSeconds: 100,

			silent_redirect_uri: window && `${window.location.origin}${ROUTE_AUTH_SILENTRENEW}`,

			// monitorSession: true,

			metadata: {
				authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/protocol/openid-connect/auth',
				token_endpoint: process.env.REACT_APP_AUTH_URL + '/protocol/openid-connect/token',
				end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/protocol/openid-connect/logout',
				introspection_endpoint: process.env.REACT_APP_AUTH_URL + '/protocol/openid-connect/token/introspect',
				userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/protocol/openid-connect/userinfo',
			},
		};

		this.userManager = new UserManager(settings);

		this.userManager.events.addSilentRenewError((e) => {
			console.log('silent renew error', e.message);
		});

		this.userManager.events.addAccessTokenExpired(() => {
			this.signinSilent();
		});

		// event fired every time session is changed
		// this.userManager.events.addUserLoaded(() => {
		// });
	}

	getUser() {
		return this.userManager.getUser();
	}

	login() {
		return this.userManager.signinRedirect();
	}

	loginCallback() {
		return this.userManager.signinRedirectCallback();
	}

	logout() {
		this.userManager.signoutRedirect({ post_logout_redirect_uri: window.location.origin });
		this.userManager.clearStaleState();
		return;
	}

	isAuthenticated = () => {
		const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT_ID}`));

		return !!oidcStorage && !!oidcStorage.access_token;
	};

	signinSilent = () => {
		this.userManager
			.signinSilent()
			.then((user) => {
				console.log('signed in');
			})
			.catch((err) => {
				// alert('Your session has expiered! You may need to relogin');
				this.logout();
				console.log({ err });
			});
	};
	signinSilentCallback = () => {
		this.userManager.signinSilentCallback();
	};

	createSigninRequest = () => {
		return this.userManager.createSigninRequest();
	};

	postLoginActions = (user) => {
		// clean session storage
		sessionStorage.removeItem('currentCompany');

		sendMetricOnLogin();
	};

	getChangePasswordLink = async () => {
		const { codeChallenge } = await generatePKCE();
		return `${process.env.REACT_APP_AUTH_URL}/protocol/openid-connect/auth?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${window.location.origin}&kc_action=UPDATE_PASSWORD&scope=openid profile email offline_access&code_challenge_method=S256&code_challenge=${codeChallenge}`.replace(
			/ /g,
			'%20',
		);
	};
}
