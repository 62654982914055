import axios from 'axios';
import { MatchingActions } from './reducer';
import { MATCH_RESUMES_FOR_VACANCY, MATCH_VACANCIES_FOR_RESUME } from 'services/api';
import { getLink } from 'helpers/route';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(MatchingActions.setInitial());
	};
}

export function matchVacanciesForResumeId(resumeId, topK, useCountry = true) {
	return async function (dispatch, getState) {
		dispatch(MatchingActions.getMatchingVacancyListRequest);

		try {
			const params = {
				useCountry,
			};

			const url = getLink(MATCH_VACANCIES_FOR_RESUME, { resumeId, topK });

			const { data } = await axios.get(url, { params });

			dispatch(MatchingActions.getMatchingVacancyListSuccess({ data }));
		} catch (e) {
			dispatch(MatchingActions.getMatchingVacancyListFailure());
		}
	};
}

export function matchResumesForVacancyId(vacancyId, topK, useCountry = true) {
	return async function (dispatch, getState) {
		dispatch(MatchingActions.getMatchingResumeListRequest);

		try {
			const params = {
				useCountry,
			};

			const url = getLink(MATCH_RESUMES_FOR_VACANCY, { vacancyId, topK });

			const { data } = await axios.get(url, { params });

			dispatch(MatchingActions.getMatchingResumeListSuccess({ data }));
		} catch (e) {
			dispatch(MatchingActions.getMatchingResumeListFailure());
		}
	};
}
