import React from 'react';
import { Box, Typography } from '@mui/material';

const Empty = () => {
	return (
		<Box>
			<Typography variant="h2" color="primary">
				Empty
			</Typography>
			<Typography variant="body1" color="secondary">
				No records found. You have the option to create a new one.
			</Typography>
		</Box>
	);
};

export default Empty;
