import { createSlice } from '@reduxjs/toolkit';
import { combineSliceActions, combineSliceReducers } from 'store/utils';

const initialState = {
	list: [],
	error: false,
	loading: false,
};

const customLists = {
	skill: createSlice({
		name: 'customLists/skill',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getSkillListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getSkillListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getSkillListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	industry: createSlice({
		name: 'customLists/industry',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getIndustryListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getIndustryListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getIndustryListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	seniority: createSlice({
		name: 'customLists/seniority',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getSeniorityListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getSeniorityListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getSeniorityListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	specialisation: createSlice({
		name: 'customLists/specialisation',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getSpecialisationListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getSpecialisationListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getSpecialisationListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	country: createSlice({
		name: 'customLists/country',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getCountryListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getCountryListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getCountryListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	languages: createSlice({
		name: 'customLists/languages',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getLanguagesListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getLanguagesListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getLanguagesListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
	languageProficiencies: createSlice({
		name: 'customLists/languageProficiencies',
		initialState,
		reducers: {
			setInitial: (state) => {
				state.list = [];
				state.error = false;
				state.loading = false;
			},
			getLanguageProficienciesListRequest: (state) => {
				state.list = [];
				state.loading = true;
				state.error = false;
			},
			getLanguageProficienciesListSuccess: (state, { payload }) => {
				state.loading = false;
				state.list = payload.data;
			},
			getLanguageProficienciesListFailure: (state) => {
				state.loading = false;
				state.error = true;
			},
		},
	}),
};

const actions = combineSliceActions(customLists);

export { actions as CustomListsActions };

export default combineSliceReducers(customLists);
