import React from 'react';
import Gravatar from 'react-gravatar';
import { Avatar as MuiAvatar, Skeleton } from '@mui/material';

const Avatar = (props) => {
	const { default: gravatarDefault = 'retro', md5, photo, alt = '', photoIsLoading, size = 48 } = props;

	if (photoIsLoading) {
		return <Skeleton variant="circular" width={size} height={size} />;
	}

	if (photo) {
		return <MuiAvatar alt={alt} src={photo} sx={{ width: size, height: size }} />;
	}

	return <Gravatar email={alt} md5={md5} default={gravatarDefault} style={{ borderRadius: '50%', width: `${size}px`, height: `${size}px`, objectFit: 'cover' }} />;
};

export default Avatar;
