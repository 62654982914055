import React from 'react';
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { NavigateNextIcon } from 'assets/icons';
import { useStyles } from './styles';

const Breadcrumbs = (props) => {
	const { breadcrumbs } = props;
	const { classes } = useStyles();

	return (
		<Box mb={4.4} className={classes.root}>
			<MuiBreadcrumbs separator={<SvgIcon component={NavigateNextIcon} inheritViewBox size={16} sx={{ fill: '#6F6C90' }} />} aria-label="breadcrumb">
				{breadcrumbs}
			</MuiBreadcrumbs>
		</Box>
	);
};

export default Breadcrumbs;
