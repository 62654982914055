import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

const StyledBox = styled(Box)(({ offsettop }) => ({
	minHeight: `calc(100vh - ${offsettop}px)`,
	position: 'relative',
	paddingBottom: 90,

	'&.loading': {
		'&:before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			zIndex: 1,
			filter: 'opacity(50%)',
			background: '#FFF',
		},
	},
	'& .bottomActions': {
		position: 'absolute',
		right: 0,
		bottom: 20,
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		'& .MuiStack-root': {
			width: '100%',
		},
		'& .spacer': {
			flexGrow: 1,
		},
	},
}));

const FullscreenWrapper = ({ children, loading, ...rest }) => {
	const wrapperRef = useRef(null);

	const [offsettop, setOffsettop] = useState(0);

	useEffect(() => {
		if (wrapperRef && wrapperRef.current) {
			const { top } = wrapperRef.current.getBoundingClientRect();
			setOffsettop(top);
		}
		window.scrollTo(0, 0);
	}, []);

	return (
		<StyledBox component="section" {...rest} className={loading ? 'loading' : ''} ref={wrapperRef} offsettop={offsettop}>
			<>
				{loading && <LinearProgress sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }} />}
				<Box sx={{ paddingTop: '4px' }}>{children}</Box>
			</>
		</StyledBox>
	);
};

export default FullscreenWrapper;
