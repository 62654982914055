import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { companyReducer } from './reducers/company';
import { ResumeReducer } from './reducers/resume';
import { VacancyReducer } from './reducers/vacancy';
import { PDFReducer } from './reducers/pdf';
import { CoverLetterReducer } from './reducers/coverLetter';
import { EmployeeReducer } from './reducers/employee';
import { NotificationReducer } from './reducers/notification';
import { MatchingReducer } from './reducers/matching';
import { CustomListsReducer } from './reducers/customLists';
import { TemplateReducer } from './reducers/template';
import { UserReducer } from './reducers/user';

const rootReducer = combineReducers({
	company: companyReducer,
	vacancy: VacancyReducer,
	resume: ResumeReducer,
	pdf: PDFReducer,
	template: TemplateReducer,
	matching: MatchingReducer,
	coverLetter: CoverLetterReducer,
	employee: EmployeeReducer,
	customLists: CustomListsReducer,
	notification: NotificationReducer,
	user: UserReducer,
});

export default function configureStore(preloadedState) {
	const middlewares = [thunkMiddleware];
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevTools(...enhancers);

	const store = createStore(rootReducer, preloadedState, composedEnhancers);

	return store;
}
