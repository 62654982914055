import axios from 'axios';
import * as endpoints from 'services/api';
import { getLink } from 'helpers/route';
import { defaultPaginationSize } from 'constants/other';
import { NotificationActions } from 'store/reducers/notification';
import { ResumeActions } from './reducer';
import { POLLING_FAIL, POLLING_PENDING, POLLING_SUCCESS } from 'constants/stages';
import { getEmployeeResumePollingStage } from '../employee';

export function setInitial() {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.setInitial());
	};
}

export function improveText(text) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.improveTextRequest());

		try {
			const { data } = await axios.post(endpoints.IMPROVE_RESUME_TEXT, { text });

			dispatch(ResumeActions.origin.improveTextSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.improveTextFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'Error improving text', options: { variant: 'error' } }));
		}
	};
}

export function getEmployeeResumes(employeeId, page = 0, paginationSize = 0) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.getResumeListByEmployeeRequest());

		try {
			const params = {
				page,
				size: paginationSize || defaultPaginationSize,
				sort: 'updatedDate,desc',
			};

			const url = getLink(endpoints.MY_RESUME_GET_LIST_BY_EMPLOYEE, { employeeId });

			const { data } = await axios.get(url, { params });

			dispatch(ResumeActions.origin.getResumeListByEmployeeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.getResumeListByEmployeeFailure());
		}
	};
}

export function createMyResume(resumeData) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.createMyResumeRequest());

		try {
			const { data } = await axios.post(endpoints.CREATE_MY_NEW_RESUME, { ...resumeData });

			dispatch(ResumeActions.origin.createMyResumeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.createMyResumeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function createResumeForEmployee(employeeId, resumeData) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.createResumeForEmployeeRequest());

		try {
			const { data } = await axios.post(endpoints.CREATE_RESUME_FOR_EMPLOYEE, { ...resumeData }, { params: { employeeId } });

			dispatch(ResumeActions.origin.createResumeForEmployeeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.createResumeForEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function getResume(resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.getResumeRequest());

		try {
			const url = getLink(endpoints.MY_RESUME_GET_BY_ID, { resumeId });

			const { data } = await axios.get(url);

			dispatch(ResumeActions.origin.getResumeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.getResumeFailure());
		}
	};
}

export function getEmployeeResumeById(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.getEmployeeResumeRequest());

		try {
			const url = getLink(endpoints.GET_EMPLOYEE_RESUME, { resumeId, employeeId });

			const { data } = await axios.get(url);

			dispatch(ResumeActions.origin.getEmployeeResumeSuccess({ data }));
		} catch ({ response }) {
			dispatch(ResumeActions.origin.getEmployeeResumeFailure({ not_found: response.status === 404 }));
			dispatch(
				NotificationActions.pushNotification({ message: response.data.messages[0] || 'An error occurred while retrieving the resume.', options: { variant: 'error' } }),
			);
		}
	};
}

export function updateMyResume(resumeData) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.updateMyResumeRequest());

		try {
			const { data } = await axios.put(endpoints.UPDATE_MY_RESUME, { ...resumeData });

			dispatch(ResumeActions.origin.updateMyResumeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.updateMyResumeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function updateResumeForEmployee(employeeId, resumeData) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.updateResumeForEmployeeRequest());

		try {
			const { data } = await axios.put(endpoints.UPDATE_RESUME_FOR_EMPLOYEE, { ...resumeData }, { params: { employeeId } });

			dispatch(ResumeActions.origin.updateResumeForEmployeeSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.updateResumeForEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while saving a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function deleteMyResume(resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.deleteMyResumeRequest());

		try {
			const url = getLink(endpoints.DELETE_MY_RESUME, { resumeId });

			await axios.delete(url);

			dispatch(ResumeActions.origin.deleteMyResumeSuccess());
		} catch (e) {
			dispatch(ResumeActions.origin.deleteMyResumeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function deleteEmployeeResume(employeeId, resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.deleteEmployeeResumeRequest());

		try {
			const url = getLink(endpoints.DELETE_EMPLOYEE_RESUME, { employeeId, resumeId });

			await axios.delete(url);

			dispatch(ResumeActions.origin.deleteEmployeeResumeSuccess());
		} catch (e) {
			dispatch(ResumeActions.origin.deleteEmployeeResumeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while deleting a resume appeared', options: { variant: 'error' } }));
		}
	};
}

export function uploadResumePersonal(file) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.uploadResumePersonalRequest());

		try {
			const params = { file };

			const { data } = await axios.post(
				endpoints.UPLOAD_RESUME_PERSONAL,
				{ ...params },
				{
					timeout: 0, // No timeout
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			dispatch(ResumeActions.origin.uploadResumePersonalSuccess({ data }));

			// start polling
			dispatch(getOwnResumePollingStage(data.id));
		} catch (e) {
			dispatch(ResumeActions.origin.uploadResumePersonalFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while uploading a file appeared', options: { variant: 'error' } }));
		}
	};
}

export function uploadResumeforEmployee(employeeId, file) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.uploadResumeForEmployeeRequest());

		try {
			const params = { file, employeeId };

			const { data } = await axios.post(
				endpoints.UPLOAD_RESUME_FOR_EMPLOYEE,
				{ ...params },
				{
					timeout: 0, // No timeout
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			dispatch(ResumeActions.origin.uploadResumeForEmployeeSuccess({ data }));

			// start polling
			dispatch(getEmployeeResumePollingStage(employeeId, data.id));
		} catch (e) {
			dispatch(ResumeActions.origin.uploadResumeForEmployeeFailure());
			dispatch(NotificationActions.pushNotification({ message: e.response.data.title || 'An Error while uploading a file appeared', options: { variant: 'error' } }));
		}
	};
}

export function loadMyResume(page = 0, paginationSize = 0) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.getResumeListRequest());

		try {
			const params = {
				page,
				size: paginationSize || defaultPaginationSize,
				sort: 'updatedDate,desc',
			};

			const { data } = await axios.get(endpoints.MY_RESUME_GET_LIST, { params });

			dispatch(ResumeActions.origin.getResumeListSuccess({ data }));
		} catch (e) {
			dispatch(ResumeActions.origin.getResumeListFailure());
		}
	};
}

export function getOwnResumePollingStage(resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.resumeLongPollingRequest());

		try {
			const url = getLink(endpoints.LONG_POLLING_OWN_RESUME_STAGE, { resumeId });
			const {
				data: { stage },
			} = await axios.get(url, { timeout: 0 });

			switch (stage) {
				case POLLING_SUCCESS:
					dispatch(loadMyResume());
					break;
				case POLLING_PENDING:
					dispatch(getOwnResumePollingStage(resumeId));
					break;
				case POLLING_FAIL:
					dispatch(recallParseForOwn(resumeId));
					break;
				default:
					dispatch(ResumeActions.origin.resumeLongPollingSuccess());
					break;
			}
		} catch (e) {
			const { response } = e;

			if (response.status === 408) {
				dispatch(getOwnResumePollingStage(resumeId));
				return;
			}

			dispatch(ResumeActions.origin.resumeLongPollingFailure());
		}
	};
}

export function recallParseForOwn(resumeId) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.origin.recallParseForOwnRequest());

		try {
			await axios.post(endpoints.RECALL_PARSE_FOR_OWN, {}, { params: { resumeId } });

			dispatch(ResumeActions.origin.recallParseForOwnSuccess());
		} catch (e) {
			dispatch(ResumeActions.origin.recallParseForOwnFailure());
		}
	};
}

export function updateOpenForJob(resumeId, newOpenForJob) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.openForJob.updateOpenForJobRequest({ resumeId, newOpenForJob }));

		try {
			const { data } = await axios.put(endpoints.UPDATE_MY_RESUME, { id: resumeId, openForJob: newOpenForJob });

			dispatch(ResumeActions.openForJob.updateOpenForJobSuccess({ data }));
			dispatch(ResumeActions.origin.updateOpenForJob({ resumeId }));
		} catch (e) {
			dispatch(ResumeActions.openForJob.updateOpenForJobFailure({ resumeId }));
			dispatch(
				NotificationActions.pushNotification({
					message: e?.response?.data?.title || 'An Error while updating openForJob in a resume appeared',
					options: { variant: 'error' },
				}),
			);
		}
	};
}

export function updateOpenForJobForEmployee(employeeId, resumeId, newOpenForJob) {
	return async function (dispatch, getState) {
		dispatch(ResumeActions.openForJob.updateOpenForJobRequest({ resumeId, newOpenForJob }));

		try {
			const { data } = await axios.put(endpoints.UPDATE_RESUME_FOR_EMPLOYEE, { id: resumeId, openForJob: newOpenForJob }, { params: { employeeId } });

			dispatch(ResumeActions.openForJob.updateOpenForJobSuccess({ data }));
			dispatch(ResumeActions.origin.updateOpenForJob({ resumeId }));
		} catch (e) {
			dispatch(ResumeActions.openForJob.updateOpenForJobFailure({ resumeId }));
			dispatch(
				NotificationActions.pushNotification({
					message: e?.response?.data?.title || 'An Error while updating openForJob in a resume appeared',
					options: { variant: 'error' },
				}),
			);
		}
	};
}
