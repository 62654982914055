import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& .MuiPaper-root': {
				backgroundColor: '#F6F6F6',
			},

			'& .react-pdf__Page': {
				backgroundColor: 'transparent!important',
			},
			'& .react-pdf__Page__canvas': {
				marginLeft: 'auto',
				marginRight: 'auto',
				backgroundColor: '#FFF',
			},
		},
		closeButton: {
			position: 'absolute',
			right: 10,
			top: 10,

			'& .MuiSvgIcon-root': {
				width: 36,
				height: 36,
			},
		},
	};
});
