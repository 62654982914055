import React from 'react';
import { Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'context/formikContext';
import { useStyles } from '../styles';

export default function FromToggleButtonGroup(props) {
	const { name, inputlabel, buttons, className, required = false } = props;
	const [field, , helpers] = useField(name);
	const { validationSchema } = useFormikContext();
	const { classes, cx } = useStyles();

	const isFieldRequired = () => {
		try {
			const { type, nullable, tests } = validationSchema.describe().fields[name];
			if (type === 'number') {
				return !nullable;
			}
			return tests.some((test) => test.name === 'required');
		} catch {
			return required;
		}
	};

	const handleChange = (event, value) => {
		helpers.setValue(value);
	};

	return (
		<Box mb={1} className={cx(classes.root, className)} display="flex" alignItems="center" gap={4}>
			{inputlabel && (
				<Typography variant="body1" color="primary" className={cx(classes.label, 'label')}>
					<>
						{inputlabel}
						{isFieldRequired() && <span className="required">*</span>}
					</>
				</Typography>
			)}
			<ToggleButtonGroup color="primary" value={field.value} exclusive onChange={handleChange} aria-label="Platform">
				{buttons &&
					buttons.map(({ label, value }) => (
						<ToggleButton key={`${name}-${label}`} value={value}>
							{label}
						</ToggleButton>
					))}
			</ToggleButtonGroup>
		</Box>
	);
}
