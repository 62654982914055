export const ROUTE_HOME = '/';

export const ROUTE_404 = '/404';

export const ROUTE_PRIVACY_POLICY = '/privacy-policy';

export const ROUTE_AUTH_CALLBACK = '/auth/callback';
export const ROUTE_AUTH_SILENTRENEW = '/auth/silentrenew';
export const ROUTE_AUTH_ACCOUNT_TYPE = '/auth/account-type';

export const ROUTE_BUSINESS_COMPANY_LIST = '/business/companies';
export const ROUTE_BUSINESS_COMPANY_ADD = '/business/companies/add-new';
export const ROUTE_BUSINESS_COMPANY_EDIT = '/business/companies/edit/:id';

export const ROUTE_BUSINESS_VACANCY_LIST = '/business/vacancies';
export const ROUTE_BUSINESS_VACANCY_PARSE = '/business/vacancies/parse';
export const ROUTE_BUSINESS_VACANCY_ADD = '/business/vacancies/add-new';
export const ROUTE_BUSINESS_VACANCY_EDIT = '/business/vacancies/edit/:id';

export const ROUTE_BUSINESS_EMPLOYEES = '/business/employees';
export const ROUTE_BUSINESS_EMPLOYEES_ADD = '/business/employees/add-new';
export const ROUTE_BUSINESS_EMPLOYEES_CREATE = '/business/employees/create';
export const ROUTE_BUSINESS_EMPLOYEES_EDIT = '/business/employees/edit/:id';
export const ROUTE_BUSINESS_EMPLOYEES_DETAILS = '/business/employees/:id';
export const ROUTE_BUSINESS_EMPLOYEES_RESUME_UPLOAD = '/business/employees/resume/upload/:employeeId';
export const ROUTE_BUSINESS_EMPLOYEES_RESUME_ADD = '/business/employees/resume/add-new/:employeeId';
export const ROUTE_BUSINESS_EMPLOYEES_RESUME_EDIT = '/business/employees/resume/edit/:employeeId/:id';
export const ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_ADD = '/business/employees/cover-letters/add/:employeeId';
export const ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_ADD_FOR_RESUME = '/business/employees/cover-letters/add/:employeeId/:resumeId';
export const ROUTE_BUSINESS_EMPLOYEES_COVERLETTER_EDIT = '/business/employees/cover-letters/edit/:employeeId/:id';

export const ROUTE_BUSINESS_MATCHING = '/business/matching';
export const ROUTE_BUSINESS_MATCHING_FOR_VACANCY = '/business/vacancies/matching/:vacancyId';
export const ROUTE_BUSINESS_MATCHING_FOR_EMPLOYEE_RESUME = '/business/matching/:employeeId/:resumeId';
export const ROUTE_BUSINESS_MATCHING_EMPLOYEE_RESUME = '/business/matchEmployeeResume';

export const ROUTE_PERSONAL_MY_RESUME = '/personal/my-resume';
export const ROUTE_PERSONAL_MY_RESUME_UPLOAD = '/personal/my-resume/upload';
export const ROUTE_PERSONAL_MY_RESUME_CREATE = '/personal/my-resume/create';
export const ROUTE_PERSONAL_MY_RESUME_EDIT = '/personal/my-resume/edit/:id';

export const ROUTE_PERSONAL_COVER_LETTERS = '/personal/cover-letters';
export const ROUTE_PERSONAL_COVER_LETTER_CREATE = '/personal/cover-letters/create';
export const ROUTE_PERSONAL_COVER_LETTER_CREATE_FOR_RESUME = '/personal/cover-letters/create/:resumeId';
export const ROUTE_PERSONAL_COVER_LETTER_EDIT = '/personal/cover-letters/edit/:id';

export const ROUTE_PERSONAL_MATCHING = '/personal/matching';
export const ROUTE_PERSONAL_MATCHING_MY_RESUME_LIST = '/personal/matchMyResumeList';
export const ROUTE_PERSONAL_MATCHING_FOR_RESUME = '/personal/matching/:resumeId';

export const ROUTE_MY_ACCOUNT = '/my-account';
