import axios from 'axios';
import * as endpoints from 'services/api';
import { defaultPaginationSize } from 'constants/other';
import { getLink } from 'helpers/route';
import { MATCH_RESUMES_FOR_VACANCY, MATCH_VACANCIES_FOR_RESUME } from './api';

export async function matchVacanciesForResumeIdFunction(resumeId, topK, useCountry = true) {
	try {
		const params = {
			useCountry,
		};

		const url = getLink(MATCH_VACANCIES_FOR_RESUME, { resumeId, topK });

		const { data } = await axios.get(url, { params });

		return data;
	} catch (e) {
		console.log(e, 'error');
	}
}

export async function matchVacanciesForManyResumes(resumesId, topK = 10, useCountry = true) {
	try {
		let matchedVacancies = [];

		const params = {
			useCountry,
		};

		await Promise.all(
			resumesId.slice(0, 55).map(async (resumeId) => {
				const url = getLink(MATCH_VACANCIES_FOR_RESUME, { resumeId, topK });

				try {
					const { data } = await axios.get(url, { params });
					matchedVacancies = matchedVacancies.concat(data);
				} catch (e) {
					console.log(e, 'error fetching data for resumeId:', resumeId);
				}
			}),
		);

		return removeDuplicatesById(matchedVacancies);
	} catch (e) {
		console.log(e, 'error');
	}
}

function removeDuplicatesById(matchedVacancies) {
	const uniqueIds = [];

	const filteredVacancies = matchedVacancies.filter((item) => {
		if (uniqueIds.includes(item.vacancy.id)) {
			return false;
		} else {
			uniqueIds.push(item.vacancy.id);
			return true;
		}
	});

	return filteredVacancies;
}

export async function matchResumesForVacancyIdFunction(vacancyId, topK, useCountry = true) {
	try {
		const params = {
			useCountry,
		};

		const url = getLink(MATCH_RESUMES_FOR_VACANCY, { vacancyId, topK });

		const { data } = await axios.get(url, { params });

		return data;
	} catch (e) {
		console.log(e, 'error');
	}
}

export async function getEmployeeResumesFunc(employeeId, page = 0, paginationSize = 0) {
	try {
		const params = {
			page,
			size: paginationSize || defaultPaginationSize,
			sort: 'createdDate',
		};

		const url = getLink(endpoints.MY_RESUME_GET_LIST_BY_EMPLOYEE, { employeeId });

		const { data } = await axios.get(url, { params });
		return data;
	} catch (e) {
		console.log(e, 'error while getting employeeResumes');
	}
}

export async function getManyEmployeesResumesFunc(employeesId, page = 0, paginationSize = 0) {
	const employeesResumes = await Promise.all(
		employeesId.map(async (emplId) => {
			return (await getEmployeeResumesFunc(emplId, page, paginationSize)).content;
		}),
	);

	let result = [];
	employeesResumes.forEach((emplRes) => (result = result.concat(emplRes)));
	return result;
}
