// Endpoints
// Swagger https://java-dev.cv2job.com/swagger-ui/index.html
export const COMPANIES_GET_LIST = `/company/own/list`;
export const COMPANY_GET_BY_ID = `/company/own/:id`;
export const COMPANY_ADD = `/company`;
export const COMPANY_EDIT = `/company/own`;
export const COMPANY_DELETE = `/company/:id`;
export const INVITE_USER_TO_COMPANY = '/company/own/inviteUserToCompany';
export const DETACH_USER_FROM_COMPANY = 'company/own/detachUserFromCompany';
export const GET_COMPANY_MEMBERS = 'company/own/getCompanyMembers/:companyId';
export const GET_COMPANY_INVITE_LIST = '/company/own/getInviteList';
export const COMPANY_ACCEPT_INVITE = '/company/own/acceptInvitationToCompany/:inviteId';
export const COMPANY_REJECT_INVITE = '/company/own/rejectInvitationToCompany/:inviteId';

export const MY_RESUME_GET_LIST = '/resume/own/list';
export const MY_RESUME_GET_LIST_BY_EMPLOYEE = '/resume/own/list/:employeeId';
export const MY_RESUME_GET_BY_ID = '/resume/own/:resumeId';
export const UPLOAD_RESUME_PERSONAL = '/resume/parseThenSaveResumeForOwnWithPreSavedResume';
export const DELETE_MY_RESUME = '/resume/deleteOwnResume/:resumeId';
export const DELETE_EMPLOYEE_RESUME = '/resume/deleteEmployeeResume/:employeeId/:resumeId';
export const UPDATE_MY_RESUME = '/resume/updateOwnResume';
export const CREATE_MY_NEW_RESUME = '/resume/createForOwn';
export const UPLOAD_RESUME_FOR_EMPLOYEE = '/resume/parseThenSaveResumeForEmployeeWithPreSavedResume';
export const CREATE_RESUME_FOR_EMPLOYEE = '/resume/createForEmployee';
export const UPDATE_RESUME_FOR_EMPLOYEE = '/resume/updateEmployeeResume';
export const GET_EMPLOYEE_RESUME = '/resume/own/:resumeId/:employeeId';

export const LONG_POLLING_OWN_RESUME_STAGE = '/resume/longPollingOwnResumeStage/:resumeId';
export const LONG_POLLING_EMPLOYEE_RESUME_STAGE = '/resume/longPollingEmployeeResumeStage/:resumeId';
export const RECALL_PARSE_FOR_OWN = '/resume/recallParseForOwn';
export const RECALL_PARSE_FOR_EMPLOYEE = '/resume/recallParseForEmployee';

export const GENERATE_RESUME_PDF = '/pdf/generateResumePdf';
export const GENERATE_RESUME_PDF_DEFAULT = '/pdf/generateResumePdfDefault';
export const GENERATE_RESUME_DOWNLOAD_URL = '/file/generateResumeDownloadUrl';
export const GENERATE_COMPANY_LOGO_URL = '/file/generateCompanyLogoDownloadUrl';
export const GENERATE_TEMPLATE_URL = '/file/generateTemplateDownloadUrl';
export const GET_OWN_PDF_TEMPLATE_LIST = '/pdf/getOwnPdfTemplateList';
export const GET_OWN_PDF_TEMPLATE_LIST_BY_COMPANY_ID = '/pdf/getOwnPdfTemplateListByCompanyId';

export const GET_OWN_COVER_LETTER_BY_ID = '/resume/coverLetter/own/:coverLetterId';
export const GET_EMPLOYEE_COVER_LETTER_BY_ID = '/resume/coverLetter/own/:coverLetterId/:employeeId';
export const GET_COVER_LETTERS = '/resume/coverLetter/own/list';
export const GET_COVER_LETTERS_BY_EMPLOYEE = '/resume/coverLetter/employee/list/:employeeId';
export const CREATE_COVERLETTER_FOR_OWN_RESUME = '/resume/createCoverLetterForOwnResume';
export const CREATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION = '/resume/createCoverLetterForOwnResumeWithVacancy';
export const CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME = '/resume/createCoverLetterForEmployeeResume';
export const CREATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION = '/resume/createCoverLetterForEmployeeResumeWithVacancy';
export const GENERATE_COVERLETTER_FOR_OWN_RESUME = '/resume/generateCoverLetterForOwnResume';
export const GENERATE_COVERLETTER_FOR_OWN_RESUME_WITH_DESCRIPTION = '/resume/generateCoverLetterForOwnResumeWithVacancy';
export const GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME = '/resume/generateCoverLetterForEmployeeResume';
export const GENERATE_COVERLETTER_FOR_EMPLOYEE_RESUME_WITH_DESCRIPTION = '/resume/generateCoverLetterForEmployeeResumeWithVacancy';
export const IMPROVE_COVERLETTER_BASED_ON_RESUME = '/resume/improveCoverLetterBasedOnResume';
export const DELETE_COVERLETTER_FOR_OWN_RESUME = '/resume/deleteResumeCoverLetter/:coverLetterId';
export const DELETE_COVERLETTER_FOR_EMPLOYEE_RESUME = '/resume/deleteEmployeeResumeCoverLetter/:coverLetterId';

export const MATCH_VACANCIES_FOR_RESUME = '/matching/findVacancyByResumeId/:resumeId/:topK';
export const MATCH_RESUMES_FOR_VACANCY = '/matching/findResumeByVacancyId/:vacancyId/:topK';

export const GET_VACANCIES = '/vacancy/own/list/:companyId';
export const GET_VACANCY_BY_ID = '/vacancy/own/:companyId/:vacancyId';
export const CREATE_VACANCY_FOR_OWN_COMPANY = '/vacancy/createForOwnCompany/:companyId';
export const UPDATE_VACANCY_FOR_OWN_COMPANY = '/vacancy/updateCompanyVacancy';
export const UPLOAD_VACANCY_FOR_OWN_COMPANY = '/vacancy/parseThenSaveVacancyForCompanyWithPreSavedVacancy/:companyId';
export const DELETE_VACANCY = '/vacancy/:vacancyId';

export const GET_EMPLOYEES = '/employee/own/list/:companyId';
export const ADD_EMPLOYEE = '/employee';
export const GET_EMPLOYEE_BY_ID = `/employee/:employeeId`;
export const GET_OWN_EMPLOYEE_BY_ID = `/employee/own/:employeeId`;
export const UPDATE_EMPLOYEE = '/employee/own';
export const UPLOAD_EMPLOYEE_RESUMES = '/resume/parseThenSaveResumeForAutoCreatedEmployeeWithPreSavedResumes';
export const DELETE_EMPLOYEE = '/employee/:employeeId';
export const FILTER_EMPLOYEES = '/employee/own/search';
export const GET_EPLOYEE_PHOTO_URL = '/file/generateEmployeePhotoDownloadUrl';

export const USER = '/user/';
export const SET_USER_CURRENT_MANAGEMENT = '/user/setCurrentManagement/:management';

export const IMPROVE_RESUME_PROJECT_DESCRIPTION = '/resume/improveProjectDescription';
export const IMPROVE_RESUME_PROJECT_ROLE = '/resume/improveProjectRole';
export const IMPROVE_RESUME_PROJECT_RESPONSIBILITY_LIST = '/resume/improveProjectResponsibilities';
export const IMPROVE_RESUME_SUMMARY = '/resume/improveSummary';
export const IMPROVE_RESUME_TEXT = '/resume/improveText';

export const GET_SKILL_LIST = '/skill/list';
export const GET_INDUSTRY_LIST = '/industry/getIndustryList';
export const GET_SENIORITY_LIST_BY_INDUSTRY_NAME = '/industry/getSSeniorityListByIndustryName/:industryName';
export const GET_SPECIALISATION_LIST_BY_INDUSTRY_NAME = '/industry/getSpecialisationListByIndustryName/:industryName';
export const GET_COUNTRY_LIST = '/location/country/list';
export const GET_LANGUAGE_PROFICIENCY_LIST = '/language/getProficiencyList';
export const GET_LANGUAGES_LIST = '/language/getLanguageList';

export const GET_FEATURE_FLAG = '/featureflag/';

export const GET_METRIC_LOGIN = '/event/sendIdentifyUserEvent';
