import { makeStyles } from 'tss-react/mui';
import colors from 'constants/colors';

export const useStyles = makeStyles()((theme) => {
	return {
		root: {
			'& .MuiAutocomplete-root': {
				'& .MuiOutlinedInput-root': {
					padding: '6px 9px',
				},
			},
			'& .MuiSelect-outlined': {
				padding: '9px 14px',
			},
		},
		label: {
			fontSize: '1.4rem',
			fontWeight: 500,
			lineHeight: '2.4rem',
			letterSpacing: '-0.2px',
			marginBottom: '6px',

			'& span': {
				fontSize: '1.4rem',
				fontWeight: 500,
				lineHeight: '2.4rem',
				letterSpacing: '-0.2px',
			},
		},
		input: {
			minWidth: 320,
		},
		autocomplete: {
			'& .MuiSvgIcon-root': {
				color: colors.InputAdornmentColor,
				fill: 'currentColor',
			},
			minWidth: 320,
			display: 'inline-flex',
		},
		Editor: {
			'& .editorClassName': {
				minHeight: 150,
				border: '1px solid #E6E6E6',
				padding: '30px 30px 0',
			},
			'&.readOnly': {
				opacity: 1,
				WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
			},
		},
		control: {
			margin: 0,
			alignItems: 'center',
			gap: theme.spacing(4),
			'& .MuiTypography-root': {
				margin: 0,
			},
		},
		selectAndInputField: {
			'& .MuiTypography-root.label': {
				display: 'none',
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 0,
			},

			'&.invalid,&.error': {
				'& .combinedField': {
					borderColor: theme.palette.error.main,
				},
			},

			'& .combinedField': {
				border: ' 1px solid #AFBACA',
				borderRadius: 6,
				display: 'grid',
				gridTemplateColumns: 'minmax(min-content, 51px) auto',
				position: 'relative',

				'& .MuiBox-root': {
					margin: 0,
				},

				'& .MuiFormHelperText-root': {
					display: 'none',
				},

				'& .messengerSelector': {
					'& .MuiSelect-select': {
						height: '24px',
						minWidth: '24px',
					},
					'& > .MuiSelect-icon': {
						fill: 'rgba(0, 0, 0, 0.54)',
					},
				},

				'& .messengerError': {
					color: 'red',
					position: 'absolute',
					bottom: '-2em',
				},
			},
		},
		removeMessengerBtn: {
			position: 'absolute',
			bottom: 0,
			right: 0,
		},
	};
});
