import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: false,
	error: false,
	list: { content: [] },
	created: false,
	updated: false,
	deleted: false,
	details: null,
	uploaded: false,
	longPolled: false,
	not_found: false,
};

const { actions, reducer } = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		setInitial: (state) => {
			state.loading = false;
			state.error = false;
			state.list = { content: [] };
			state.created = false;
			state.updated = false;
			state.deleted = false;
			state.details = null;
			state.uploaded = false;
			state.longPolled = false;
			state.not_found = false;
		},

		uploadEmployeeResumesRequest: (state, { payload }) => {
			state.uploaded = false;
			state.loading = true;
			state.error = false;
		},
		uploadEmployeeResumesSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
			state.uploaded = true;
		},
		uploadEmployeeResumesFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		updateEmployeeRequest: (state, { payload }) => {
			state.updated = false;
			state.loading = true;
			state.error = false;
		},
		updateEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
			state.updated = true;
		},
		updateEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getEmployeeRequest: (state) => {
			state.details = null;
			state.loading = true;
			state.error = false;
		},
		getEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = { ...payload.data, photoIsLoading: payload.data.photo ? true : false, photoUrl: '' };
		},
		getEmployeeFailure: (state, { payload: { not_found } }) => {
			state.loading = false;
			state.error = true;
			state.not_found = not_found;
		},

		getEmployeesRequest: (state, { payload }) => {
			state.list = initialState.list;
			state.loading = true;
			state.error = false;
		},
		getEmployeesSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		getEmployeesFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getEmployeeOwnResumesRequest: (state) => {
			state.details.resumesLoading = true;
		},
		getEmployeeOwnResumesSuccess: (state, { payload }) => {
			state.details.resumesLoading = false;
			state.details.resumes = payload.data;
		},
		getEmployeeOwnResumesFailure: (state) => {
			state.details.resumesLoading = false;
		},

		getEmployeesOwnResumesRequest: (state, { payload }) => {
			state.list.content = state.list.content.map((employee) => {
				if (employee.id !== payload.employeeId) {
					return employee;
				} else {
					return { ...employee, resumesLoading: true };
				}
			});
		},
		getEmployeesOwnResumesSuccess: (state, { payload }) => {
			state.list.content = state.list.content.map((employee) => {
				if (employee.id !== payload.employeeId) {
					return employee;
				} else {
					return { ...employee, resumesLoading: false, resumes: payload.data };
				}
			});
		},
		getEmployeesOwnResumesFailure: (state, { payload }) => {
			state.list.content = state.list.content.map((employee) => {
				if (employee.id !== payload.employeeId) {
					return employee;
				} else {
					return { ...employee, resumesLoading: false };
				}
			});
		},

		addEmployeeRequest: (state, { payload }) => {
			state.created = false;
			state.details = null;
			state.loading = true;
			state.error = false;
		},
		addEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.details = payload.data;
			state.created = true;
		},
		addEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		employeeResumeLongPollingRequest: (state, { payload }) => {
			state.error = false;
			state.longPolled = false;
		},
		employeeResumeLongPollingSuccess: (state) => {
			state.loading = false;
			state.longPolled = true;
		},
		employeeResumeLongPollingFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		recallParseForEmployeeRequest: (state, { payload }) => {
			state.loading = true;
			state.error = false;
		},
		recallParseForEmployeeSuccess: (state) => {
			state.loading = false;
		},
		recallParseForEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		deleteEmployeeRequest: (state) => {
			state.loading = true;
			state.error = false;
			state.deleted = false;
		},
		deleteEmployeeSuccess: (state) => {
			state.loading = false;
			state.deleted = true;
		},
		deleteEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		filterEmployeeRequest: (state, { payload }) => {
			state.details = null;
			state.loading = true;
			state.error = false;
		},
		filterEmployeeSuccess: (state, { payload }) => {
			state.loading = false;
			state.list = payload.data;
		},
		filterEmployeeFailure: (state) => {
			state.loading = false;
			state.error = true;
		},

		getEmployeePhotoRequest: (state) => {
			state.details = { ...state.details, photoIsLoading: true };
			state.details.photoUrl = '';
		},
		getEmployeePhotoSuccess: (state, { payload }) => {
			state.details.photoUrl = payload;
			state.details.photoIsLoading = false;
		},
		getEmployeePhotoFailure: (state) => {
			state.details.photoIsLoading = false;
		},

		updateEmployeeResumeOpenForJobRequest: (state, { payload }) => {
			state.list.content.find(({ id, resumes }) => {
				if (id === payload.employeeId) {
					resumes.find(({ id }) => id === payload.resumeId).openForJobLoading = true;
					return true;
				} else {
					return false;
				}
			});
		},
		updateEmployeeResumeOpenForJobSuccess: (state, { payload }) => {
			state.list.content.find(({ id, resumes }) => {
				if (id === payload.employeeId) {
					const resume = resumes.find(({ id }) => id === payload.resumeId);
					resume.openForJobLoading = false;
					resume.openForJob = payload.newOpenForJob;
					return true;
				} else {
					return false;
				}
			});
		},
		updateEmployeeResumeOpenForJobFailure: (state, { payload }) => {
			state.list.content.find(({ id, resumes }) => {
				if (id === payload.employeeId) {
					resumes.find(({ id }) => id === payload.resumeId).openForJobLoading = false;
					return true;
				} else {
					return false;
				}
			});
		},
	},
});

export { actions as EmployeeActions };

export default reducer;
